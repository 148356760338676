import React, { useEffect } from "react";
import { imageUrl } from "../../Config/apiUrl";
import classes from "./OurServiceCard.module.css";
import Aos from "aos";
import "aos/dist/aos.css";

function OurServiceCard({ item, ...props }) {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);
  return (
    <div data-aos="fade-up" className={`${classes.card}`} {...props}>
      <img src={imageUrl(item?.icon)} />
      {/* <img src={item?.icon}/> */}
      <h5>{item?.title}</h5>
      <p>{item?.description}</p>
    </div>
  );
}

export default OurServiceCard;
