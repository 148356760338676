import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../stories/Footer";
import Header from "../../stories/Header";
import HeroSection from "../../stories/HeroSection";
import classes from "./CreateProfile.module.css";
import Aos from "aos";
import Input from "../../stories/Input";
import TextArea from "../../stories/TextArea";
import { Checkbox } from "../../stories/Checkbox";
import Button from "../../stories/Button";
import {
  apiHeader,
  BaseURL,
  createFormData,
  imageUrl,
} from "../../Config/apiUrl";
import { Get, Post } from "../../Axios/AxiosFunctions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import LocationInput from "../../stories/LocationInput";
import PaymentModal from "../../Components/Modals/PaymentModal";
import { useHistory } from "react-router-dom";
import { setRegistrationType } from "../../store/Actions/authAction";
import ConfirmSubmissionModal from "../../Components/Modals/ConfirmSubmissionModal";
import PDFImageView from "../../stories/PDFImageView";
import { UploadPdfIcon } from "../../constant/imagePath";
import { useParams } from "react-router-dom";
import { ProfileWithEditButton } from "../../stories/ProfileWithEditButton";

const createPdfFormData = (file) => {
  let data = new FormData();
  file?.documents.forEach((item) => {
    data.append("documents", item);
  });
  return data;
};

function CreateProfile() {
  const id = useParams()?.id;

  const [userData, setUserData] = useState();

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    certificatesArray,
    servicesArray,
    defaultCoords,
    registrationType,
    packageDetail,
  } = useSelector((state) => state?.commonReducer);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [googleLink, setGoogleLink] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [linkedInLink, setLinkedInLink] = useState("");
  const [snapChatLink, setSnapChatLink] = useState("");
  const [location, setLocation] = useState("");
  const [myCoords, setMyCoords] = useState(defaultCoords);
  const [biography, setBiography] = useState("");
  const [loading, setLoading] = useState(false);

  console.log({ userData });

  const [buyPackageLoading, setBuyPackageLoading] = useState(false);

  const [blurLoader, setBlurLoader] = useState(false);
  const [selectedCertificates, setSelectedCertificates] = useState([]);
  const [selectedCertificatesObjects, setSelectedCertificatesObjects] =
    useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedServicesObjects, setSelectedServicesObjects] = useState([]);

  const [termsAndCondition, setTermsAndCondition] = useState([]);

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [confirmSubmissionModal, setConfirmSubmissionModal] = useState(false);

  const [apiToken, setApiToken] = useState(null);

  // Profile Pic
  const [coverImage, setCoverImage] = useState("");
  const [profileImage, setProfileImage] = useState("");

  // ------ public docs states --------
  const [documentsFile, setDocumentsFile] = useState([]);

  // ------ private docs states -------
  const [documentsFilePrivate, setDocumentsFilePrivate] = useState([]);
  const documentRef = useRef(null);
  const privateDocRef = useRef(null);
  const [selectedDoc, setSelectedDoc] = useState("");
  const [chosenPDFType, setChosenPDFType] = useState("");
  const [isDeletingDoc, setIsDeletingDoc] = useState(false);

  const getData = async () => {
    const url = BaseURL(`customer/claimed-business-user/${id}`);
    const response = await Get(url);
    if (response !== undefined) {
      setUserData(response?.data?.data);
      setEmail(response?.data?.data?.UserId?.email);
      setUserName(response?.data?.data?.userName);
      setGoogleLink(response?.data?.data?.googleLink);
      setInstagramLink(response?.data?.data?.instagramLink);
      setSnapChatLink(response?.data?.data?.snapchatLink);
      setFacebookLink(response?.data?.data?.facebookLink);
      setLinkedInLink(response?.data?.data?.linkedinLink);
      setBiography(response?.data?.data?.description);
      setSelectedCertificates(response?.data?.data?.certificates);
      setProfileImage(response?.data?.data?.photo);
      setCoverImage(response?.data?.data?.cover_image);
      setDocumentsFile(response?.data?.data?.documents);

      createCeritifateAndServiceDataStructure(
        response?.data?.data?.certificates,
        "certificateItem",
        "Certificate",
        setSelectedCertificatesObjects,
        setSelectedCertificates
      );
      createCeritifateAndServiceDataStructure(
        response?.data?.data?.services,
        "serviceItems",
        "service",
        setSelectedServicesObjects,
        setSelectedServices
      );
    }
  };

  const uploadDocument = (e) => {
    let files = e;
    let stateFiles = documentsFile?.length;
    let maxLength = 4;
    let uploadFilesLength = files.length;
    if (uploadFilesLength + stateFiles > maxLength) {
      return toast.info(
        `😬Sorry, Maximum ${maxLength} public pdf files allowed.`
      );
    }

    let isNotPdf = false;
    let maxSize = 2097152;
    let isLargeinSize = false;
    Object.keys(files).forEach(function (key) {
      if (files[key].name.split(".").pop().toLowerCase() === "pdf") {
        isNotPdf = true;
      }
      if (files[key].size > maxSize) {
        isLargeinSize = true;
      }
    });

    if (isNotPdf === false) {
      toast.info("Sorry, One of your files is not a pdf.");
      return;
    }

    if (isLargeinSize === true) {
      toast.info("😬Alert, One of your files is more than 2MB.");
      return;
    }

    let allDocumentsFile = [...documentsFile];
    Object.keys(files).forEach(function (key) {
      allDocumentsFile.push(files[key]);
    });
    setDocumentsFile(allDocumentsFile);
  };

  // const getData = async () => {
  //     const url = BaseURL(
  //         `pages?pages=registrationForm&goals=false&service=false&aboutusItem=false&faq=false&all=false`
  //     );
  //     setBlurLoader(true);
  //     const response = await Get(url);
  //     if (response !== undefined) {
  //         setDataList(response.data.data);
  //     } else {
  //         setDataList([]);
  //     }
  //     setBlurLoader(false);
  // };

  useEffect(() => {
    getData();
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);

  const handleSelectedCertificates = (type, item, itemType) => {
    let index = -1;
    let newItem = {};
    let data =
      itemType == "certificate"
        ? selectedCertificatesObjects.slice()
        : selectedServicesObjects.slice();
    if (itemType == "certificate") {
      index = selectedCertificatesObjects.findIndex(
        (x) => x.certificateItemId == item?._id
      );
      if (index == -1) {
        newItem = {
          mechanicUserId: null,
          mechanicDetailId: null,
          certificateTypeId: type?._id,
          certificateItemId: item?._id,
        };
      }
    } else {
      index = selectedCertificatesObjects.findIndex(
        (x) => x.certificateItemId == item?._id
      );
      if (index == -1) {
        newItem = {
          mechanicUserId: null,
          mechanicDetailId: null,
          serviceTypeId: type?._id,
          serviceItemId: item?._id,
        };
      }
    }
    if (index == -1) {
      data.push(newItem);
    } else {
      data?.splice(index, 1);
    }
    if (itemType == "certificate") {
      setSelectedCertificatesObjects(data);
    } else {
      setSelectedServicesObjects(data);
    }
  };

  const createPdfFormData = (file) => {
    let data = new FormData();
    data.append("email", email);
    file?.documents.forEach((item) => {
      data.append("documents[]", item);
    });

    return data;
  };

  const HandleSignUp = async () => {
    let params = {
      email,
      description: biography,
      userName,
      passwordConfirm: confirmPassword,
      password: password,
      photo: profileImage,
      UserId: userData?.UserId?._id,
      cover_image: coverImage,
      facebookLink,
      instagramLink,
      snapchatLink: snapChatLink,
      linkedinLink: linkedInLink,
      googleLink,
    };
    for (let key in params) {
      if (params[key] == "" || params[key] == null) {
        toast.info(`Please Enter All Details.`);
      }
    }
    HandleSubmitApi(params);

    // const pdfApiURL = BaseURL("upload/claim/pdf");
    // params = {
    //   ...params,
    //   documents: [...documentsFile],
    // };

    // let formData = await createPdfFormData(params);

    // setLoading(true);

    // let responseData = await Post(pdfApiURL, formData, apiHeader(null, true));
    // if (responseData !== undefined) {
    //   params = {
    //     ...params,
    //     documents: responseData?.data?.data?.user?.documents,
    //   };
    //   HandleSubmitApi(params);
    // } else {
    //   setLoading(false);
    // }
  };

  // Handle Submit
  const HandleSubmitApi = async (params) => {
    setLoading(true);
    let updateProfileURL = BaseURL("update/claim/resetPassword");
    let editProfileData = await createFormData(params);
    const responseData = await Post(
      updateProfileURL,
      editProfileData,
      apiHeader()
    );
    if (responseData !== undefined) {
      setConfirmSubmissionModal(true);
    }
    setLoading(false);
  };

  const buyPackage = async (paymentMethod) => {
    console.log(paymentMethod, "paymentMethod");
    console.log(paymentMethod?.id, "ID");

    const url = BaseURL("mechanic/create-subscription");
    const params =
      packageDetail?.product?.metadata?.premimum !== undefined
        ? {
            paymentMethodId: paymentMethod?.id,
            planId: packageDetail?.id,
            premimum: true,
          }
        : {
            planId: packageDetail?.id,
            paymentMethodId: paymentMethod?.id,
          };

    setBuyPackageLoading(true);
    const response = await Post(url, params, apiHeader(apiToken));
    if (response !== undefined) {
      console.log(response, "buyresponse");
      toast.success(
        `🙌 We have successfully created your account. Please check your email for verification.`,
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        }
      );
      await dispatch(setRegistrationType(null, null));
      setShowPaymentModal(true);
      setTimeout(() => {
        history.push(`/`);
      }, 1000);
    }
    setBuyPackageLoading(false);
  };

  const createCeritifateAndServiceDataStructure = (
    mainArray,
    mainKey,
    innerTypeKey,
    setObject,
    setString
  ) => {
    let selectedItemStringsArray = [];
    let selectedItemObjectArray = [];
    mainArray.map((item, index) => {
      item[mainKey].map((innerItem, innerIndex) => {
        selectedItemStringsArray.push(innerItem[`${innerTypeKey}Name`]);
        selectedItemObjectArray.push({
          mechanicUserId: userData?.UserId?._id,
          mechanicDetailId: userData?._id,
          ...(innerTypeKey == "Certificate" && {
            certificateTypeId: innerItem[`${innerTypeKey}TypeId`],
            certificateItemId: innerItem?._id,
          }),
          ...(innerTypeKey == "service" && {
            serviceTypeId: innerItem[`${innerTypeKey}TypeId`],
            serviceItemId: innerItem?._id,
          }),
        });
      });
    });
    setString(selectedItemStringsArray);
    setObject(selectedItemObjectArray);
  };

  return (
    <div className={[classes.page, blurLoader && "pageBlur"].join(" ")}>
      <div className={classes.header}>
        <Header
          bgTransparent={false}
          isLogoBlack={true}
          containerClass={`${[classes.container].join(" ")}`}
        />
      </div>
      <section className={classes.profileSection}>
        <Container className={classes.container}>
          <Row>
            <Col md={12}>
              <h2>UPDATE INFORMATION</h2>
            </Col>
            <Col md={12}>
              <div className={`${[classes.userImagesContainer].join(" ")}`}>
                <ProfileWithEditButton
                  updateImage={coverImage}
                  setUpdateImage={setCoverImage}
                  onErrorImage={true}
                  classImg={classes.coverImageTagClass}
                  className={classes.coverImage}
                  classBtn={classes.coverEditBtn}
                />
                <div className={`${classes.profile_div}`}>
                  <ProfileWithEditButton
                    updateImage={profileImage}
                    setUpdateImage={setProfileImage}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} className={classes.mBlock20}>
              <Input
                label={"Email Address"}
                placeholder={"Email Address"}
                value={email}
                setter={setEmail}
                type={"text"}
              />
            </Col>
            <Col md={6} className={classes.mBlock20}>
              <Input
                label={"Username"}
                placeholder={"Username"}
                value={userName}
                setter={setUserName}
                type={"text"}
              />
            </Col>
            <Col md={6} className={classes.mBlock20}>
              <Input
                label={"Google Link"}
                placeholder={"www.plus.google.com/username"}
                value={googleLink}
                setter={setGoogleLink}
                type={"text"}
              />
            </Col>
            <Col md={6} className={classes.mBlock20}>
              <Input
                label={"Facebook Link"}
                placeholder={"www.plus.facebook.com/username"}
                value={facebookLink}
                setter={setFacebookLink}
                type={"text"}
              />
            </Col>
            <Col md={6} className={classes.mBlock20}>
              <Input
                label={"LinkedIn Link"}
                placeholder={"www.plus.linkedin.com/username"}
                value={linkedInLink}
                setter={setLinkedInLink}
                type={"text"}
              />
            </Col>
            <Col md={6} className={classes.mBlock20}>
              <Input
                label={"Instagram Link"}
                placeholder={"www.plus.instagram.com/username"}
                value={instagramLink}
                setter={setInstagramLink}
                type={"text"}
              />
            </Col>
            <Col md={6} className={classes.mBlock20}>
              <Input
                label={"Snapchat Link"}
                placeholder={"www.plus.snapchat.com/username"}
                value={snapChatLink}
                setter={setSnapChatLink}
                type={"text"}
              />
            </Col>
            <Col md={6} className={classes.mBlock20}>
              <div className={classes?.locationMainContainer}>
                <label className={`mb-2 ${[classes.labelText].join(" ")}`}>
                  Location
                </label>
                <LocationInput
                  setPlace={setLocation}
                  setCordinate={setMyCoords}
                  placeholder={"Location*"}
                  className={classes?.locationInput}
                />
              </div>
            </Col>
            <Col md={6} className={classes.mBlock20}>
              <Input
                label={"Password"}
                placeholder={"Password"}
                value={password}
                setter={setPassword}
                type={"password"}
              />
            </Col>
            <Col md={6} className={classes.mBlock20}>
              <Input
                label={"Confirm Password"}
                placeholder={"Confirm Password"}
                value={confirmPassword}
                setter={setConfirmPassword}
                type={"password"}
              />
            </Col>

            <Col md={12} className={classes.mBlock20}>
              <TextArea
                label={"Biography"}
                placeholder={"Biography*"}
                value={biography}
                setter={setBiography}
                type={"text"}
                rows={6}
              />
            </Col>
          </Row>
          <Row className={classes.mBlock20}>
            <Col md={12}>
              <h3>CERTIFICATIONS</h3>
              <p>Check boxes for below option</p>
            </Col>
          </Row>
          <Row className={classes.mBlock20}>
            {certificatesArray?.map((item, i) => {
              return (
                <Col md={6} className={[classes.certificateCol]} key={i}>
                  <h3>{item?.CertificateType}</h3>
                  {item?.certificateItem?.map((certificate, i) => {
                    return (
                      <Checkbox
                        value={selectedCertificates}
                        label={certificate?.CertificateName}
                        disabled={false}
                        setValue={(e) => {
                          setSelectedCertificates(e);
                          handleSelectedCertificates(
                            item,
                            certificate,
                            "certificate"
                          );
                        }}
                      />
                    );
                  })}
                </Col>
              );
            })}
          </Row>

          <Row className={classes.mBlock20}>
            <Col md={12}>
              <h3>SERVICES</h3>
              <p>Check boxes for below option</p>
            </Col>
          </Row>
          <Row className={classes.mBlock20}>
            {servicesArray?.map((item, i) => {
              return (
                <Col md={6} className={[classes.certificateCol]} key={i}>
                  <h3>{item?.serviceType}</h3>
                  {item?.serviceItem?.map((service, i) => {
                    return (
                      <Checkbox
                        label={service?.serviceName}
                        value={selectedServices}
                        disabled={false}
                        setValue={(e) => {
                          setSelectedServices(e);
                          handleSelectedCertificates(item, service, "service");
                        }}
                      />
                    );
                  })}
                </Col>
              );
            })}
          </Row>
          {/* PDFS */}

          <Row className={`${classes.termsAndCondition}`}>
            <Col md={6}>
              <Checkbox
                label={
                  "By clicking Submit, you agree to our Terms & Conditions, Data Policy and Cookie Policy."
                }
                value={termsAndCondition}
                setValue={(e) => {
                  setTermsAndCondition(e);
                }}
                disabled={false}
              />
              <div className={classes.submitBtnDiv}>
                <Button
                  label={loading ? "Submitting..." : "Submit"}
                  onClick={HandleSignUp}
                  className={classes.submitBtn}
                  disabled={loading}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer containerClass={classes.container} />

      <ConfirmSubmissionModal
        show={confirmSubmissionModal}
        setShow={setConfirmSubmissionModal}
        title={"Thank you! "}
        subtitle={"We have successfully created your account."}
        buttonLabel={"DONE"}
        btnClick={() => history.push("/")}
      />

      {/* <PaymentModal
                show={showPaymentModal}
                setShow={setShowPaymentModal}
                apiToken={apiToken}
                type={"registration"}
                buyPackage={buyPackage}
                buyPackageLoading={buyPackageLoading}
            /> */}
    </div>
  );
}

export default CreateProfile;
