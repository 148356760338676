import usePlacesAutocomplete, {
  getLatLng,
  getGeocode,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

import classes from "./locationInput.module.css";

export default function LocationInput({
  setPlace,
  setCordinate,
  placeholder = "Search address",
  className,
}) {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  //   handleSelect
  const handleSelect = async (val) => {
    setValue(val, false);
    clearSuggestions();
    const results = await getGeocode({ address: val });
    const { lat, lng } = await getLatLng(results[0]);
    setPlace(val);
    setCordinate !== undefined && setCordinate({ lat, lng });
  };

  return (
    <Combobox
      onSelect={handleSelect}
      className={`${className ? className : classes.combobox}`}
    >
      <ComboboxInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        className={classes["combobox-input"]}
        placeholder={placeholder}
      />
      <div className={classes.overlayBox}>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </div>
    </Combobox>
  );
}
