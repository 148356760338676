import React from "react";
import { imageUrl } from "../../Config/apiUrl";
import classes from "./MechanicTypeCard.module.css";

function MechanicTypeCard({ data }) {
  return (
    <div className={classes.card} data-aos="flip-up">
      <img src={`${imageUrl(data?.icon)}`} alt="..." />
      <h4>{data?.title}</h4>
    </div>
  );
}

export default MechanicTypeCard;
