import React, { useEffect, useState } from "react";
import DesktopFooter from "./DesktopFooter";
import MobileFooter from "./MobileFooter";
import { isMobileViewHook } from "../../CustomHooks/isMobileViewHook";
import { BaseURL } from "../../Config/apiUrl";
import { Get } from "../../Axios/AxiosFunctions";

function Footer({ containerClass }) {
  const [isMobile, setIsMobile] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [blurLoader, setBlurLoader] = useState(false);

  useEffect(() => {
    isMobileViewHook(setIsMobile);
  }, []);

  const getData = async () => {
    const ApiURL = BaseURL(`web/all-source`);
    setBlurLoader(true);
    const responseData = await Get(ApiURL);
    if (responseData !== undefined) {
      setDataList(responseData.data.data);
    } else {
      setDataList([]);
      console.log("error");
    }
    setBlurLoader(false);
  };

  useEffect(() => {
    getData();
  }, []);

  //   return <DesktopFooter />;
  return isMobile ? (
    <MobileFooter dataList={dataList} containerClass={containerClass} />
  ) : (
    <DesktopFooter dataList={dataList} containerClass={containerClass} />
  );
}

export default Footer;
