import React, { useEffect, useState } from "react";
import { GallerHeroImg } from "../../constant/imagePath";
import Header from "../../stories/Header";
import HeroSection from "../../stories/HeroSection";
import Aos from "aos";
import classes from "./UnclaimedBusinessListing.module.css";
import Footer from "../../stories/Footer";
import { Col, Container, Row } from "react-bootstrap";
import UnClaimedBusinessCard from "../../stories/UnClaimedBusinessCard";
import { Get } from "../../Axios/AxiosFunctions";
import { BaseURL } from "../../Config/apiUrl";
import { useSelector } from "react-redux";
import NoData from "../../stories/NoData/NoData";

function UnclaimedBusinessListing() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = useSelector((state) => state.authReducer.accessToken);

  async function getData() {
    const url = BaseURL("customer/unclaimed-business-users");
    setIsLoading(true);
    const response = await Get(url);
    if (response !== undefined) {
      setData(response?.data?.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }

  console.log({ data });

  useEffect(() => {
    getData();
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className={classes.header}>
        <Header
          bgTransparent={true}
          containerClass={`${[classes.container].join(" ")}`}
        />
      </div>
      <HeroSection
        title={"UnClaimed Businesses"}
        description={"There are many unclaimed businesses here"}
        BgImg={GallerHeroImg}
      />

      <section className={classes.cardsSection}>
        <Container fluid className={classes.container}>
          <Row>
            {isLoading ? (
              [1, 2, 3, 4]?.map((item) => (
                <Col md={6}>
                  {" "}
                  <div
                    className={`SkeletonContainer ${classes.skeleton}`}
                  ></div>
                </Col>
              ))
            ) : data?.length == 0 ? (
              <NoData text="No Businesses Found" className={classes.noData} />
            ) : (
              data?.map((item) => {
                return (
                  <Col md={6} className={classes.mb20}>
                    <UnClaimedBusinessCard item={item} />
                  </Col>
                );
              })
            )}
          </Row>
        </Container>
      </section>

      <Footer containerClass={classes.container} />
    </div>
  );
}

export default UnclaimedBusinessListing;
