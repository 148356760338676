import React, { useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./MessageModal.module.css";
import { apiHeader, BaseURL, imageUrl } from "../../../Config/apiUrl";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

function MessageModal({
  show,
  setShow,
  onClick,
  title,
  user,
  buttonText,
  isSendingMessage,
}) {
  const user_type = useSelector((state) => state?.authReducer?.user_type);

  const [message, setMessage] = useState("");
  const cusDetail = user?.customerDetailId?.UserId;

  return (
    <ModalSkeleton show={show} setShow={setShow} borderRadius={"40px"}>
      <Row className={`${classes.modal_container}`}>
        <Col md={12}>
          <div className={`${classes.profileSubContainer}`}>
            <div className={`${classes.profile}`}>
              <img
                src={imageUrl(
                  user_type === "customer"
                    ? user?.photo
                    : user?.customerDetailId?.photo
                )}
              />
            </div>
            <div className={`${classes.bookingStatus_conatiner}`}>
              <h5 className={`${classes.t_t_c}`}>
                {user_type === "customer" ? user?.userName : user?.name}
              </h5>
              <h6>
                {user_type === "customer"
                  ? user?.UserId?.email
                  : cusDetail?.email}
              </h6>
            </div>
          </div>
          <h4 className={`${classes.send_para} ${classes.t_t_c}`}>{title}</h4>
        </Col>
        <Col md={12} className={`${classes.textArea_div}`}>
          <textarea
            value={message}
            placeholder="Write a message"
            rows={5}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Col>
        {/* <ModalFooter> */}
        <div className={`${classes.send_btn_div}`}>
          <button
            className={`${classes.send_btn} ${classes.c_p}`}
            onClick={() => {
              onClick(message);
            }}
            disabled={message?.length === 0 || isSendingMessage}
          >
            {isSendingMessage ? "SENDING..." : buttonText}
          </button>
        </div>
      </Row>
    </ModalSkeleton>
  );
}

export default MessageModal;
