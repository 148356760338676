import React, { useState, useEffect, useRef } from "react";
import classes from "./ProfilePage.module.css";
import Header from "./../../stories/Header/index";
import Footer from "../../stories/Footer";
import Button from "./../../stories/Button/index";
import StarRatings from "react-star-ratings";
import { FaCar, FaSnapchat } from "react-icons/fa";
import { MdLocationPin, MdDescription, MdEmail } from "react-icons/md";
import { ProfileWithEditButton } from "./../../stories/ProfileWithEditButton/index";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { BsFacebook, BsLinkedin, BsTelephoneFill } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";
import { ImGooglePlus3 } from "react-icons/im";
import PDFImageView from "../../stories/PDFImageView";
import { useHistory } from "react-router-dom";

function ProfilePage() {
  const history = useHistory();
  const { isLogin, accessToken, user_type, user } = useSelector(
    (state) => state.authReducer
  );

  return (
    <div>
      <style>
        {`
        .${classes.profileImageContainer} {
          bottom:${user_type === "mechanic" ? "-40px" : "0px"}
        }
        `}
      </style>
      {/* Header  */}
      <div className={classes.header}>
        <Header
          containerClass={`${[classes.container].join(" ")}`}
          isLogoBlack={true}
          bgTransparent={false}
        />
      </div>

      {/* Body User Detail Section Start */}
      <div className={`${classes.bodyContainer} `}>
        <div
          className={`${classes.userImagesContainer}`}
          style={{
            height: user_type === "mechanic" ? 400 : 120,
          }}
        >
          {user_type === "mechanic" && (
            <ProfileWithEditButton
              updateImage={user?.cover_image}
              className={classes.coverImage}
              onErrorImage={true}
              classImg={classes.coverImageTagClass}
              edit={false}
            />
          )}
          <div className={`${classes.profileImageContainer}`}>
            <ProfileWithEditButton updateImage={user?.photo} edit={false} />
          </div>
        </div>
        <div
          className={`${classes.userDetailContainer}`}
          style={{
            marginTop: user_type === "mechanic" ? 50 : 10,
          }}
        >
          <div className={classes.centerItems}>
            <p className={`${classes.userNameText}`}>{user?.userName}</p>
            {user_type === "mechanic" && (
              <>
                <StarRatings
                  rating={user?.ratingsAverage}
                  starRatedColor="red"
                  numberOfStars={5}
                  name="rating"
                  starDimension="16px"
                  starSpacing="1px"
                />
                <p>{`${user?.review?.length} ${
                  user?.review?.length === 1 ? "review" : "reviews"
                }`}</p>
                <Row className={`g-0`}>
                  <Col md={12} className={classes.SocialLinkContainer}>
                    {user?.snapchatLink && (
                      <FaSnapchat
                        size={24}
                        color={"var(--main-color-red)"}
                        onClick={() => {
                          window.open(
                            `https://${user?.snapchatLink}`,
                            "_blank"
                          );
                        }}
                        className={classes.socialIcon}
                      />
                    )}
                    {user?.facebookLink && (
                      <BsFacebook
                        size={24}
                        color={"var(--main-color-red)"}
                        onClick={() => {
                          window.open(
                            `https://${user?.facebookLink}`,
                            "_blank"
                          );
                        }}
                        className={classes.socialIcon}
                      />
                    )}
                    {user?.instagramLink && (
                      <GrInstagram
                        size={24}
                        color={"var(--main-color-red)"}
                        onClick={() => {
                          window.open(
                            `https://${user?.instagramLink}`,
                            "_blank"
                          );
                        }}
                        className={classes.socialIcon}
                      />
                    )}
                    {user?.googleLink && (
                      <ImGooglePlus3
                        size={24}
                        color={"var(--main-color-red)"}
                        onClick={() => {
                          window.open(`https://${user?.googleLink}`, "_blank");
                        }}
                        className={classes.socialIcon}
                      />
                    )}
                    {user?.linkedinLink && (
                      <BsLinkedin
                        size={24}
                        color={"var(--main-color-red)"}
                        onClick={() => {
                          window.open(
                            `https://${user?.linkedinLink}`,
                            "_blank"
                          );
                        }}
                        className={classes.socialIcon}
                      />
                    )}
                  </Col>
                </Row>
              </>
            )}
          </div>

          <Row className={`g-0 ${classes.personalDetailRow}`}>
            <Col md={3} sm={12}>
              <MdEmail size={24} color={"#E60000"} />
              <span>{user?.UserId?.email}</span>
            </Col>
            {user_type === "mechanic" && (
              <Col md={2}>
                <BsTelephoneFill size={20} color={"#E60000"} />
                <span>
                  {user?.contactNo !== null ? user?.contactNo : "not edit yet"}
                </span>
              </Col>
            )}
            {user_type == "customer" && (
              <Col md={8} sm={12}>
                <FaCar size={24} color={"#E60000"} />
                <span>
                  {user?.vehicle
                    ? user?.vehicle
                    : "This information is not edit yet..."}
                </span>
              </Col>
            )}
            <Col md={user_type === "mechanic" ? 7 : 12} sm={12}>
              <MdLocationPin size={24} color={"#E60000"} />
              <span>
                {user?.address
                  ? user?.address
                  : "This information is not edit yet..."}
              </span>
            </Col>
            <Col md={12} sm={12}>
              <MdDescription size={24} color={"#E60000"} />
              <span>
                {user?.description
                  ? user?.description
                  : "This information is not edit yet..."}
              </span>
            </Col>
          </Row>
          {user_type === "mechanic" && (
            <>
              <Row className={`g-0 ${classes.certificateAndServicesRow}`}>
                <Col md={12} sm={12}>
                  <h4>CERTIFICATIONS</h4>
                </Col>
                {user?.certificates?.map((items, index) => {
                  return (
                    <Col md={6} sm={12}>
                      <h5>{items?.certificateType}</h5>
                      <ul>
                        {items?.certificateItem?.map((list, index) => {
                          return (
                            <li className={`${classes.greyText}`}>
                              {list?.CertificateName}
                            </li>
                          );
                        })}
                      </ul>
                    </Col>
                  );
                })}
              </Row>
              <Row className={`g-0 ${classes.certificateAndServicesRow}`}>
                <Col md={12} sm={12}>
                  <h4>SERVICES</h4>
                </Col>
                {user?.services?.map((items, index) => {
                  return (
                    <Col md={6} sm={12}>
                      <h5>{items?.serviceType}</h5>
                      <ul>
                        {items?.serviceItems?.map((list, index) => {
                          return (
                            <li className={`${classes.greyText}`}>
                              {list?.serviceName}
                            </li>
                          );
                        })}
                      </ul>
                    </Col>
                  );
                })}
              </Row>
              <Row className={`g-0 ${classes.certificateAndServicesRow}`}>
                <Col md={12} sm={12}>
                  <h4>Public Certificates PDF</h4>
                </Col>

                {user?.documents?.length > 0 &&
                  user?.documents?.map((el, index) => {
                    return (
                      <PDFImageView
                        item={el}
                        index={index}
                        local={false}
                        isCloseable={false}
                      />
                    );
                  })}
              </Row>
              <Row className={`g-0 ${classes.certificateAndServicesRow}`}>
                <Col md={12} sm={12}>
                  <h4>Private Certificates PDF</h4>
                </Col>

                {user?.privateDocuments?.length > 0 &&
                  user?.privateDocuments?.map((el, index) => {
                    return (
                      <PDFImageView
                        item={el}
                        index={index}
                        local={false}
                        isCloseable={false}
                      />
                    );
                  })}
              </Row>
            </>
          )}
          <Button
            label={"Edit Profile"}
            className={classes.editbtn}
            onClick={() => history.push("/edit-profile")}
          />
        </div>
      </div>

      {/* Body User Detail Section End */}

      {/* Footer section */}

      <section className={`${classes.Footer_Section}`}>
        <Footer containerClass={classes.container} />
      </section>

      {/* Footer section */}
    </div>
  );
}

export default ProfilePage;
