import React, { useEffect } from "react";
import classes from "./button.module.css";

const Button = ({
  label,
  customStyle,
  onClick,
  disabled = false,
  children,
  className,
  ...props
}) => {
  let styleObject = Object.assign(
    {},
    customStyle
  );

  return (
    <>
      <button
        className={`${[classes.btn, className
        ].join(" ")}`}
        style={{
          ...styleObject,
        }}
        onClick={onClick}
        disabled={disabled}
        {...props}
      >
        {label && label}
        {children && children}
      </button>
    </>
  );
};

export default Button
