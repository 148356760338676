import React, { useEffect, useState } from "react";
import classes from "./BookMechanicModal.module.css";
import ModalSkeleton from "../ModalSkeleton";
import Input from "../../../stories/Input";
import Button from "../../../stories/Button";
import { useSelector } from "react-redux";
import LocationInput from "../../../stories/LocationInput";

const BookMechanicModal = ({
  show,
  setShow,
  mechanicData,
  loading,
  handleBook,
}) => {
  const userData = useSelector((state) => state?.authReducer?.user);
  const coords = useSelector((state) => state?.commonReducer?.defaultCoords);

  const [name, setName] = useState(userData?.userName);
  const [email, setEmail] = useState(userData?.UserId?.email);
  const [place, setPlace] = useState(null);
  const [myCoords, setMyCoords] = useState(coords);
  const [workDetail, setWorkDetails] = useState("");
  const [vin, setVin] = useState("");

  const SettingParams = async () => {
    const params = {
      customerUserId: userData?.UserId._id,
      customerDetailId: userData?._id,
      mechanicUserId: mechanicData?.UserId?._id,
      mechanicDetailId: mechanicData?._id,
      mechanicName: mechanicData?.userName,
      name,
      email,
      VIN: vin,
      workDetail,
      serviceAddress: place,
      lat: myCoords?.lat,
      lng: myCoords?.lng,
    };
    await handleBook(params);
    // setName("");
    // setEmail("");
    // setPlace(null);
    // setWorkDetails("");
    // setVin("");
  };

  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      borderRadius={"40px"}
      width={"600px"}
    >
      <div className={classes.mainContentDiv}>
        <h3>Book Mechanic</h3>
        <h5>Enter Your Details</h5>
        <div className={classes.inputDiv}>
          <Input
            type={`text`}
            placeholder={"Enter Name"}
            setter={setName}
            value={name}
            disabled={true}
          />
        </div>
        <div className={classes.inputDiv}>
          <Input
            type={`email`}
            placeholder={"Enter Email"}
            setter={setEmail}
            value={email}
            disabled={true}
          />
        </div>
        <div className={classes.inputDiv}>
          <LocationInput
            setPlace={setPlace}
            placeholder="Loaction*"
            setCordinate={setMyCoords}
          />
        </div>
        <div className={classes.inputDiv}>
          <Input
            type={`text`}
            placeholder={"Car Vin*"}
            setter={setVin}
            value={vin}
          />
        </div>
        <div className={classes.inputDiv}>
          <Input
            type={`text`}
            placeholder={"Work Details*"}
            setter={setWorkDetails}
            value={workDetail}
          />
        </div>
        <div className={classes.BookBtnDiv}>
          <Button
            label={loading ? "Booking..." : "Book"}
            disabled={loading}
            onClick={SettingParams}
            className={classes.BookBtn}
          />
        </div>
      </div>
    </ModalSkeleton>
  );
};

export default BookMechanicModal;
