import {
  SET_LOCATION,
  SAVE_CURRENT_LOCATION,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  UPDATE_USER_DATA,
  SET_REFETCH,
  SET_SELECTEDNAV,
  SET_SELECTED_PARENT_NAV,
  CHANGE_PUSH_NOTIFICATION,
  CHANGE_ONBOARDING,
  UPDATE_USER_INTEREST_QUESTION,
  UPDATE_USER_EMAIL_VERIFICATION,
  LOGOUT_SUCCESS,
  DEFAULT_LAT_LNG,
  SET_REGISTRATION_TYPE,
  SET_CERTIFICATES_SERVICES_ARRAY,
  UPDATE_FCM_TOKEN,
} from "./actionType";

// export function loginFailure(email, response) {
//   return {
//     type: LOGIN_FAILURE,
//     payload: {email, response},
//   };
// }

export function updateUserEmailVerification(response) {
  return {
    type: UPDATE_USER_EMAIL_VERIFICATION,
    payload: { response },
  };
}

export function updateUserInterestQuestion(response) {
  return {
    type: UPDATE_USER_INTEREST_QUESTION,
    payload: { response },
  };
}

export const changePushNotification = (data) => ({
  type: CHANGE_PUSH_NOTIFICATION,
  data,
});
export const changeOnBoarding = (data) => ({
  type: CHANGE_ONBOARDING,
  data,
});

export const saveCurrentLocation = (data) => ({
  type: SAVE_CURRENT_LOCATION,
  data,
});

export const setLocationRedux = (lat, lng) => ({
  type: SET_LOCATION,
  lat,
  lng,
});

export const changeRefetch = (data) => ({
  type: SET_REFETCH,
  data,
});
export const changeSelectedNav = (data) => ({
  type: SET_SELECTEDNAV,
  data,
});
export const changeSelectedParentNav = (data) => ({
  type: SET_SELECTED_PARENT_NAV,
  data,
});

export function loginRequest(email, password) {
  return {
    type: LOGIN_REQUEST,
    payload: { email, password },
  };
}

export function saveLoginUserData(response) {
  return { type: LOGIN_SUCCESS, payload: { response } };
}

export function updateUserData(response) {
  return {
    type: UPDATE_USER_DATA,
    payload: { response },
  };
}

export function logout() {
  return {
    type: LOGOUT_REQUEST,
  };
}

export const setLogoutSuccessFul = (response) => ({
  type: LOGOUT_SUCCESS,
  response,
});
export const setFcmToken = (response) => ({
  type: UPDATE_FCM_TOKEN,
  response,
});

export const defaultCoords = (coords) => ({
  type: DEFAULT_LAT_LNG,
  coords,
});

export function setRegistrationType(type, item) {
  return {
    type: SET_REGISTRATION_TYPE,
    payload: { type, item },
  };
}
export function setCertificatesAndServices(data) {
  return {
    type: SET_CERTIFICATES_SERVICES_ARRAY,
    payload: data,
  };
}
