import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CustomAccordion from "../../stories/Accordion";
import Footer from "../../stories/Footer";
import Header from "../../stories/Header";
import HeroSection from "../../stories/HeroSection";
import classes from "./UserSupportPage.module.css";
import Aos from "aos";
import { FaEnvelope } from "react-icons/fa";
import Input from "../../stories/Input";
import TextArea from "../../stories/TextArea";
import Button from "../../stories/Button";
import { apiHeader, BaseURL } from "../../Config/apiUrl";
import { toast } from "react-toastify";
import { Post } from "../../Axios/AxiosFunctions";

function Support(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);

  const handleSubmitApi = async () => {
    const contactUrl = BaseURL("contact-us");
    let params = {
      firstName,
      lastName,
      email,
      phoneNo: phoneNumber,
      message,
      formType: "contactsupport",
    };
    for (let key in params) {
      if (params[key] == "") {
        return toast.error("Please fill out all fields");
      }
    }

    setLoading(true);
    const responseData = await Post(contactUrl, params, apiHeader());
    if (responseData !== undefined) {
      toast.success(`Form Successfully Submitted.`);
      setEmail("");
      setLastName("");
      setFirstName("");
      setPhoneNumber("");
      setMessage("");
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <Header
          bgTransparent={false}
          isLogoBlack={true}
          containerClass={`${[classes.container].join(" ")}`}
        />
      </div>

      <section className={classes.faqSection}>
        <Container className={classes.container}>
          <Row>
            <Col md={12} className={classes.supportHead}>
              <h2>CONTACT SUPPORT</h2>
            </Col>
            <Col md={12} lg={4} className={classes.mb40}>
              <div className={classes.card}>
                <div className={classes.dGrid}>
                  <div className={classes.email}>
                    <FaEnvelope />
                  </div>
                  <h2>Email</h2>
                </div>
              </div>
            </Col>
            <Col md={12} lg={8}>
              <Row>
                <Col md={6} sm={6} xs={12} className={classes.mBottom20}>
                  <Input
                    placeholder={"First Name"}
                    value={firstName}
                    setter={setFirstName}
                  />
                </Col>
                <Col md={6} sm={6} xs={12} className={classes.mBottom20}>
                  <Input
                    placeholder={"Last Name"}
                    value={lastName}
                    setter={setLastName}
                  />
                </Col>
                <Col md={6} sm={6} xs={12} className={classes.mBottom20}>
                  <Input
                    placeholder={"Phone Number"}
                    value={phoneNumber}
                    setter={setPhoneNumber}
                    type={"number"}
                  />
                </Col>
                <Col md={6} sm={6} xs={12} className={classes.mBottom20}>
                  <Input
                    placeholder={"Email Address"}
                    value={email}
                    setter={setEmail}
                    type={"email"}
                  />
                </Col>
                <Col md={12}>
                  <TextArea
                    placeholder={"Message"}
                    value={message}
                    setter={setMessage}
                    rows={5}
                  />
                </Col>
              </Row>
            </Col>
            {/* Button Col */}
            <Col md={12}>
              <Row>
                <Col lg={{ span: 8, offset: 4 }} sm={12}>
                  <div className={classes.submitBtnDiv}>
                    <Button
                      label={loading ? "Submitting..." : "Submit"}
                      onClick={() => handleSubmitApi()}
                      className={classes.submitBtn}
                      disabled={loading}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer containerClass={classes.container} />
    </div>
  );
}

export default Support;
