import React, { useEffect, useState } from "react";
import classes from "./ViewMechanicProfile.module.css";
import { apiHeader, BaseURL, imageUrl } from "../../Config/apiUrl";
import { Get, Post } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Footer from "../../stories/Footer";
import Header from "../../stories/Header";
import Button from "../../stories/Button";
import { Col, Row } from "react-bootstrap";
import { MdDescription, MdLocationPin, MdEmail } from "react-icons/md";
import { FiMail } from "react-icons/fi";
import StarRatings from "react-star-ratings";
import BookMechanicModal from "../../Components/Modals/BookMechanicModal";
import MessageModal from "../../Components/Modals/MessageModal";
import { toast } from "react-toastify";
import { ProfileWithEditButton } from "./../../stories/ProfileWithEditButton/index";
import { FaCar, FaSnapchat } from "react-icons/fa";
import PDFImageView from "../../stories/PDFImageView";
import { BsFacebook, BsLinkedin, BsTelephoneFill } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";
import { ImGooglePlus3 } from "react-icons/im";

function ViewMechanicProfile() {
  const {
    accessToken,
    user_type,
    user: userData,
    fcmToken,
  } = useSelector((state) => state.authReducer);
  const [certificates, setCertificates] = useState([]);
  const [services, setServices] = useState([]);
  const [user, setUser] = useState();

  console.log("mec", fcmToken);

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [bookModal, setBookModal] = useState(false);
  const [sendModal, setSendModal] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);
  const [bookloading, setBookLoading] = useState(false);

  const headers = apiHeader(accessToken);

  const getMechanicProfileData = async () => {
    const mechaincDataApi = BaseURL(`mechanic/mechanic-detail/${id}`);
    setLoading(true);
    const response = await Get(mechaincDataApi, accessToken);
    if (response !== undefined) {
      setUser(response?.data?.data?.user);
      setCertificates(response?.data?.data?.certificates);
      setServices(response?.data?.data?.services);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMechanicProfileData();
  }, []);

  const handleBook = async (params) => {
    const url = BaseURL("booking/create");

    for (let key in params) {
      if (params[key] == "" || params[key] == null) {
        return toast.error("Please enter required fields.");
      }
    }
    setBookLoading(true);
    const response = await Post(url, params, headers);
    if (response !== undefined) {
      console.log("Booking", response);
      const updateUser = { ...user, isActiveBooking: true };
      setUser(updateUser);
      console.log(response?.data, "booking response");
      setBookModal(false);
      toast.success(
        `🙌 ${user?.userName} Mechanic has been booked and is on his way!`
      );
      setBookModal(false);
    }
    setBookLoading(false);
  };

  const sendMessage = async (message) => {
    const newMessage = {
      _id: Math.random() * 100000,
      text: message,
      createdAt: Date.now(),
      user: {
        // _id: user?.UserId?._id,
        _id: userData?.UserId?._id,
        name: userData?.userName,
        avatar: userData?.photo,
      },
    };

    const params = {
      msg: newMessage,
      msgTo: user?._id,
      currentUser: user_type,
      fcmToken: "",
      roomId: user?.roomId,
    };

    const url = BaseURL(`chat/message/create`);
    setMessageLoading(true);
    const response = await Post(url, params, headers);
    if (response !== undefined) {
      console.log(response?.data, "message response");
      toast.success(`Message Sent To ${user?.userName}`);
      setMessageLoading(false);
      setSendModal(false);
    } else {
      setMessageLoading(false);
    }
  };

  const updateCall = async () => {
    const url = BaseURL(`update/call`);
    const params = {
      userId: user?.UserId?._id,
    };
    await Post(url, params, headers);
  };

  return (
    <div>
      {/* <Header /> */}
      <div className={classes.header}>
        <Header
          containerClass={`${[classes.container].join(" ")}`}
          isLogoBlack={true}
          bgTransparent={false}
        />
      </div>

      {/* Body User Detail Section Start */}
      <div className={[classes.bodyContainer, loading && "pageBlur"].join(" ")}>
        <div
          className={`${classes.userImagesContainer}`}
          style={{
            height: 400,
          }}
        >
          <ProfileWithEditButton
            updateImage={user?.cover_image}
            className={classes.coverImage}
            onErrorImage={true}
            classImg={classes.coverImageTagClass}
            edit={false}
          />
          <div className={`${classes.profileImageContainer}`}>
            <ProfileWithEditButton updateImage={user?.photo} edit={false} />
          </div>
        </div>
        <div
          className={`${classes.userDetailContainer}`}
          style={{
            marginTop: 50,
          }}
        >
          <div className={classes.centerItems}>
            <p className={`${classes.userNameText}`}>{user?.userName}</p>
            <>
              <StarRatings
                rating={user?.ratingsAverage}
                starRatedColor="red"
                numberOfStars={5}
                name="rating"
                starDimension="16px"
                starSpacing="1px"
              />
              <p>{`${user?.review?.length} ${
                user?.review?.length === 1 ? "review" : "reviews"
              }`}</p>
              <Row className={`g-0`}>
                <Col md={12} className={classes.SocialLinkContainer}>
                  {user?.snapchatLink && (
                    <FaSnapchat
                      size={24}
                      color={"var(--main-color-red)"}
                      onClick={() => {
                        window.open(`https://${user?.snapchatLink}`, "_blank");
                      }}
                      className={classes.socialIcon}
                    />
                  )}
                  {user?.facebookLink && (
                    <BsFacebook
                      size={24}
                      color={"var(--main-color-red)"}
                      onClick={() => {
                        window.open(`https://${user?.facebookLink}`, "_blank");
                      }}
                      className={classes.socialIcon}
                    />
                  )}
                  {user?.instagramLink && (
                    <GrInstagram
                      size={24}
                      color={"var(--main-color-red)"}
                      onClick={() => {
                        window.open(`https://${user?.instagramLink}`, "_blank");
                      }}
                      className={classes.socialIcon}
                    />
                  )}
                  {user?.googleLink && (
                    <ImGooglePlus3
                      size={24}
                      color={"var(--main-color-red)"}
                      onClick={() => {
                        window.open(`https://${user?.googleLink}`, "_blank");
                      }}
                      className={classes.socialIcon}
                    />
                  )}
                  {user?.linkedinLink && (
                    <BsLinkedin
                      size={24}
                      color={"var(--main-color-red)"}
                      onClick={() => {
                        window.open(`https://${user?.linkedinLink}`, "_blank");
                      }}
                      className={classes.socialIcon}
                    />
                  )}
                </Col>
              </Row>
            </>
          </div>

          <Row className={`g-0 ${classes.personalDetailRow}`}>
            <Col md={5} lg={4} sm={12}>
              <MdEmail size={24} color={"#E60000"} />
              <span>{user?.UserId?.email}</span>
            </Col>

            <Col md={5} lg={6} sm={12}>
              <MdLocationPin size={24} color={"#E60000"} />
              <span>
                {user?.address
                  ? user?.address
                  : "This information is not edit yet..."}
              </span>
            </Col>
            <Col md={2} lg={2}>
              <BsTelephoneFill size={20} color={"#E60000"} />
              <span onClick={updateCall}>
                {user?.contactNo !== null ? (
                  <a
                    href={`tel:+${user?.contactNo}`}
                    className={[classes.phoneNumber]}
                  >
                    {user?.contactNo}
                  </a>
                ) : (
                  "not added"
                )}
              </span>
            </Col>

            <Col md={12} sm={12}>
              <MdDescription size={24} color={"#E60000"} />
              <span>
                {user?.description
                  ? user?.description
                  : "This information is not edit yet..."}
              </span>
            </Col>
          </Row>
          <>
            <Row className={`g-0 ${classes.certificateAndServicesRow}`}>
              <Col md={12} sm={12}>
                <h4>CERTIFICATIONS</h4>
              </Col>
              {certificates?.map((items, index) => {
                return (
                  <Col md={6} sm={12}>
                    <h5>{items?.certificateType}</h5>
                    <ul>
                      {items?.certificateItem?.map((list, index) => {
                        return (
                          <li className={`${classes.greyText}`}>
                            {list?.CertificateName}
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                );
              })}
            </Row>
            <Row className={`g-0 ${classes.certificateAndServicesRow}`}>
              <Col md={12} sm={12}>
                <h4>SERVICES</h4>
              </Col>
              {services?.map((items, index) => {
                return (
                  <Col md={6} sm={12}>
                    <h5>{items?.serviceType}</h5>
                    <ul>
                      {items?.serviceItems?.map((list, index) => {
                        return (
                          <li className={`${classes.greyText}`}>
                            {list?.serviceName}
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                );
              })}
            </Row>
            <Row className={`g-0 ${classes.certificateAndServicesRow}`}>
              <Col md={12} sm={12}>
                <h4>Public Certificates PDF</h4>
              </Col>

              {user?.documents?.length > 0 ? (
                user?.documents?.map((el, index) => {
                  return <PDFImageView item={el} index={index} local={false} />;
                })
              ) : (
                <p className={classes.noCertificate}>No Public Certificates</p>
              )}
            </Row>
            <Row className={`g-0 ${classes.certificateAndServicesRow}`}>
              <Col md={12} sm={12}>
                <h4>Private Certificates PDF</h4>
              </Col>

              {user?.privateDocuments?.length > 0 ? (
                user?.privateDocuments?.map((el, index) => {
                  return <PDFImageView item={el} index={index} local={false} />;
                })
              ) : (
                <p className={classes.noCertificate}>No Private Certificates</p>
              )}
            </Row>
          </>
          {user?.isActiveBooking ? (
            <Button
              label={"Send Message"}
              className={classes.editbtn}
              onClick={() => setSendModal(true)}
            />
          ) : (
            <Button
              label={"Contact Mechanic"}
              className={classes.editbtn}
              onClick={() => setBookModal(true)}
            />
          )}
        </div>
      </div>

      {/* Footer section */}
      <section className={`${classes.Footer_Section}`}>
        <Footer containerClass={classes.container} />
      </section>
      {/* Footer section */}

      <BookMechanicModal
        show={bookModal}
        setShow={setBookModal}
        mechanicData={user}
        handleBook={handleBook}
        loading={bookloading}
      />

      <MessageModal
        show={sendModal}
        setShow={setSendModal}
        title={`Please Write Your Message Below...`}
        user={user}
        onClick={sendMessage}
        buttonText={"SEND"}
        isSendingMessage={messageLoading}
      />
    </div>
  );
}

export default ViewMechanicProfile;
