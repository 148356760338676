import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CustomAccordion from "../../stories/Accordion";
import Footer from "../../stories/Footer";
import Header from "../../stories/Header";
import HeroSection from "../../stories/HeroSection";
import classes from "./RegistrationForm.module.css";
import Aos from "aos";
import Input from "../../stories/Input";
import TextArea from "../../stories/TextArea";
import { Checkbox } from "../../stories/Checkbox";
import Button from "../../stories/Button";
import { apiHeader, BaseURL, imageUrl } from "../../Config/apiUrl";
import { Get, Post } from "../../Axios/AxiosFunctions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import LocationInput from "../../stories/LocationInput";
import PaymentModal from "../../Components/Modals/PaymentModal";
import { useHistory } from "react-router-dom";
import { setRegistrationType } from "../../store/Actions/authAction";
import ConfirmSubmissionModal from "../../Components/Modals/ConfirmSubmissionModal";

function RegistrationForm(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    certificatesArray,
    servicesArray,
    defaultCoords,
    registrationType,
    packageDetail,
  } = useSelector((state) => state?.commonReducer);

  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [googleLink, setGoogleLink] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [linkedInLink, setLinkedInLink] = useState("");
  const [snapChatLink, setSnapChatLink] = useState("");
  const [location, setLocation] = useState("");
  const [myCoords, setMyCoords] = useState(defaultCoords);
  const [biography, setBiography] = useState("");
  const [loading, setLoading] = useState(false);

  const [buyPackageLoading, setBuyPackageLoading] = useState(false);

  const [blurLoader, setBlurLoader] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [selectedCertificates, setSelectedCertificates] = useState([]);
  const [selectedCertificatesObjects, setSelectedCertificatesObjects] =
    useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedServicesObjects, setSelectedServicesObjects] = useState([]);

  const [termsAndCondition, setTermsAndCondition] = useState([]);
  const [providedInfo, setProvidedInfo] = useState([]);

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [confirmSubmissionModal, setConfirmSubmissionModal] = useState(false);

  const [apiToken, setApiToken] = useState(null);

  const getData = async () => {
    const url = BaseURL(
      `pages?pages=registrationForm&goals=false&service=false&aboutusItem=false&faq=false&all=false`
    );
    setBlurLoader(true);
    const response = await Get(url);
    if (response !== undefined) {
      setDataList(response.data.data);
    } else {
      setDataList([]);
    }
    setBlurLoader(false);
  };

  useEffect(() => {
    getData();
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);

  const handleSelectedCertificates = (type, item, itemType) => {
    let index = -1;
    let newItem = {};
    let data =
      itemType == "certificate"
        ? selectedCertificatesObjects.slice()
        : selectedServicesObjects.slice();
    if (itemType == "certificate") {
      index = selectedCertificatesObjects.findIndex(
        (x) => x.certificateItemId == item?._id
      );
      if (index == -1) {
        newItem = {
          mechanicUserId: null,
          mechanicDetailId: null,
          certificateTypeId: type?._id,
          certificateItemId: item?._id,
        };
      }
    } else {
      index = selectedCertificatesObjects.findIndex(
        (x) => x.certificateItemId == item?._id
      );
      if (index == -1) {
        newItem = {
          mechanicUserId: null,
          mechanicDetailId: null,
          serviceTypeId: type?._id,
          serviceItemId: item?._id,
        };
      }
    }
    if (index == -1) {
      data.push(newItem);
    } else {
      data?.splice(index, 1);
    }
    if (itemType == "certificate") {
      setSelectedCertificatesObjects(data);
    } else {
      setSelectedServicesObjects(data);
    }
  };

  const HandleSignUp = async () => {
    if (email !== confirmEmail) {
      return toast.error(`Email and ConfirmEmail Not Match!`);
    }
    const url = BaseURL("web-signup");
    let params = {
      email,
      userName,
      address: location,
      password,
      passwordConfirm: confirmPassword,
      role: "mechanic",
      isTermsAgree: termsAndCondition?.length === 1 ? true : false,
      isInformationProvided: providedInfo?.length === 1 ? true : false,
      selectedCertificates: selectedCertificatesObjects,
      selectedServices: selectedServicesObjects,
      lat: myCoords?.lat,
      lng: myCoords?.lng,
      description: biography,
      contactNo,
    };

    for (let key in params) {
      if (
        params[key] === "" ||
        params[key] === false ||
        params[key]?.length === 0
      ) {
        return toast.error("Please Enter Required Fields.");
      }
    }

    params = {
      ...params,
      facebookLink,
      instagramLink,
      snapchatLink: snapChatLink,
      googleLink,
      linkedinLink: linkedInLink,
    };
    setLoading(true);
    const response = await Post(url, params, apiHeader());
    if (response !== undefined) {
      console.log(response);
      setApiToken(response?.data?.data?.token);
      setEmail("");
      setConfirmEmail("");
      setUserName("");
      setPassword("");
      setConfirmPassword("");
      setGoogleLink("");
      setFacebookLink("");
      setInstagramLink("");
      setLinkedInLink("");
      setSnapChatLink("");
      setLocation("");
      setBiography("");
      setSelectedCertificates([]);
      setSelectedServices([]);
      setTermsAndCondition([]);

      if (registrationType == null) {
        setConfirmSubmissionModal(true);
      } else {
        setShowPaymentModal(true);
      }
    }
    setLoading(false);
  };

  const buyPackage = async (paymentMethod) => {
    console.log(paymentMethod, "paymentMethod");
    console.log(paymentMethod?.id, "ID");

    const url = BaseURL("mechanic/create-subscription");
    const params =
      packageDetail?.product?.metadata?.premimum !== undefined
        ? {
            paymentMethodId: paymentMethod?.id,
            planId: packageDetail?.id,
            premimum: true,
          }
        : {
            planId: packageDetail?.id,
            paymentMethodId: paymentMethod?.id,
          };

    setBuyPackageLoading(true);
    const response = await Post(url, params, apiHeader(apiToken));
    if (response !== undefined) {
      console.log(response, "buyresponse");
      toast.success(
        `🙌 We have successfully created your account. Please check your email for verification.`,
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        }
      );
      await dispatch(setRegistrationType(null, null));
      setShowPaymentModal(true);
      setTimeout(() => {
        history.push(`/`);
      }, 1000);
    }
    setBuyPackageLoading(false);
  };

  return (
    <div className={[classes.page, blurLoader && "pageBlur"].join(" ")}>
      <div className={classes.header}>
        <Header
          bgTransparent={true}
          containerClass={`${[classes.container].join(" ")}`}
        />
      </div>
      <HeroSection
        BgImg={imageUrl(dataList?.pages?.cover_image)}
        title={dataList?.pages?.heading}
        sectionClass={classes.heroSection}
        description={dataList?.pages?.detail_description}
      />

      <section className={classes.faqSection}>
        <Container className={classes.container}>
          <Row>
            <Col md={12}>
              <h2>SUBMIT YOUR INFORMATION</h2>
              <p>
                (Fields marked with * are required for application. All fields
                except Permanent Business Address can be edited after your
                membership has been approved.)
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={6} className={classes.mBlock20}>
              <Input
                label={"Email Address"}
                placeholder={"Email Address"}
                value={email}
                setter={setEmail}
                type={"text"}
              />
            </Col>
            <Col md={6} className={classes.mBlock20}>
              <Input
                label={"Confirm Email"}
                placeholder={"Confirm Email"}
                value={confirmEmail}
                setter={setConfirmEmail}
                type={"text"}
              />
            </Col>
            <Col md={6} className={classes.mBlock20}>
              <Input
                label={"Password"}
                placeholder={"Password"}
                value={password}
                setter={setPassword}
                type={"password"}
              />
            </Col>
            <Col md={6} className={classes.mBlock20}>
              <Input
                label={"Confirm Password"}
                placeholder={"Confirm Password"}
                value={confirmPassword}
                setter={setConfirmPassword}
                type={"password"}
              />
            </Col>
            <Col md={6} className={classes.mBlock20}>
              <Input
                label={"Username"}
                placeholder={"Enter Business Username"}
                value={userName}
                setter={setUserName}
                type={"text"}
              />
            </Col>
            <Col md={6} className={classes.mBlock20}>
              <Input
                label={"Contact No"}
                placeholder={"Contact No"}
                value={contactNo}
                setter={setContactNo}
                type={"number"}
              />
            </Col>
            <Col md={6} className={classes.mBlock20}>
              <Input
                label={"Google Link"}
                placeholder={"www.plus.google.com/username"}
                value={googleLink}
                setter={setGoogleLink}
                type={"text"}
              />
            </Col>
            <Col md={6} className={classes.mBlock20}>
              <Input
                label={"Facebook Link"}
                placeholder={"www.plus.facebook.com/username"}
                value={facebookLink}
                setter={setFacebookLink}
                type={"text"}
              />
            </Col>
            <Col md={6} className={classes.mBlock20}>
              <Input
                label={"LinkedIn Link"}
                placeholder={"www.plus.linkedin.com/username"}
                value={linkedInLink}
                setter={setLinkedInLink}
                type={"text"}
              />
            </Col>
            <Col md={6} className={classes.mBlock20}>
              <Input
                label={"Instagram Link"}
                placeholder={"www.plus.instagram.com/username"}
                value={instagramLink}
                setter={setInstagramLink}
                type={"text"}
              />
            </Col>
            <Col md={6} className={classes.mBlock20}>
              <Input
                label={"Snapchat Link"}
                placeholder={"www.plus.snapchat.com/username"}
                value={snapChatLink}
                setter={setSnapChatLink}
                type={"text"}
              />
            </Col>
            <Col md={12} className={classes.mBlock20}>
              <LocationInput
                setPlace={setLocation}
                setCordinate={setMyCoords}
                placeholder={"Location*"}
              />
            </Col>
            <Col md={12} className={classes.mBlock20}>
              <TextArea
                label={"Biography"}
                placeholder={"Biography*"}
                value={biography}
                setter={setBiography}
                type={"text"}
                rows={6}
              />
            </Col>
          </Row>
          <Row className={classes.mBlock20}>
            <Col md={12}>
              <h3>CERTIFICATIONS</h3>
              <p>Check boxes for below option</p>
            </Col>
          </Row>
          <Row className={classes.mBlock20}>
            {certificatesArray?.map((item, i) => {
              return (
                <Col md={6} className={[classes.certificateCol]} key={i}>
                  <h3>{item?.CertificateType}</h3>
                  {item?.certificateItem?.map((certificate, i) => {
                    return (
                      <Checkbox
                        value={selectedCertificates}
                        label={certificate?.CertificateName}
                        disabled={false}
                        setValue={(e) => {
                          setSelectedCertificates(e);
                          handleSelectedCertificates(
                            item,
                            certificate,
                            "certificate"
                          );
                        }}
                      />
                    );
                  })}
                </Col>
              );
            })}
          </Row>

          <Row className={classes.mBlock20}>
            <Col md={12}>
              <h3>SERVICES</h3>
              <p>Check boxes for below option</p>
            </Col>
          </Row>
          <Row className={classes.mBlock20}>
            {servicesArray?.map((item, i) => {
              return (
                <Col md={6} className={[classes.certificateCol]} key={i}>
                  <h3>{item?.serviceType}</h3>
                  {item?.serviceItem?.map((service, i) => {
                    return (
                      <Checkbox
                        label={service?.serviceName}
                        value={selectedServices}
                        disabled={false}
                        setValue={(e) => {
                          setSelectedServices(e);
                          handleSelectedCertificates(item, service, "service");
                        }}
                      />
                    );
                  })}
                </Col>
              );
            })}
          </Row>
          <Row className={`${classes.mBlock20} ${classes.termsAndCondition}`}>
            <Col md={6}>
              <Checkbox
                label={
                  "By clicking Submit, you agree to our Terms & Conditions, Data Policy and Cookie Policy."
                }
                value={termsAndCondition}
                setValue={(e) => {
                  setTermsAndCondition(e);
                }}
                disabled={false}
              />
              <div className="mt-3">
                <Checkbox
                  label={
                    "I agree that all above information provided is true,If any Information found incorrect which can result your account to be deleted."
                  }
                  value={providedInfo}
                  setValue={(e) => {
                    setProvidedInfo(e);
                  }}
                  disabled={false}
                />
              </div>
              <div className={classes.submitBtnDiv}>
                <Button
                  label={loading ? "Submitting..." : "Submit"}
                  onClick={HandleSignUp}
                  className={classes.submitBtn}
                  disabled={loading}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer containerClass={classes.container} />

      <ConfirmSubmissionModal
        show={confirmSubmissionModal}
        setShow={setConfirmSubmissionModal}
        title={"Thank you! "}
        subtitle={
          "We have successfully created your account. Please check your email for verification."
        }
        buttonLabel={"DONE"}
        btnClick={() => history.push("/")}
      />

      <PaymentModal
        show={showPaymentModal}
        setShow={setShowPaymentModal}
        apiToken={apiToken}
        type={"registration"}
        buyPackage={buyPackage}
        buyPackageLoading={buyPackageLoading}
      />
    </div>
  );
}

export default RegistrationForm;
