import Aos from "aos";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GallerHeroImg } from "../../constant/imagePath";
import Footer from "../../stories/Footer";
import Header from "../../stories/Header";
import HeroSection from "../../stories/HeroSection";
import NoData from "../../stories/NoData/NoData";
import classes from "./Gallery.module.css";
import InstagramEmbed from "react-instagram-embed";
import { Get } from "../../Axios/AxiosFunctions";
import InstagramPostCard from "../../stories/InstagramPostCard";
import Loader from "../../stories/Loader";

function Gallery() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
    getInstaAccess();
  }, []);

  // Get Insta Post
  async function getInstaAccess() {
    const url = `https://graph.instagram.com/me/media?access_token=IGQVJVRkVwdjU3MTRxNlQyWExxUGE3c0pZAblV4a0lHeDVXX0p3cVpubVY5cW52OWN0SVpia3VBczZArZADdNZAXlYVzBwZAm1HV3M3VFk2V0RuQVNPTHp0WG5NczFlTHBMVlRGMmp2V19STmF6dGpfNGZAXSwZDZD&fields=id,caption,media_url,media_type,permalink,thumbnail_url,timestamp,username`;
    setIsLoading(true);
    const res = await Get(url);
    if (res !== undefined) {
      setData(res?.data?.data);
    }
    setIsLoading(false);
  }

  return (
    <div>
      <div className={classes.header}>
        <Header
          bgTransparent={true}
          containerClass={`${[classes.container].join(" ")}`}
        />
      </div>

      <HeroSection
        title={"Gallery"}
        description={
          "Our goal is to connect clients to local, honest, reputable mobile mechanics via our private network. Join our Network of Mobile Mechanics today!"
        }
        BgImg={GallerHeroImg}
        sectionClass={classes.heroSection}
      />

      <section className={classes.imagesSection}>
        <Container fluid className={classes.container}>
          <Row>
            {isLoading ? (
              <Loader />
            ) : data?.length == 0 ? (
              <NoData className={classes.noData} text={"No Posts Found"} />
            ) : (
              data?.map((item, index) => (
                <Col md={4}>
                  <InstagramPostCard item={item} index={index} />
                </Col>
              ))
            )}
          </Row>
        </Container>
      </section>
      <section className={classes.footerSection}>
        <Footer containerClass={classes.container} />
      </section>
    </div>
  );
}

export default Gallery;
