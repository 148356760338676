import {
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  UPDATE_USER_DATA,
  UPDATE_FCM_TOKEN,
} from "../Actions/actionType";

const INITIAL_STATE = {
  accessToken: "",
  isLogin: false,
  user_type: "",
  user: null,
  fcmToken: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user_type: action.payload.response?.user?.UserId?.role,
        user: action.payload.response?.user,
        isLogin: true,
        accessToken: action.payload.response?.token,
      };
      break;
    case UPDATE_USER_DATA:
      return {
        ...state,
        user: action?.payload?.response,
      };
      break;
    case UPDATE_FCM_TOKEN:
      return {
        ...state,
        fcmToken: action?.response,
      };
      break;
    case LOGOUT_REQUEST:
      return {
        ...state,
        accessToken: "",
        isLogin: false,
        user_type: "",
        user: null,
      };
    default:
      return state;
  }
};
