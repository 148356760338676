import React from "react";
import { Row } from "react-bootstrap";
import Button from "../../../stories/Button";
import { FiAlertTriangle } from 'react-icons/fi'
import classes from './confirmationModal.module.css'
import { Modal } from "react-bootstrap";


const ConfirmationModal = ({ show, setShow, subTitle,
    onClick,
    isApiCall,
}) => {
    return (
        <>
            <style jsx>{`
          .modal-content {
            width: 100%;
            border-radius: 20px;
            overflow: hidden;
          }
          .modal-body {
            padding: 24px;
          }
          .modal-header {
            flex-direction: column;
            background: var(--main-color-red);
            border-bottom: none;
            padding: 0.75rem;
          }
          .name {
            font-size: 18px;
            color: var(--text-color-black);
          }
        `}</style>
            <Modal show={show} onHide={() => setShow(false)} centered>
                <Modal.Header>
                    <div className={[classes.iconDiv].join(" ")}>
                        <FiAlertTriangle size={"60px"} color={"var(--white-color)"} />
                    </div>
                    <p className={[classes.header].join(" ")}>Are You Sure</p>
                </Modal.Header>
                <Modal.Body>
                    <div className={classes.content} >
                        <div className={classes.mainDiv}>
                            <h4>{subTitle}</h4>
                        </div>
                        <div className={classes.btnsBox}>
                            <Button
                                className={classes.yesBtn}
                                onClick={onClick}
                                disabled={isApiCall}
                            >
                                {isApiCall ? "Wait" : "Yes"}
                            </Button>
                            <Button
                                className={classes.noBtn}
                                onClick={async () => {
                                    setShow(false);
                                }}
                                disabled={isApiCall}
                            >
                                No
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};



export default ConfirmationModal;
