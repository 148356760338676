import React from 'react'
import StarRatings from 'react-star-ratings/build/star-ratings'
import { imageUrl } from '../../Config/apiUrl'
import classes from './ReviewCard.module.css'

function ReviewCard({ data }) {
    console.log({ data })
    return (
        <div className={classes.card}>
            <div>
                <img
                    src={imageUrl(data?.customerDetailId?.photo)}
                    style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                    }}
                />
            </div>
            <div className={classes.details}>
                <h5>{data?.name}</h5>
                <StarRatings
                    rating={data?.review[0]?.rating}
                    name="rating"
                    starSpacing="1px"
                    numberOfStars={5}
                    starDimension="16px"
                    starRatedColor="var(--main-color-yellow)"
                />
                <p>{data?.review[0]?.reviewMessage}</p>

            </div>
        </div>
    )
}

export default ReviewCard