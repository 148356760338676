import React, { useState, useEffect, useRef } from "react";
import LoginOrSignUpModal from "../../Components/Modals/LoginOrSignUp";
import { isMobileViewHook } from "../../CustomHooks/isMobileViewHook";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";
import ForgetPassword from "../../Components/Modals/ForgetPassword";
import { apiUrl, BaseURL, firebaseVapidObject } from "../../Config/apiUrl";
import { Get } from "../../Axios/AxiosFunctions";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import moment from "moment";
import { toast } from "react-toastify";
import { error, success, warning } from "../../constant/imagePath";

import { logout } from "../../store/Actions/authAction";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
// import LoginModal from "../../components/LoginModal";
// import {
//   apiUrl,
//   firebaseVapidObject,
//   imageUrl,
//   URL,
// } from "../../Config/apiUrl";
// import { Get, Post, Patch } from "../../Axios/AxiosFunctions";
// import ForgotPasswordModal from "../../Screens/ForgotPasswordModal";
// import firebase, { messaging } from "../../firebase";

const Header = ({ bgTransparent, containerClass, isLogoBlack }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const accessToken = useSelector((state) => state.authReducer.access_token);
  const userData = useSelector((state) => state.authReducer?.user);
  const user_type = userData?.user_type;
  const [isMobile, setIsMobile] = useState(false);
  const [scrollY, setScrollY] = useState(false);
  const [isShowLoginModal, setIsShowLoginModal] = useState(false);
  const [isShowForgetnModal, setIsShowForgetModal] = useState(false);
  const fcmToken = useSelector((state) => state.authReducer.fcmToken);

  // Socket Ref
  const socket = useRef(null);

  // Notifications State
  const [notifications, setNotifications] = useState([]);
  const [notiUnReadCount, setNotiUnReadCount] = useState(0);
  const [chatUnreadCount, setChatUnreadCount] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 100) {
        setScrollY(true);
      } else {
        setScrollY(false);
      }
    });
    isMobileViewHook(setIsMobile);
  }, []);

  // get all notifications
  const getAllNotifications = async () => {
    const url = BaseURL("notification/all");
    const response = await Get(url, accessToken, false);
    if (response !== undefined) {
      setNotiUnReadCount(response?.data?.newNotifications);
      let newShape = [];
      response?.data?.data?.length > 0 &&
        response?.data?.data?.forEach((e) =>
          newShape.push({
            id: e?._id,
            message: e?.message,
            receivedTime: moment(e?.createdAt).fromNow(),
            isRead: e?.isSeen,
            image:
              e?.status == "success"
                ? success
                : e?.status == "error"
                ? error
                : warning,
          })
        );
      setNotifications(newShape);
    }
  };

  useEffect(() => {
    let count = 0;
    socket.current = io(apiUrl);
    if (user_type == "mechanic") {
      getAllNotifications();
      socket.current.on("msg", (msg, socketRoomId, roomList) => {
        roomList?.length > 0 &&
          roomList?.forEach(
            (e) =>
              (count =
                userData?.user_type == "customer"
                  ? count + e?.customerUnreadCount * 1
                  : count + e?.mechanicUnreadCount * 1)
          );
        setNotiUnReadCount(count);
      });
    }
  }, []);

  // mark all as read
  const markAllAsread = async (type) => {
    // setNotifications([]);
    const url = BaseURL(`seen-notifications`);
    const response = await Get(url, accessToken, false);
    if (response !== undefined) {
      type !== undefined &&
        toast.success("Successfully marked all notifications as read.");
      await getAllNotifications();
    }
  };

  // For Logout
  const userLogOut = async () => {
    socket.current.emit("logout", userData?._id, fcmToken);
    dispatch(logout());
    history.push("/");
  };

  return (
    <div>
      {isMobile ? (
        <MobileHeader
          bgTransparent={bgTransparent}
          containerClass={containerClass}
          scrollY={scrollY}
          setModalOpen={setIsShowLoginModal}
          markAllAsread={markAllAsread}
          notificationsUnRead={notiUnReadCount}
          chatUnreadCount={chatUnreadCount}
          logout={userLogOut}
        />
      ) : (
        <DesktopHeader
          bgTransparent={bgTransparent}
          containerClass={containerClass}
          scrollY={scrollY}
          setModalOpen={setIsShowLoginModal}
          markAllAsread={markAllAsread}
          notificationsUnRead={notiUnReadCount}
          chatUnreadCount={chatUnreadCount}
          notificationsData={notifications}
          isLogoBlack={isLogoBlack}
          logout={userLogOut}
        />
      )}
      {isShowLoginModal && (
        <LoginOrSignUpModal
          show={isShowLoginModal}
          setShow={setIsShowLoginModal}
          onForgetPassword={() => {
            setIsShowForgetModal(true);
            setIsShowLoginModal(false);
          }}
        />
      )}
      {isShowForgetnModal && (
        <ForgetPassword
          show={isShowForgetnModal}
          setShow={setIsShowForgetModal}
        />
      )}
    </div>
  );
};

export default Header;
