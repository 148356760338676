import React, { useState, useEffect } from "react";
import classes from "./changePassword.module.css";
import Header from "./../../stories/Header/index";
import Footer from "../../stories/Footer";
import Button from "./../../stories/Button/index";
import Input from "./../../stories/Input/index";
import { FaLock } from "react-icons/fa";
import { apiHeader, BaseURL } from "../../Config/apiUrl";
import { Patch } from "../../Axios/AxiosFunctions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { logout } from "../../store/Actions/authAction";
import { Container } from "react-bootstrap";

function ChangePassword() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { accessToken } = useSelector((state) => state.authReducer);

  const resetInputFields = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  const submitPasswordRequest = async () => {
    let params = {
      passwordCurrent: currentPassword,
      password: newPassword,
      passwordConfirm: confirmPassword,
    };
    let url = BaseURL("updateMyPassword");
    for (let key in params) {
      if (params[key] == "") {
        return toast.error(`Insufficient Data, All Fields are required.`)
      }
    }

    if (newPassword !== confirmPassword) {
      return toast.info(`Password Mismatch.`);
    } else {
      setLoading(true);
      let responseData = await Patch(url, params, apiHeader(accessToken));
      if (responseData !== undefined) {
        console.log(responseData?.data?.data);
        resetInputFields();
        toast.success("Your Password has been successfully Updated.");
        dispatch(logout())
        history.push('/')

        setLoading(false);
      } else {
        toast.error(`${responseData?.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false);

      }
    }

  };

  return (
    <div>
      {/* <Header /> */}
      <div className={classes.header}>
        <Header containerClass={`${[classes.container].join(" ")}`} bgTransparent={false} isLogoBlack={true} />
      </div>

      {/* Input Section */}
      <section className={classes.middleSection}>
        <Container className={`${classes.container}`}>
          <div className={`${classes.inp_div}`}>
            <Input
              type={"password"}
              placeholder={"Current Password"}
              setter={setCurrentPassword}
              value={currentPassword}
              leftIcon={<FaLock size={20} color={"#E60000"} />}
            />
          </div>
          <div className={`${classes.inp_div}`}>
            <Input
              type={"password"}
              placeholder={"New Password"}
              setter={setNewPassword}
              value={newPassword}
              leftIcon={<FaLock size={20} color={"#E60000"} />}
            />
          </div>
          <div className={`${classes.inp_div}`}>
            <Input
              type={"password"}
              placeholder={"Confirm Password"}
              setter={setConfirmPassword}
              value={confirmPassword}
              leftIcon={<FaLock size={20} color={"#E60000"} />}
            />
          </div>
          <Button
            label={loading ? "Saving..." : "Save"}
            className={classes.savebtn}
            onClick={() => submitPasswordRequest()}
            disabled={loading}
          />
        </Container>
      </section>
      {/* Input Section */}

      {/* Footer section */}

      <section className={`${classes.Footer_Section}`}>
        <Footer containerClass={classes.container} />
      </section>

      {/* Footer section */}
    </div>
  );
}

export default ChangePassword;
