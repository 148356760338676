import React, { useState, useEffect, useRef } from "react";
import classes from "./recentBokking.module.css";
import Header from "./../../stories/Header/index";
import Footer from "../../stories/Footer";
import Input from "./../../stories/Input/index";
import { FiSearch } from "react-icons/fi";
import DropDown from "../../stories/DropDown";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { BaseURL } from "../../Config/apiUrl";
import { Get } from "../../Axios/AxiosFunctions";
import NoData from "../../stories/NoData/NoData";
import BookingCard from "../../stories/BookingCard";
import { PaginationComponent } from "../../stories/Pagination/Pagination";
import { isMobileViewHook } from '../../CustomHooks/isMobileViewHook'

const RenderSkeletons = () => {
  const [isMobile, setIsMobile] = useState(false)
  let decide = isMobile ? [1, 2, 4, 2] : [1, 2, 3]
  useEffect(() => { isMobileViewHook(setIsMobile, 992) }, [])

  return decide.map((item, i) => {
    return (
      <Col lg={4} md={6} sm={12}>
        <div className="SkeletonContainer" style={{ borderRadius: '18px', width: '100%' }}></div>
      </Col>
    );
  });
};


function RecentBooking() {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState({ value: "pending", label: "Pending" });
  const limit = 6
  const textInputRef = useRef(null)

  const [bookings, setBookings] = useState([]);
  const [maxLength, setMaxLength] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  const accessToken = useSelector((state) => state?.authReducer?.accessToken);
  const userData = useSelector((state) => state?.authReducer);

  const getBookings = async (pgNumber, queryText, statusText) => {
    const recentBookingsURL = BaseURL(
      `bookings/${userData?.user?._id}?limit=${limit}&page=${pgNumber}&q=${queryText}&status=${statusText}`
    );
    setIsLoading(true);
    const responseData = await Get(recentBookingsURL, accessToken);
    if (responseData !== undefined) {
      console.log("Recent Bookings Data: ", responseData?.data);
      setMaxLength(responseData?.data?.results);
      setBookings(responseData?.data?.data);
      setIsLoading(false);

    } else {
      console.log("error");
      setIsLoading(false);

    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  useEffect(() => {
    getBookings(pageNumber, search, status?.value);

  }, [pageNumber, search, status]);

  const options = [
    { value: "pending", label: "Pending" },
    { value: "current", label: "Current" },
    { value: "past", label: "Past" },
    { value: "completed", label: "Completed" },
  ];

  return (
    <div>
      {/* <Header /> */}
      <div className={classes.header}>
        <Header containerClass={`${[classes.container].join(" ")}`} bgTransparent={false} isLogoBlack={true} />
      </div>

      <div className={`${classes.main_section}`}>
        <Container className={classes.container}>
          <Row >
            <Col md={9} sm={8} xs={12}>
              <Input
                placeholder={"Search by name"}
                setter={setSearch}
                value={search}
                inputRef={textInputRef}
                inputContainer={classes.search_inp}
                leftIcon={<FiSearch size={24} />}
                onKeyDown={(e) => {
                  if (e.target.value?.length > 0) {
                    console.log("Value", e.target.value)
                    if (e.key === "Enter" || e.keyCode === 13) {
                      setPageNumber(1);
                      textInputRef.current.blur();
                    }
                  }
                }}

              />
            </Col>

            <Col md={3} sm={4} xs={12} className={classes.mtSM10}>
              <DropDown
                options={options}
                placeholder={"status"}
                setter={(e) => {
                  setPageNumber(1)
                  setStatus(e)
                }}
                value={status}
              />
            </Col>

          </Row>
          <div>
            <h2 className={`${classes.bookingText}`}>{`${status?.value} Booking`}</h2>
            {isLoading ?
              <Row className={'mt-4'}>
                <RenderSkeletons />
              </Row>
              : bookings?.length === 0 ? (
                <>
                  <NoData text="No Bookings Found" className={classes.noDataContainer} />
                </>
              ) : (
                <Row className={`${classes.machanicCard_row}`}>
                  {bookings?.map((item, index) => {
                    return (
                      <Col lg={4} md={6} sm={12} key={index}>
                        <BookingCard item={item} status={status?.value} />
                      </Col>
                    );
                  })}
                </Row>
              )}
          </div>
          {/* For Pagination */}
          {bookings?.length > 0 && <PaginationComponent
            currentPage={pageNumber}
            setCurrentPage={setPageNumber}
            totalPages={Math.ceil(maxLength / limit)}
          />}
        </Container>
      </div>

      {/* Footer section */}

      <section className={`${classes.Footer_Section}`}>
        <Footer containerClass={classes.container} />
      </section>

      {/* Footer section */}
    </div >
  );
}

export default RecentBooking;
