import React, { useState, useEffect } from "react";
import classes from "./aboutPage.module.css";
import HeroSection from "./../../stories/HeroSection";
import { Container, Row, Col } from "react-bootstrap";
import BackgroundImageWithInfo from "../../stories/BackgroundImageWithInfo";
import Footer from "./../../stories/Footer";
import { BaseURL, imageUrl } from "../../Config/apiUrl";
import Aos from "aos";
import "aos/dist/aos.css";
import MechanicTypeCard from "./../../stories/MechanicTypeCard/index";
import Header from "./../../stories/Header/index";
import { Get } from "../../Axios/AxiosFunctions";
import { useHistory } from "react-router-dom";
function AboutPage() {
  const history = useHistory()
  const [blurLoader, setBlurLoader] = useState(false);
  const [dataList, setDataList] = useState([]);

  const cmsDataApiUrl = BaseURL(
    "pages?pages=about_us&goals=false&service=false&aboutusItem=true&faq=false&all=false"
  );

  const getAboutData = async () => {
    setBlurLoader(true);
    const responseData = await Get(cmsDataApiUrl);
    if (responseData !== undefined) {
      setDataList(responseData.data.data);
    } else {
      setDataList([]);
    }
    setBlurLoader(false);
  };

  useEffect(() => {
    getAboutData();
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.page}>
      {/* <Header /> */}
      <div className={[classes.header, blurLoader && "pageBlur"].join(" ")}>
        <Header
          bgTransparent={true}
          containerClass={`${[classes.container].join(" ")}`}
        />
      </div>
      <HeroSection
        BgImg={imageUrl(dataList?.pages?.cover_image)}
        alt="img"
        title={dataList?.pages?.heading}
        description={dataList?.pages?.detail_description}
        sectionClass={classes.aboutSection}
      ></HeroSection>

      {/* About us section */}

      <section className={`${classes.aboutUs_Section}`}>
        <Container className={`${classes.container}`}>
          <Row>
            <Col md={12} data-aos="fade-up">
              <h1 className={classes.heading2}>
                ABOUT US<span>ABOUT US</span>
              </h1>
            </Col>
            <Col md={12}>
              <Row className={`${classes.aboutUs_row}`}>
                <Col md={6} sm={12}>
                  <div
                    data-aos="fade-left"
                    className={`${classes.aboutImg_sec}`}
                  >
                    <img
                      src={`${imageUrl(dataList?.pages?.sec1Image)}`}
                      className="img-fluid"
                      alt=""
                    ></img>
                  </div>
                </Col>
                <Col md={6} sm={12}>
                  <div
                    data-aos="fade-right"
                    className={`${classes.aboutSection_para}`}
                  >
                    <h3>{dataList?.pages?.sec1Heading}</h3>
                    <p>{dataList?.pages?.sec1Description}</p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* About us section */}

      {/* WE OFFER THESE SERVICES section */}

      <section className={`${classes.TypesMechanic_Section}`}>
        <Container className={`${classes.container}`}>
          <Row>
            <Col md={12}>
              <div data-aos="flip-left">
                <h1 className={`${classes.lower_text_heading}`}>
                  Types of Mobile Mechanic
                </h1>
              </div>
            </Col>
            <Col md={12}>
              <Row className={`${classes.MechanicTypeCard_row}`}>
                {dataList?.aboutusItem?.map((item, i) => {
                  return (
                    <Col lg={4} md={6} sm={12} key={i}>
                      <MechanicTypeCard data={item} />
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* WE OFFER THESE SERVICES section */}

      <section className={`${classes.MechanicHub_Section}`}>
        <Container className={`${classes.container}`}>
          <Row>
            <Col md={12}>
              <div>
                <h1
                  data-aos="flip-left"
                  className={`${classes.lower_text_heading}`}
                >
                  {dataList?.pages?.sec2Heading}
                </h1>
                <p data-aos="fade-up">{dataList?.pages?.sec2Description}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${classes.WantToJoin_Section}`}>
        <Container className={`${classes.container}`}>
          <Row>
            <Col lg={12}>
              <BackgroundImageWithInfo
                item={{
                  title: dataList?.pages?.sec3Heading,
                  description: dataList?.pages?.sec3Description,
                  image: imageUrl(dataList?.pages?.sec3Image),
                }}
                btn={true}
                bgimgClass={classes.bgimgnetwork}
                btnClass={classes.join_btn}
                btnClick={() => history.push('/join-the-network')}
              />
            </Col>
          </Row>
        </Container>
      </section>

      {/* Footer section */}

      <section className={`${classes.Footer_Section}`}>
        <Footer containerClass={classes.container} />
      </section>

      {/* Footer section */}
    </div>
  );
}

export default AboutPage;
