import React from "react";
import classes from "./ConfirmSubmissionModal.module.css";
import ModalSkeleton from "../ModalSkeleton";
import Button from "../../../stories/Button";
import { JOINED } from "../../../constant/imagePath";

const ConfirmSubmissionModal = ({
  show,
  setShow,
  title,
  subtitle,
  btnClick,
  buttonLabel,
}) => {
  return (
    <ModalSkeleton
      show={show}
      setShow={setShow}
      borderRadius={"30px"}
      width={"600px"}
    >
      <div className={[classes.mainModalDiv]}>
        <div className={[classes.imgDiv]}>
          <img src={JOINED} alt="..." />
        </div>
        <h2 className={[classes.title]}>{title}</h2>
        <h4 className={[classes.subTitle]}>{subtitle}</h4>

        <div className={[classes.ConfirmBtnDiv]}>
          <Button
            label={buttonLabel}
            onClick={btnClick}
            className={[classes.ConfirmBtn]}
          />
        </div>
      </div>
    </ModalSkeleton>
  );
};

export default ConfirmSubmissionModal;
