import React, { useState, useEffect } from "react";
import classes from "./JoinTheNetwork.module.css";
import HeroSection from "./../../stories/HeroSection";
import bgImage from "../../assets/images/Join_Network_banner.png";
import { Container, Row, Col } from "react-bootstrap";
import { apiHeader, apiUrl, BaseURL, imageUrl } from "../../Config/apiUrl";
import Aos from "aos";
import "aos/dist/aos.css";
import Header from "./../../stories/Header/index";
import Footer from "../../stories/Footer";
import PackagesCard from "../../stories/PackageCard";
import { Get, Post } from "../../Axios/AxiosFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  setRegistrationType,
  updateUserData,
} from "../../store/Actions/authAction";
import { useHistory } from "react-router-dom";
import PaymentModal from "../../Components/Modals/PaymentModal";
import { toast } from "react-toastify";
import NoData from "../../stories/NoData/NoData";

function JoinTheNetwork() {
  const accessToken = useSelector((state) => state?.authReducer?.accessToken);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isFetchingPlans, setIsFetchingPlans] = useState(false);
  const [blurLoader, setBlurLoader] = useState(false);
  const [packages, setPackages] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [buyPackageLoading, setBuyPackageLoading] = useState(false);

  const getPackages = async () => {
    const packagesURL = BaseURL("get-plans");
    setIsFetchingPlans(true);
    const responseData = await Get(packagesURL);
    if (responseData !== undefined) {
      setPackages(responseData?.data?.data);
    } else {
    }
    setIsFetchingPlans(false);
  };

  // ==== getData ===
  const getData = async () => {
    const ApiURL = BaseURL(
      `pages?pages=Join_Network&goals=${false}&service=${false}&aboutusItem=${false}&faq=${false}&all=${false}`
    );
    setBlurLoader(true);
    const responseData = await Get(ApiURL);
    if (responseData !== undefined) {
      setDataList(responseData.data.data);
    } else {
      setDataList([]);
    }
    setBlurLoader(false);
  };

  useEffect(() => {
    getData();
    getPackages();
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);

  const onSignupNow = async (packageCard) => {
    if (packageCard?.amount == 0) {
      await dispatch(setRegistrationType(null, null));
    } else {
      await dispatch(setRegistrationType("paid", packageCard));
    }
    history.push(`/registration-form`);
  };

  const onBuyNow = (packageCard) => {
    setSelectedPackage(packageCard);
    setShowPaymentModal(true);
  };

  const buyPackage = async (paymentMethod) => {
    const url = BaseURL("mechanic/create-subscription");

    const params =
      selectedPackage?.product?.metadata?.premimum !== undefined
        ? {
          paymentMethodId: paymentMethod?.id,
          planId: selectedPackage?.id,
          premimum: true,
        }
        : {
          planId: selectedPackage?.id,
          paymentMethodId: paymentMethod?.id,
        };
    setBuyPackageLoading(true);
    const response = await Post(url, params, apiHeader(accessToken));
    if (response !== undefined) {
      toast.success(
        `🙌 ${selectedPackage?.product?.name} plan was successfully purchased.`);
      dispatch(updateUserData(response?.data?.data));
      setShowPaymentModal(false);
      setSelectedPackage(null);
    }
    setBuyPackageLoading(false);
  };

  return (
    <div className={[blurLoader && "pageBlur"]}>
      {/* <Header /> */}
      <div className={classes.header}>
        <Header
          bgTransparent={true}
          containerClass={`${[classes.container].join(" ")}`}
        />
      </div>
      <HeroSection
        BgImg={imageUrl(dataList?.pages?.cover_image)}
        alt="img"
        title={dataList?.pages?.heading}
        description={dataList?.pages?.detail_description}
        sectionClass={classes.joinSection}
      ></HeroSection>

      {/* Join Network section */}

      <section className={`${classes.JoinNetwork_Section}`}>
        <Container className={`${classes.container}`}>
          <Row>
            <Col md={12}>
              <div
                data-aos="zoom-in-down"
                className={`${classes.JoinNetwork_Section_div}`}
              >
                <h5 className={`${classes.upper_text_heading}`}>Join The</h5>
                <h1 className={`${classes.lower_text_heading}`}>
                  NETWORK <span>NETWORK</span>
                </h1>
              </div>
            </Col>
            <Col md={12}>
              <Row className={`${classes.JoinNetwork_Section_row}`}>
                {isFetchingPlans ? (
                  <>
                    {[1, 2, 3, 4, 5, 6].map((item, i) => {
                      return (
                        <Col lg={4} md={6} sm={12}>
                          <div className="SkeletonContainer"></div>
                        </Col>
                      );
                    })}
                  </>
                ) : packages?.length > 0 ? (
                  packages?.map((item, index) => {
                    return (
                      <Col lg={4} md={6} sm={12} key={index}>
                        <PackagesCard
                          item={item}
                          onSignupNow={onSignupNow}
                          onBuyNow={onBuyNow}
                        />
                      </Col>
                    );
                  })
                ) : (
                  <>
                    <NoData text={"No Packages Found"} className={classes.noData} />
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* About us section */}

      {/* Footer section */}

      <section className={`${classes.Footer_Section}`}>
        <Footer containerClass={classes.container} />
      </section>

      {/* Footer section */}

      <PaymentModal
        show={showPaymentModal}
        setShow={setShowPaymentModal}
        buyPackage={buyPackage}
        buyPackageLoading={buyPackageLoading}
      />
    </div>
  );
}

export default JoinTheNetwork;
