import React, { useEffect, useRef, useState } from "react";
import { isMobileViewHook } from "../../CustomHooks/isMobileViewHook";
import ChatDesktop from "./ChatDesktop";
import ChatMobile from "./ChatMobile";
import { apiUrl, BaseURL, imageUrl } from "../../Config/apiUrl";
import { useSelector } from "react-redux";
import { Get } from "../../Axios/AxiosFunctions";
import moment from "moment";
import Header from "../../stories/Header";
import Footer from "../../stories/Footer";
import classes from "./ChatDesktop.module.css";
import io from "socket.io-client";

const Chat = () => {
  const socket = useRef(null);

  const accessToken = useSelector((state) => state?.authReducer?.accessToken);
  const userData = useSelector((state) => state?.authReducer?.user);
  const userType = useSelector((state) => state?.authReducer?.user_type);

  const [loadPrevMessages, setLoadPrevMessages] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [roomsData, setRoomsData] = useState([]);
  const [messages, setMessages] = useState([]);

  // For Loading
  const [isLoadingRooms, setIsLoadingRooms] = useState(false)
  const [isLoadingChats, setIsLoadingChats] = useState(false)

  const getRoomsData = async () => {
    const url = BaseURL(`chat/rooms?id=${userData?._id}`);
    setIsLoadingRooms(true);
    const response = await Get(url, accessToken);

    if (response !== undefined) {
      setRoomsData(response?.data?.data);
      setIsLoadingRooms(false);
    } else {
      setIsLoadingRooms(false);
    }
  };

  const sendMessage = async (msg) => {
    const msgData = {
      _id: Math.floor(Math.random(100000) * 100000),
      text: msg,
      createdAt: moment().format(),
      user: {
        _id: userData?.UserId?._id,
        avatar: userData?.photo,
        name: userData?.userName,
      },
    };
    setMessages((prev) => [msgData, ...prev]);
    console.log(userType === "customer"
      ? selectedRoom?.mechanic
      : selectedRoom?.customer,
      userData?._id
    )

    socket.current.emit(
      "msg",
      msgData,
      userType === "customer"
        ? selectedRoom?.mechanic?._id
        : selectedRoom?.customer?._id,
      selectedRoom?.id,
      userType
    );
  };


  useEffect(() => {
    isMobileViewHook(setIsMobile, 992);
    getRoomsData();
  }, []);

  useEffect(() => {
    socket.current = io(apiUrl);
    socket.current.emit("join", userData?._id, userType);

    return () => {
      socket.current.emit("disconnected", userData?._id, userType);
    };
  }, []);

  const getPreMessages = async (oldMessage, skip, limit, initial = false) => {
    const url = BaseURL(
      `chat/room/single-chat/${selectedRoom?.id}?skip=${skip}&limit=${limit}`
    );
    setLoadPrevMessages(true);
    initial && setIsLoadingChats(true)
    const response = await Get(url, accessToken);

    if (response !== undefined) {
      setMessages([...oldMessage, ...response?.data?.data]);
    }
    initial && setIsLoadingChats(false)
    setLoadPrevMessages(false);
  };

  useEffect(() => {
    if (selectedRoom !== null) {
      getPreMessages([], 0, 30, true);
      socket.current.emit('mark-as-read', selectedRoom?.id, userType)
      socket.current.emit(
        "chatJoin",
        userData?._id,
        selectedRoom?.id,
        userType
      );

      socket.current.on("msg", (msg, socketRoomId) => {
        console.log("msg", { msg })
        if (selectedRoom?.id === socketRoomId && msg.user?._id !== userData?._id) {
          setMessages((prev) => [msg, ...prev]);
          socket.current.emit('mark-as-read', selectedRoom?.id, userType)
        }
      });
    }
  }, [selectedRoom]);

  return (
    <>
      <div className={classes.header}>
        <Header containerClass={`${[classes.custom_Container].join(" ")}`} bgTransparent={false} isLogoBlack={true} />
      </div>
      {isMobile ? (
        <ChatMobile
          roomsData={roomsData}
          selectedRoom={selectedRoom}
          setSelectedRoom={setSelectedRoom}
          messages={messages}
          sendMessage={sendMessage}
          getPrevMessages={getPreMessages}
          loadMoreMessages={loadPrevMessages}
          isLoadingChats={isLoadingChats}
          isLoadingRooms={isLoadingRooms}
        />
      ) : (
        <ChatDesktop
          roomsData={roomsData}
          selectedRoom={selectedRoom}
          setSelectedRoom={setSelectedRoom}
          messages={messages}
          sendMessage={sendMessage}
          getPrevMessages={getPreMessages}
          loadMoreMessages={loadPrevMessages}
          isLoadingChats={isLoadingChats}
          isLoadingRooms={isLoadingRooms}

        />
      )}
      <Footer />
    </>
  );
};

export default Chat;
