import React, { useState, useEffect, useRef } from "react";
import classes from "./MechanicCoupons.module.css";
import Header from "./../../stories/Header/index";
import Footer from "../../stories/Footer";
import Button from "./../../stories/Button/index";
import Input from "./../../stories/Input/index";
import { FiMail } from "react-icons/fi";
import StarRatings from "react-star-ratings";
import { FaCar } from "react-icons/fa";
import { MdLocationPin, MdDescription } from "react-icons/md";
import { ProfileWithEditButton } from "./../../stories/ProfileWithEditButton/index";
import VoucherCard from "../../stories/VoucherCard";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { BaseURL, imageUrl } from "../../Config/apiUrl";
import { useHistory } from "react-router-dom";
import { Get } from "../../Axios/AxiosFunctions";

function MechanicCoupons() {
  const history = useHistory();

  const userData = useSelector((state) => state.authReducer.user);
  const userType = useSelector((state) => state.authReducer.user_type);
  const accessToken = useSelector((state) => state.authReducer.accessToken);

  const [isFetchingCoupons, setIsFetchingCoupons] = useState(false);
  const [coupons, setCoupons] = useState([]);

  const getCoupons = async () => {
    const couponsURL = BaseURL("voucher/all");
    setIsFetchingCoupons(true);
    const responseData = await Get(couponsURL, accessToken);
    if (responseData !== undefined) {
      setCoupons(responseData?.data?.data);
    } else {
      console.log("error");
    }
    setIsFetchingCoupons(false);
  };

  useEffect(() => {
    getCoupons();
  }, []);

  return (
    <div>
      {/* <Header /> */}
      <div className={classes.header}>
        <Header containerClass={`${[classes.container].join(" ")}`} bgTransparent={false} isLogoBlack={true} />
      </div>

      {/* Input Section */}
      <div className={`${classes.InputSection_div}`}>
        <Container className={classes.container}>
          <Row>
            <Col span={12}>
              {coupons?.map((item, index) => (
                <VoucherCard key={index} data={item} />
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default MechanicCoupons;
