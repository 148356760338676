export const apiUrl = "https://mobile-mechanic-backend-demo-1d3aee65c9ce.herokuapp.com"; //main
// export const apiUrl = "https://8713-39-57-128-204.sa.ngrok.io";
// export const apiUrl = 'https://mobile-mechanic-hub-backend.herokuapp.com';
export const imageUrl = (url) => {
  return `${apiUrl}/api/images/${url}`;
};

export const pdfUrl = `${apiUrl}/api/pdf`;
export const voucherImageUrl = `${apiUrl}/img/vouchers/`;
export const firebaseVapidObject = {
  vapidKey:
    "BE5l0vTuK_JkSKcurrquqVtASxRtvpG8bJhVZVu1qdO99ruNXRi-ctdNEzNXuuahTPsYlIJrnH57uLNq-WoSuRU",
};

export const stripe_public_key =
  "pk_live_51JKyGOHOVyleiVHjRlo6C5U84qnearI7JaYn5hWwYbemmH2X2qSITNCoYfsaK8vgBdAzQiSdLox88MjKhBDX1QzK006q88diSu"; //live key

// export const stripe_public_key =
//   "pk_test_51JKyGOHOVyleiVHjaA244Cs5sUI3gGJZ9pm6klSDkediJYII64shOj68zOuTEZiQpBtiE48u3TpEvceLHMX4ZXJ300xvn4RpOR";

export const BaseURL = (link) => {
  return `${apiUrl}/api/${link}`;
};

// test

export const apiHeader = (token, isFormData) => {
  if (token && !isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  }
  if (token && isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
  }
  if (!token && !isFormData) {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  if (!token && isFormData) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }
};

export const maxApiDataLength = 30;

export const createFormData = (data) => {
  const formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  return formData;
};
