import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Get } from "../../Axios/AxiosFunctions";
import { BaseURL } from "../../Config/apiUrl";
import Button from "../../stories/Button";
import CurrentBooking from "../../stories/CurrentBookingCard";
import Footer from "../../stories/Footer";
import Header from "../../stories/Header";
import { ProfileWithEditButton } from "../../stories/ProfileWithEditButton";
import classes from "./ViewBooking.module.css";

import PLANT_COUNTRY from "../../assets/images/plantCountry.png";
import MANUFACT from "../../assets/images/productivity.png";
import MODEL_NAME from "../../assets/images/specification (2).png";
import V_TYPE from "../../assets/images/steering-wheel.png";
import MODEL_YEAR from "../../assets/images/factory.png";
import ENGINE_SIZE_CC from "../../assets/images/engineSizeCC.png";
import ENGINE_SIZE_L from "../../assets/images/engineSizeL.png";
import ENGINE_SIZE_CL from "../../assets/images/engineSizeCl.png";
import VIN_NO from "../../assets/images/car-no.png";
import { MdDescription, MdEmail, MdLocationPin } from "react-icons/md";
import { FaCar } from "react-icons/fa";

function ViewBooking() {
  const pageId = useParams()?.id;
  const { accessToken, user_type } = useSelector((state) => state.authReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [currentBooking, setCurrentBooking] = useState({});

  async function getBooking() {
    setIsLoading(true);
    const url = BaseURL(`booking/${pageId}`);
    const response = await Get(url, accessToken);
    if (response !== undefined) {
      console.log("aa", response?.data?.data);
      setCurrentBooking(response?.data?.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    getBooking();
  }, []);
  return (
    <div>
      <style>
        {`
        .${classes.profileImageContainer} {
          bottom:${user_type !== "mechanic" ? "-40px" : "0px"}
        }
        `}
      </style>
      {/* Header  */}
      <div className={classes.header}>
        <Header
          containerClass={`${[classes.container].join(" ")}`}
          isLogoBlack={true}
          bgTransparent={false}
        />
      </div>

      {/* Body User Detail Section Start */}
      <div className={`${classes.bodyContainer} `}>
        <div
          className={`${classes.userImagesContainer}`}
          style={{
            height: user_type !== "mechanic" ? 400 : 120,
          }}
        >
          {user_type !== "mechanic" && (
            <ProfileWithEditButton
              updateImage={currentBooking?.mechanicDetailId?.cover_image}
              className={classes.coverImage}
              onErrorImage={true}
              classImg={classes.coverImageTagClass}
              edit={false}
            />
          )}
          <div className={`${classes.profileImageContainer}`}>
            <ProfileWithEditButton
              updateImage={
                user_type === "mechanic"
                  ? currentBooking?.customerDetailId?.photo
                  : currentBooking?.mechanicDetailId?.photo
              }
              edit={false}
            />
          </div>
        </div>
        <div
          className={`${classes.userDetailContainer}`}
          style={{
            marginTop: user_type !== "mechanic" ? 50 : 10,
          }}
        >
          <div className={classes.centerItems}>
            <p className={`${classes.userNameText}`}>
              {user_type == "mechanic"
                ? currentBooking?.name
                : currentBooking?.mechanicDetailId?.userName}
            </p>
          </div>

          <Row className={`g-0 ${classes.personalDetailRow}`}>
            <Col lg={4} md={4} sm={12}>
              <MdEmail size={24} color={"#E60000"} />
              <span>
                {user_type === "mechanic"
                  ? currentBooking?.email
                  : currentBooking?.mechanicDetailId?.UserId?.email}
              </span>
            </Col>

            <Col lg={8} md={8} sm={12}>
              <MdLocationPin size={24} color={"#E60000"} />
              <span>
                {user_type == "mechanic"
                  ? currentBooking?.serviceAddress
                  : currentBooking?.mechanicDetailId?.address}
              </span>
            </Col>
            <Col
              lg={4}
              md={6}
              sm={12}
              className={`${classes.infoSubContainer}`}
            >
              <img src={PLANT_COUNTRY} />
              <span>{currentBooking?.PlantCountry}</span>
            </Col>
            <Col
              lg={4}
              md={6}
              sm={12}
              className={`${classes.infoSubContainer}`}
            >
              <img src={MANUFACT} />
              <span>{currentBooking?.manufactureName}</span>
            </Col>
            <Col
              lg={4}
              md={6}
              sm={12}
              className={`${classes.infoSubContainer}`}
            >
              <FaCar size={24} color={"#E60000"} />
              <span>{`${currentBooking?.vehicleName}`}</span>
            </Col>

            {currentBooking?.ModelName !== undefined &&
              currentBooking?.ModelName !== "" &&
              currentBooking?.ModelName !== null && (
                <Col
                  lg={4}
                  md={6}
                  sm={12}
                  className={`${classes.infoSubContainer}`}
                >
                  <img src={MODEL_NAME} style={{ filter: "unset" }} />
                  <span>{currentBooking?.ModelName}</span>
                </Col>
              )}
            <Col
              lg={4}
              md={6}
              sm={12}
              className={`${classes.infoSubContainer}`}
            >
              <img src={V_TYPE} />
              <span>{currentBooking?.VehicleType}</span>
            </Col>

            {currentBooking?.ModelYear !== undefined &&
              currentBooking?.ModelYear !== "" &&
              currentBooking?.ModelYear !== null && (
                <Col
                  lg={4}
                  md={6}
                  sm={12}
                  className={`${classes.infoSubContainer}`}
                >
                  <img src={MODEL_YEAR} />
                  <span>{`Make Year -${currentBooking?.ModelYear}`}</span>
                </Col>
              )}

            {currentBooking?.engineSizeCC !== undefined &&
              currentBooking?.engineSizeCC !== "" &&
              currentBooking?.engineSizeCC !== null && (
                <Col
                  lg={4}
                  md={6}
                  sm={12}
                  className={`${classes.infoSubContainer}`}
                >
                  <img
                    src={ENGINE_SIZE_CC}
                    className={`${classes.plate_img}`}
                  />
                  <span>{currentBooking?.engineSizeCC}</span>
                </Col>
              )}

            {currentBooking?.engineSizeCC !== undefined &&
              currentBooking?.engineSizeCI !== "" &&
              currentBooking?.engineSizeCI !== null && (
                <Col
                  lg={4}
                  md={6}
                  sm={12}
                  className={`${classes.infoSubContainer}`}
                >
                  <img
                    src={ENGINE_SIZE_CL}
                    className={`${classes.plate_img}`}
                  />
                  <span>{currentBooking?.engineSizeCI}</span>
                </Col>
              )}

            {currentBooking?.engineSizeCC !== undefined &&
              currentBooking?.engineSizeL !== "" &&
              currentBooking?.engineSizeL !== null && (
                <Col
                  lg={4}
                  md={6}
                  sm={12}
                  className={`${classes.infoSubContainer}`}
                >
                  <img src={ENGINE_SIZE_L} className={`${classes.plate_img}`} />
                  <span>{currentBooking?.engineSizeL}</span>
                </Col>
              )}
            <Col
              lg={4}
              md={6}
              sm={12}
              className={`${classes.infoSubContainer}`}
            >
              <img src={VIN_NO} />
              <span>{currentBooking?.VIN}</span>
            </Col>
            <Col lg={12} sm={12}>
              <MdDescription size={24} color={"#E60000"} />
              <span>{currentBooking?.workDetail}</span>
            </Col>
          </Row>
        </div>
      </div>

      {/* Body User Detail Section End */}

      {/* Footer section */}

      <section className={`${classes.Footer_Section}`}>
        <Footer containerClass={classes.container} />
      </section>

      {/* Footer section */}
    </div>
  );
}

export default ViewBooking;
