import React, { useEffect, useState } from "react";
import { LogoBlack, LogoWhite } from "../../constant/imagePath";
import classes from "./MobileHeader.module.css";
import { IoBusiness } from "react-icons/io5";
import { Container } from "react-bootstrap";
import Drawer from "react-modern-drawer";
import { useHistory, useLocation } from "react-router-dom";
import { loginRequest } from "../../store/Actions/authAction";
import { useDispatch, useSelector } from "react-redux";

import { IoLogOut, IoLogIn, IoChatbubbles } from "react-icons/io5";
import { GoSignIn } from "react-icons/go";
import {
  FaHome,
  FaTags,
  FaUnlockAlt,
  FaCertificate,
  FaBook,
  FaQuestionCircle,
  FaUsers,
  FaBars,
} from "react-icons/fa";
import { MdNotifications } from "react-icons/md";
import { AiFillSetting } from "react-icons/ai";
import { BsFillPeopleFill, BsFillEnvelopeOpenFill } from "react-icons/bs";
import { RiBusWifiLine } from "react-icons/ri";
import { RiBook2Fill } from "react-icons/ri";
import { imageUrl } from "../../Config/apiUrl";

function MobileHeader({
  containerClass,
  scrollY,
  setModalOpen,
  notificationsUnRead,
  chatUnreadCount,
  bgTransparent,
  logout,
}) {
  const isLogin = useSelector((state) => state.authReducer.isLogin);
  const user = useSelector((state) => state.authReducer.user);
  const user_type = useSelector((state) => state.authReducer.user_type);

  const history = useHistory();
  const currentPage = useLocation()?.pathname;

  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const RenderListItem = ({ icon, text, customClass, path, onClick }) => {
    return (
      <div
        className={[classes.listItem, customClass && customClass].join(" ")}
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            history.push(path);
          }
        }}
      >
        {icon}
        <span className={classes.listItemText}>{text}</span>
      </div>
    );
  };

  return (
    <div
      className={`${[
        classes.header,
        !bgTransparent && classes.bgWhite,
        scrollY && classes.bgWhite,
      ].join(" ")}`}
    >
      <Container
        fluid
        className={`${[
          classes.container,
          containerClass && containerClass,
        ].join(" ")}`}
      >
        <div className={classes.logoAndBarsIcon}>
          <div className={classes.logo}>
            <img
              src={!bgTransparent ? LogoBlack : scrollY ? LogoBlack : LogoWhite}
            />
          </div>
          <FaBars className={classes.bars} onClick={() => setIsOpen(true)} />
        </div>
      </Container>

      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className={classes.drawer}
      >
        <div className={classes.drawerContainer}>
          <div
            className={classes.drawerUserSection}
            style={isLogin == false ? { height: "100px" } : {}}
          >
            {isLogin ? (
              <>
                <div
                  className={classes.userDetail}
                  onClick={() => history.push("/view-profile")}
                >
                  <img
                    src={`${imageUrl(user?.photo)}`}
                    className={classes.drawerUserImage}
                    alt="profile"
                  />
                  <span className={classes.drawerUserName}>
                    {user?.userName}
                  </span>
                  <span className={classes.drawerUserEmail}>
                    {user?.UserId?.email}
                  </span>
                </div>
              </>
            ) : (
              <>
                <img
                  src={LogoWhite}
                  className={classes.drawerLogo}
                  alt="logo"
                />
              </>
            )}
          </div>
          <div className={classes.drawerList}>
            {!isLogin ? (
              <>
                <RenderListItem
                  icon={<IoLogIn className={classes.icon} />}
                  text={"Login"}
                  onClick={() => {
                    setModalOpen(true);
                    setIsOpen(false);
                  }}
                  // customClass={currentPage == "/login" && classes.activeItem}
                  // path={"/login"}
                />
                <hr
                  style={{
                    width: "100%",
                    marginBottom: "0px",
                  }}
                />
              </>
            ) : (
              <>
                {user_type == "mechanic" && (
                  <RenderListItem
                    icon={
                      <span className={classes.iconBox}>
                        {notificationsUnRead > 0 && (
                          <span className={classes.count}>
                            {notificationsUnRead}
                          </span>
                        )}
                        <MdNotifications className={classes.icon} />
                      </span>
                    }
                    text={"Notifications"}
                    customClass={
                      currentPage == "/notifications" && classes.activeItem
                    }
                    path={"/notifications"}
                  />
                )}
                <RenderListItem
                  icon={
                    <span className={classes.iconBox}>
                      {chatUnreadCount > 0 && (
                        <span className={classes.count}>{chatUnreadCount}</span>
                      )}
                      <IoChatbubbles className={classes.icon} />
                    </span>
                  }
                  text={"Chat"}
                  customClass={currentPage == "/chat" && classes.activeItem}
                  path={"/chat"}
                />
                <hr
                  style={{
                    width: "100%",
                    marginBottom: "0px",
                  }}
                />

                <RenderListItem
                  icon={<FaBook className={classes.icon} />}
                  text={"Bookings"}
                  customClass={currentPage == "/bookings" && classes.activeItem}
                  path={"/bookings"}
                />

                {user_type === "mechanic" && (
                  <>
                    <RenderListItem
                      icon={<FaCertificate className={classes.icon} />}
                      text={"Certificates & Services"}
                      customClass={
                        currentPage == "/update-services-and-certificates" &&
                        classes.activeItem
                      }
                      path={"/update-services-and-certificates"}
                    />
                    <RenderListItem
                      icon={<FaTags className={classes.icon} />}
                      text={"Buy Packages"}
                      customClass={
                        currentPage == "/buy-packages" && classes.activeItem
                      }
                      path={"/buy-packages"}
                    />
                  </>
                )}
                {user_type === "mechanic" && user?.premium && (
                  <RenderListItem
                    icon={<BsFillEnvelopeOpenFill className={classes.icon} />}
                    text={"Vouchers"}
                    path={"/mechanic-coupons"}
                    customClass={
                      currentPage == "/mechanic-coupons" && classes.activeItem
                    }
                  />
                )}
                <RenderListItem
                  icon={<FaUnlockAlt className={classes.icon} />}
                  text={"Change Password"}
                  customClass={
                    currentPage == "/change-password" && classes.activeItem
                  }
                  path={"/change-password"}
                />
                <RenderListItem
                  icon={<FaQuestionCircle className={classes.icon} />}
                  text={"Support"}
                  customClass={currentPage == "/support" && classes.activeItem}
                  path={"/support"}
                />
                <RenderListItem
                  icon={<AiFillSetting className={classes.icon} />}
                  text={"Settings"}
                  customClass={currentPage == "/settings" && classes.activeItem}
                  path={"/settings"}
                />
                <RenderListItem
                  icon={<IoLogOut className={classes.icon} />}
                  text={"logout"}
                  path={"logout"}
                  onClick={logout}
                />
                <hr
                  style={{
                    width: "100%",
                    marginBottom: "0px",
                  }}
                />
              </>
            )}
            {/* If Login or Not */}
            <RenderListItem
              icon={<FaHome className={classes.icon} />}
              text={"Home"}
              customClass={currentPage == "/" && classes.activeItem}
              path={"/"}
            />
            <RenderListItem
              icon={<RiBook2Fill className={classes.icon} />}
              text={"About Us"}
              customClass={currentPage == "/about-us" && classes.activeItem}
              path={"/about-us"}
            />
            <RenderListItem
              icon={<BsFillPeopleFill className={classes.icon} />}
              text={"Contact Us"}
              customClass={currentPage == "/contact-us" && classes.activeItem}
              path={"/contact-us"}
            />
            <RenderListItem
              icon={<FaUsers className={classes.icon} />}
              text={"Consult"}
              customClass={currentPage == "/consult" && classes.activeItem}
              path={"/consult"}
            />
            {user_type === "customer" && (
              <RenderListItem
                icon={<FaUsers className={classes.icon} />}
                text={"Available Mechanics"}
                customClass={
                  currentPage == "/available-mechanics" && classes.activeItem
                }
                path={"/available-mechanics"}
              />
            )}

            <RenderListItem
              icon={<FaQuestionCircle className={classes.icon} />}
              text={"FAQ"}
              customClass={currentPage == "/faq" && classes.activeItem}
              path={"/faq"}
            />
            <RenderListItem
              icon={<IoBusiness className={classes.icon} />}
              text={"Gallery"}
              customClass={currentPage == "/gallery" && classes.activeItem}
              path={"/gallery"}
            />
            {!isLogin && (
              <RenderListItem
                icon={<RiBusWifiLine className={classes.icon} />}
                text={"Join The Network"}
                customClass={
                  currentPage == "/join-the-network" && classes.activeItem
                }
                path={"/join-the-network"}
              />
            )}

            {/* End If Login or Not */}
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default MobileHeader;
