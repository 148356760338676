import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./BackgroundImageWithInfo.module.css";
import Button from "../../stories/Button";

const BackgroundImageWithInfo = ({
  item,
  sectionClass,
  btnText = "Join The Network",
  btnClass,
  btnClick,
  btn = true,
  bgimgClass,
  containerClass,
}) => {
  return (
    <section
      className={`${[sectionClass && sectionClass, classes.section].join(" ")}`}
    >
      {/* <div className="container custom_Container"> */}
      <Container className={containerClass && containerClass}>
        <Row
          className={`${classes.heroImg} ${bgimgClass && bgimgClass}`}
          style={{
            // backgroundImage: `url(${imageUrl(item?.image)})`,
            backgroundImage: `url(${item?.image})`,
          }}
        >
          <Col md={6} data-aos="fade-right" className={classes.content}>
            <h2 className={classes.title}>{item?.title}</h2>
            <p className={classes.description}>{item?.description}</p>
            {btn && (
              <Button
                onClick={btnClick}
                className={`${[classes.btnClass, btnClass && btnClass].join(
                  " "
                )}`}
              >
                {btnText}
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BackgroundImageWithInfo;
