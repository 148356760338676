import React, { useState, useEffect } from "react";
import classes from "./MechanicPackages.module.css";
import { Container, Row, Col } from "react-bootstrap";
import { apiHeader, BaseURL, imageUrl } from "../../Config/apiUrl";
import Aos from "aos";
import "aos/dist/aos.css";
import Header from "../../stories/Header/index";
import Footer from "../../stories/Footer";
import PackagesCard from "../../stories/PackageCard";
import Button from "../../stories/Button";
import { Get, Post } from "../../Axios/AxiosFunctions";
import NoData from "../../stories/NoData/NoData";
import { toast } from "react-toastify";
import PaymentModal from "../../Components/Modals/PaymentModal";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../../store/Actions/authAction";

function MechanicPackages() {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state?.authReducer?.accessToken);
  const userData = useSelector((state) => state?.authReducer?.user);
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [buyPackageLoading, setBuyPackageLoading] = useState(false);
  const [cancelSubsLoading, setCancelSubsLoading] = useState(false);

  const headers = apiHeader(accessToken);

  console.log(userData, "userData-----");

  const getPackages = async () => {
    const url = BaseURL("get-plans");
    setLoading(true);
    const response = await Get(url);
    if (response !== undefined) {
      setResponseData(response?.data?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPackages();
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);

  const onBuyNow = (packageCard) => {
    setSelectedPackage(packageCard);
    setShowPaymentModal(true);
  };

  const buyPackage = async (paymentMethod) => {
    const url = BaseURL("mechanic/create-subscription");
    const params =
      selectedPackage?.product?.metadata?.premium !== undefined
        ? {
          paymentMethodId: paymentMethod?.id,
          planId: selectedPackage?.id,
          premium: true,
        }
        : {
          planId: selectedPackage?.id,
          paymentMethodId: paymentMethod?.id,
        };
    setBuyPackageLoading(true);
    const response = await Post(url, params, headers);
    if (response !== undefined) {
      toast.success(
        `🙌 ${selectedPackage?.product?.name} plan was successfully purchased.`
      );
      dispatch(updateUserData(response?.data?.data));
      setShowPaymentModal(false);
    }
    setBuyPackageLoading(false);
  };

  const cancelSubscription = async () => {
    const url = BaseURL("subscription/cancel");
    const params = {
      subscription: userData?.subscription,
    };
    setCancelSubsLoading(true);
    const response = await Post(url, params, headers);
    if (response !== undefined) {
      toast.success("Subscription has been cancelled!");
      console.log(response?.data?.data);
      dispatch(updateUserData(response?.data?.data));
    }
    setCancelSubsLoading(false);
  };

  return (
    <div>
      {/* <Header /> */}
      <div className={classes.header}>
        <Header
          bgTransparent={false}
          containerClass={`${[classes.container].join(" ")}`}
          isLogoBlack={true}
        />
      </div>

      {/* Join Network section */}

      <section className={`${classes.JoinNetwork_Section}`}>
        <Container className={`${classes.container}`}>
          <Row>
            <Col md={12}>
              <div className={classes.subsDiv}>
                <h2>BUY PACKAGES</h2>
                <Button
                  label={
                    cancelSubsLoading
                      ? "Canceling..."
                      : "Cancel Current Subscription"
                  }
                  className={classes.subsBtn}
                  disabled={cancelSubsLoading}
                  onClick={cancelSubscription}
                />
              </div>
            </Col>
            <Col md={12}>
              <Row className={`${classes.JoinNetwork_Section_row}`}>
                {loading ? (
                  <>
                    {[1, 2, 3, 4, 5, 6].map((item, i) => {
                      return (
                        <Col lg={4} md={6} sm={12}>
                          <div className="SkeletonContainer"></div>
                        </Col>
                      );
                    })}
                  </>
                ) : responseData?.length > 0 ? (
                  responseData?.map((item, index) => {
                    return (
                      <Col lg={4} md={6} sm={12} key={index}>
                        <PackagesCard item={item} onBuyNow={onBuyNow} />
                      </Col>
                    );
                  })
                ) : (
                  <>
                    <NoData className={classes.noDataContainer} text={"No Packages Found"} />
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* About us section */}

      {/* Footer section */}

      <section className={`${classes.Footer_Section}`}>
        <Footer containerClass={classes.container} />
      </section>

      <PaymentModal
        show={showPaymentModal}
        setShow={setShowPaymentModal}
        buyPackage={buyPackage}
        buyPackageLoading={buyPackageLoading}
      />

      {/* Footer section */}
    </div>
  );
}

export default MechanicPackages;
