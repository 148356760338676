import React, { useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./ClaimModal.module.css";
import ReactCodeInput from "react-code-input";
import Button from "../../../stories/Button";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const ClaimModal = ({ show, setShow, verifyClick, loading }) => {
  const history = useHistory();
  const [code, setCode] = useState("");

  const submit = async () => {
    const params = {
      unClaimedCode: code,
    };
    if (code.length < 6) {
      return toast.error("Please fill all the fields");
    }
    await verifyClick(params);
  };

  return (
    <ModalSkeleton
      show={show}
      setShow={() => console.log()}
      borderRadius={"24px"}
    >
      <div className={[classes.modalBody]}>
        <div className={[classes.headerDiv]}>
          <h3>VERIFY</h3>
        </div>
        <div className={[classes.verifyCodeDiv]}>
          <ReactCodeInput
            type={"number"}
            fields={6}
            onChange={(pinCode) => setCode(pinCode)}
            value={code}
          />
        </div>
        <div className={[classes.BtnDiv]}>
          <Button
            label={"Go Back"}
            onClick={() => {
              history.goBack();
            }}
          />
          <Button
            label={loading ? "Verifying..." : "Verify"}
            onClick={submit}
            disabled={loading}
          />
        </div>
      </div>
    </ModalSkeleton>
  );
};

export default ClaimModal;
