import React from "react";
import { AiFillDelete } from "react-icons/ai";
import { MASTERCARD, VISACARD } from "../../constant/imagePath";
import classes from "./CreditCard.module.css";

const CreditCard = ({
  cardData,
  detachCard,
  setSelectedCard,
  selectedCard,
  removeCardLoading,
}) => {
  console.log(cardData, "cardData");
  return (
    <div
      className={[classes.cardMainDiv]}
      onClick={(e) => {
        e.stopPropagation();
        if (selectedCard?.id === cardData?.id) {
          setSelectedCard(null);
        } else {
          setSelectedCard(cardData);
        }
      }}
      style={{
        boxShadow:
          selectedCard?.id === cardData?.id &&
          "0 0 4px 0 var(--main-color-red)",
      }}
    >
      <div>
        <div className={[classes.cardImg]}>
          <img src={cardData?.card?.brand === "visa" ? VISACARD : MASTERCARD} />
        </div>
        <div className={classes.cardNumberDiv}>
          <p className={[classes.cardLabel]}>{cardData?.card?.brand}</p>
          {cardData?.cardName !== "Paypal" && (
            <p className={[classes.cardLabel]}>**** {cardData?.card?.last4}</p>
          )}
        </div>
      </div>

      {cardData?.cardName !== "Paypal" && (
        <div className={classes.cardExpiryDiv}>
          <p className={[classes.cardLabel]}>Expiration date:</p>
          <p className={[classes.cardLabel]}>
            {cardData?.card?.exp_month}/{cardData?.card?.exp_year}
          </p>
        </div>
      )}
      <div className={[classes.delCardIconDiv]}>
        <AiFillDelete
          size={25}
          color={"#000"}
          onClick={(e) => {
            e.stopPropagation();
            if (!removeCardLoading) {
              detachCard(cardData?.id);
            }
          }}
        />
      </div>
    </div>
  );
};

export default CreditCard;
