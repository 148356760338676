import React, { useEffect, useRef, useState } from "react";
import classes from "./ChatMobile.module.css";
import { Col, Row, Container } from "react-bootstrap";
import { FiChevronLeft } from "react-icons/fi";
import InputWithButton from "../../stories/InputWithButton/InputWithButton";
import { useSelector } from "react-redux";
import { IoIosSend } from "react-icons/io";
import { imageUrl } from "../../Config/apiUrl";
import moment from "moment";
import Button from "../../stories/Button";
import NoData from "../../stories/NoData/NoData";
import Loader from "../../stories/Loader";

const ChatMobile = ({
  roomsData,
  selectedRoom,
  setSelectedRoom,
  messages,
  sendMessage,
  getPrevMessages,
  loadMoreMessages,
  isLoadingChats,
  isLoadingRooms

}) => {
  const userData = useSelector((state) => state?.authReducer?.user);
  const userType = useSelector((state) => state?.authReducer?.user_type);

  const [message, setMessage] = useState("");

  // For Scroll To Bottom
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  useEffect(scrollToBottom, []);

  return (
    <>
      <Container
        fluid
        className={[classes.mainContainer].join(" ")}
      >
        {selectedRoom === null ? (
          <>
            {
              isLoadingRooms ?
                <Loader />
                :
                <Row>
                  <Col md={12}>
                    {
                      roomsData?.length == 0 ?
                        <NoData className={classes.noData} text={'No Rooms Found'} />
                        :
                        roomsData.map((item, i) => {
                          return (
                            <div
                              className={[classes.ChatRoom_innerDiv]}
                              key={i}
                              onClick={() => {
                                // setIsChat(true);
                                setSelectedRoom(item);
                              }}
                            >
                              <div className={[classes.ChatRoom_img_div]}>
                                <img
                                  src={imageUrl(
                                    userType === "customer"
                                      ? item?.mechanic?.photo
                                      : item?.customer?.photo
                                  )}
                                />
                              </div>
                              <div className={[classes.ChatRoom_text_div]}>
                                <div className={[classes.ChatRoom_innertext_div]}>
                                  <div>
                                    <h5>
                                      {userType === "customer"
                                        ? item?.mechanic?.userName
                                        : item?.customer?.userName}
                                    </h5>
                                  </div>
                                  <div>
                                    <p>{moment(item?.createdAt).format("hh:mm a")}</p>
                                  </div>
                                </div>
                                <p className={[classes.ChatRoom_innertext_div_para]}>
                                  {item?.lastMessage?.text}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                  </Col>
                </Row>}
          </>
        ) : (
          // Main Chat screen
          <>
            <div className={classes.inbox}>
              <div className={classes.chatMain_header}>
                <div className={classes.chatHeaders}>
                  <div className={classes.chatHeadersInner}>
                    <FiChevronLeft
                      onClick={() => setSelectedRoom(null)}
                      color="#373737"
                      size="25"
                    />
                  </div>
                  <div className={classes.chatSidebarList}>
                    <div className={classes.chatHeader}>
                      <img
                        src={imageUrl(
                          userType === "customer"
                            ? selectedRoom?.mechanic?.photo
                            : selectedRoom?.customer?.photo
                        )}
                        alt="chatSideBar"
                      />
                    </div>
                    <div className={classes.chatSiderName}>
                      <p className={classes.chatName}>
                        {userType === "customer"
                          ? selectedRoom?.mechanic?.userName
                          : selectedRoom?.customer?.userName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={[classes.chattingDiv]}>
                <div ref={messagesEndRef} />
                {
                  isLoadingChats ?
                    <Loader />
                    :
                    messages?.length == 0 ?
                      <NoData className={classes.noData} text={'No Chats Found'} />
                      :
                      messages.map((item, i) => {
                        return (
                          <>
                            <div key={i}>
                              {/* for left align messages  */}
                              {userData?.UserId?._id !== item?.user?._id ? (
                                <>
                                  <div className={classes.incomingMessage}>
                                    <p>{item?.text}</p>
                                  </div>
                                  <span className={classes.time}>
                                    {moment(item?.createdAt).format("hh:mm a")}
                                  </span>
                                </>
                              ) : (
                                //  for right align messages
                                <>
                                  <div className={classes.outcomingMessage}>
                                    <p>{item?.text}</p>
                                  </div>
                                  <span className={classes.Incomingtime}>
                                    {moment(item?.createdAt).format("hh:mm a")}
                                  </span>
                                </>
                              )}
                            </div>
                          </>
                        );
                      })}
                <div className={[classes.loadMoreButtonDiv]}>
                  {messages?.length > 30 && <Button
                    label={loadMoreMessages ? "loading..." : "load more"}
                    onClick={() =>
                      getPrevMessages(messages, messages?.length + 1, 30)
                    }
                    disabled={loadMoreMessages}
                  />}
                </div>
              </div>
              <div className={[classes.sendBtnDiv]}>
                <InputWithButton
                  mainContainerClass={classes.SendInputMainContainer}
                  inputClass={classes.sendInput}
                  btnClass={classes.SendInputBtnContainer}
                  placeholder={"Type message here..."}
                  btnChildern={
                    <IoIosSend color={"var(--main-color-red)"} size={24} />
                  }
                  value={message}
                  setValue={setMessage}
                  onClick={() => {
                    sendMessage(message);
                    setMessage("");
                  }}
                />
              </div>
            </div>
          </>
        )}
      </Container>
    </>
  );
};

export default ChatMobile;
