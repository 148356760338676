import React, { useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./forgetPassword.module.css";
import Input from "./../../../stories/Input/index";
import Button from "./../../../stories/Button/index";
import { FiMail } from "react-icons/fi";
import { apiHeader, BaseURL } from "../../../Config/apiUrl";
import { Post } from "../../../Axios/AxiosFunctions";
import { toast } from "react-toastify";

function ForgetPassword({ show, setShow }) {
  const [email, setEmail] = useState("");
  const [loading, setIsLoading] = useState(false);

  const handleForgetPassword = async () => {
    const url = BaseURL("forgetPasswordWeb");
    if (email === "") {
      return toast.error("Required Field!", `Email Can't be empty`);
    }
    const params = {
      email,
    };
    setIsLoading(true);
    const response = await Post(url, params, apiHeader());
    if (response !== undefined) {
      toast.success("Token send Successfully to your email");
      setShow(false);
    }
    setIsLoading(false);
  };

  return (
    <ModalSkeleton show={show} setShow={setShow} borderRadius={"40px"}>
      <div className={`${classes.ForgetPassword_div}`}>
        <h3>FORGOT YOUR PASSWORD</h3>
        <div className={`${classes.ForgetPassword_div_inp}`}>
          <Input
            type={`email`}
            placeholder={"Enter Email"}
            setter={setEmail}
            value={email}
            leftIcon={<FiMail size={24} color={"#E60000"} />}
          />
        </div>
        <Button
          label={loading ? "Sending..." : "Send"}
          disabled={loading}
          onClick={handleForgetPassword}
          className={`${classes.ForgetPassword_btn} ${classes.c_p}`}
        />
      </div>
    </ModalSkeleton>
  );
}

export default ForgetPassword;
