import React, { useState, useEffect } from "react";
import classes from "./homePage.module.css";
import Header from "../../stories/Header";
import HeroSection from "./../../stories/HeroSection";
import bgImage from "../../assets/images/Home_banner.png";
import Map from "./../../stories/Map/index";
import { Container, Row, Col } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import DropDown from "../../stories/DropDown";
import Button from "./../../stories/Button/index";
import img1 from "../../assets/images/trustworthy.png";
import img2 from "../../assets/images/help.png";
import img3 from "../../assets/images/about_delivery.png";
import img4 from "../../assets/images/home_sec4.png";
import img5 from "../../assets/images/section7_img.png";
import img6 from "../../assets/images/sect2_img.png";
import OurServiceCard from "../../stories/OurServiceCard";
import BackgroundImageWithInfo from "../../stories/BackgroundImageWithInfo";
import Footer from "./../../stories/Footer";
import LocationInput from "./../../stories/LocationInput/index";
import MechanicCard from "./../../stories/MechanicCard/index";
import Aos from "aos";
import "aos/dist/aos.css";
import Accordion from "../../stories/Accordion";
import { BaseURL, imageUrl, maxApiDataLength } from "../../Config/apiUrl";
import { Get } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import NoData from "../../stories/NoData/NoData";
import CurrentBooking from "../../stories/CurrentBookingCard";

function HomePage() {
  const { isLogin, accessToken, user_type, user } = useSelector(
    (state) => state.authReducer
  );
  const { certificatesArray, servicesArray, defaultCoords } = useSelector(
    (state) => state.commonReducer
  );

  const [place, setPlace] = useState(null);
  const [currentBookings, setCurrentBookings] = useState([]);
  const [myCoords, setMyCoords] = useState(
    defaultCoords?.lat !== undefined
      ? defaultCoords
      : { lat: 40.73061, lng: -73.935242 }
  );
  const [blurLoader, setBlurLoader] = useState(false);
  const [dataList, setDataList] = useState([]);

  const [selectedCertificates, setSelectedCertificates] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [machanicData, setMachanicData] = useState([]);
  // const [machanicData, setMachanicData] = useState(["", "", ""]);
  const [getMechanicPageNumber, setGetMechanicPageNumber] = useState(1);
  const [getMechanicLoading, setGetMechanicLoading] = useState(false);

  const [currentBookingData, setCurrentBookingData] = useState([]);

  const [getCurrentBookingPageNumber, setGetCurrentBookingPageNumber] =
    useState(1);
  const [getCurrentBookingLoading, setGetCurrentBookingLoading] =
    useState(false);

  const cmsDataApiUrl = BaseURL(
    "pages?pages=home&goals=true&service=true&aboutusItem=false&faq=true&all=false"
  );

  const getCmsPageData = async () => {
    setBlurLoader(true);
    const responseData = await Get(cmsDataApiUrl);
    if (responseData !== undefined) {
      setDataList(responseData.data.data);
    } else {
      setDataList([]);
    }
    setBlurLoader(false);
  };

  const getMechanicsNearMe = async (pageNo, oldArray) => {
    const filterArray = [];
    selectedCertificates?.map((item) => {
      filterArray.push(item?._id);
    });
    selectedServices?.map((item) => {
      filterArray.push(item?._id);
    });

    let nearestMechanicApiUrl = BaseURL(
      `customer/mechanics-near-me?latlng=${myCoords?.lat},${
        myCoords?.lng
      }&limit=${maxApiDataLength}&page=${pageNo}&types=${JSON.stringify(
        filterArray
      )}`
    );

    setGetMechanicLoading(true);
    let responseData = await Get(nearestMechanicApiUrl, accessToken);
    if (responseData !== undefined) {
      setGetMechanicPageNumber((pre) => pre + 1);
      let mechanicArray = oldArray.slice();
      mechanicArray = mechanicArray.concat(responseData?.data?.data);
      setMachanicData(mechanicArray);
    }
    setGetMechanicLoading(false);
  };

  const resetGetMechanicData = () => {
    setSelectedCertificates([]);
    setSelectedServices([]);
    setGetMechanicPageNumber(1);
    setMachanicData([]);
    getMechanicsNearMe(1, []);
  };

  const getCurrentBookings = async (pageNo, oldArray) => {
    const currentBookingsURL = BaseURL(
      `bookings/${user?._id}?limit=${maxApiDataLength}&page=${pageNo}&status=pending`
    );

    setGetCurrentBookingLoading(true);
    let responseData = await Get(currentBookingsURL, accessToken);
    if (responseData !== undefined) {
      setGetCurrentBookingPageNumber((pre) => pre + 1);
      let bookingArray = oldArray.slice();
      bookingArray = bookingArray.concat(responseData?.data?.data);
      setCurrentBookingData(bookingArray);
    }
    setGetCurrentBookingLoading(false);
  };

  useEffect(() => {
    getCmsPageData();
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
    if (user_type !== "mechanic") getMechanicsNearMe(1, []);
    if (isLogin && user_type == "mechanic") getCurrentBookings(1, []);
  }, []);

  const RenderSkeletons = () => {
    return [1, 2, 3, 4, 5, 6].map((item, i) => {
      return (
        <Col lg={4} md={6} sm={12}>
          <div className="SkeletonContainer"></div>
        </Col>
      );
    });
  };

  return (
    <div className={[classes.page, blurLoader && "pageBlur"].join(" ")}>
      {/* <Header /> */}
      <div className={classes.header}>
        <Header
          bgTransparent={true}
          containerClass={`${[classes.custom_Container].join(" ")}`}
        />
      </div>

      <HeroSection
        BgImg={imageUrl(dataList?.pages?.cover_image)}
        alt="img"
        title={dataList?.pages?.heading}
        description={dataList?.pages?.detail_description}
        sectionClass={classes.heroSection}
      >
        {/* Location Input */}
        <Row>
          <Col md={8} xl={9} sm={9} xs={12}>
            <LocationInput setPlace={setPlace} setCordinate={setMyCoords} />
          </Col>
          <Col md={4} xl={3} sm={3} xs={12}>
            <Button
              label={"Search"}
              className={`${classes.searchBtn}`}
              onClick={() => {
                if (place !== null) {
                  if (user_type !== "mechanic") {
                    setMachanicData([]);
                    setGetMechanicPageNumber(1);
                    getMechanicsNearMe(1, []);
                  }
                  if (isLogin && user_type == "mechanic") {
                    setCurrentBookingData([]);
                    setGetCurrentBookingPageNumber(1);
                    getCurrentBookings(1, []);
                  }
                }
              }}
            />
          </Col>
        </Row>

        {/* Location Input */}
      </HeroSection>

      {/* Map  */}
      <div className={`${classes.Map_section}`}>
        <Container className={`${classes.custom_Container}`}>
          <Row>
            <Col lg={12}>
              {/* <div className={`${classes.map_container}`}> */}
              <Map
                locations={currentBookings}
                myCoords={myCoords}
                mapContainerClass={classes.map_container}
              />
              {/* </div> */}
            </Col>
          </Row>
        </Container>
      </div>
      {/* Map  */}

      {/* For Mechanic After Login Render Current Booking on pending Status Section Start */}
      {isLogin && user_type == "mechanic" && (
        <section>
          <Container className={`${classes.custom_Container}`}>
            <Row>
              <Col md={12}>
                <div className={`${classes.available_mechanics}`}>
                  <h1>Current Bookings</h1>
                </div>
              </Col>
            </Row>
            <Row className={`${classes.machanicCard_row}`}>
              {getCurrentBookingLoading ? (
                <RenderSkeletons />
              ) : (
                currentBookingData?.map((item, index) => {
                  // console.log("=============>===========>currentBookingData",currentBookingData)
                  return (
                    <Col lg={12} md={12} sm={12}>
                      <CurrentBooking
                        item={item}
                        index={index}
                        setCurrentBookingData={setCurrentBookingData}
                        currentBookingData={currentBookingData}
                      />
                    </Col>
                  );
                })
              )}

              {currentBookingData?.length == 0 &&
                getCurrentBookingLoading == false && (
                  <NoData
                    text={"No Current Bookings Found"}
                    className={classes.noData}
                  />
                )}
            </Row>
            {maxApiDataLength * (getCurrentBookingPageNumber - 1) ==
              currentBookingData?.length && (
              <Col>
                <Button
                  label={getCurrentBookingLoading ? "loading..." : "Load More"}
                  disabled={getCurrentBookingLoading}
                  onClick={() => {
                    getCurrentBookingLoading == false &&
                      getCurrentBookings(
                        getCurrentBookingPageNumber,
                        currentBookingData
                      );
                  }}
                  className={`${classes.loadMoreBtn}`}
                />
              </Col>
            )}
          </Container>
        </section>
      )}
      {/* For Mechanic After Login Render Current Booking on pending Status Section End*/}

      {/* For Customer Render Available in your Area Section Start */}
      {user_type !== "mechanic" && (
        <section id="2">
          <Container className={`${classes.custom_Container}`}>
            <Row>
              <Col md={12}>
                <div className={`${classes.available_mechanics}`}>
                  <p className="available_p">Available in your Area</p>
                  <h1>MOBILE MECHANICS</h1>
                </div>
              </Col>
              <Col md={12}>
                <div className={`${classes.fliter_div}`}>
                  <h2>Filter</h2>
                  <div>
                    <FaFilter className={`${classes.filterIcon}`} />
                  </div>
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className={`${classes.mech_filter}`}>
                  <h5>Mechanic Certifications </h5>
                  <DropDown
                    placeholder={"Certificates"}
                    isMulti
                    options={certificatesArray}
                    optionLabel={"CertificateType"}
                    optionValue={"CertificateType"}
                    setter={setSelectedCertificates}
                    value={selectedCertificates}
                  />
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className={`${classes.mech_filter}`}>
                  <h5>Mechanic Services </h5>
                  <DropDown
                    placeholder={"services"}
                    isMulti
                    options={servicesArray}
                    optionLabel={"serviceType"}
                    optionValue={"serviceType"}
                    setter={setSelectedServices}
                    value={selectedServices}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className={`${classes.filterBtn_div}`}>
                  <Button
                    label={`Apply Filters`}
                    className={`${classes.red_btn} ${classes.filter_btn}`}
                    onClick={() => {
                      setGetMechanicPageNumber(1);
                      setMachanicData([]);
                      getMechanicsNearMe(1, []);
                    }}
                  />
                  <Button
                    label={`Clear Filters`}
                    className={`${classes.white_btn} ${classes.filter_btn}`}
                    onClick={() => {
                      resetGetMechanicData();
                    }}
                  />
                </div>
              </Col>
            </Row>

            <Row className={`${classes.machanicCard_row}`}>
              {getMechanicLoading ? (
                <RenderSkeletons />
              ) : (
                machanicData?.map((item, index) => {
                  return (
                    <Col lg={4} md={6} sm={12}>
                      <MechanicCard item={item} />
                    </Col>
                  );
                })
              )}

              {machanicData.length == 0 && getMechanicLoading == false && (
                <NoData
                  text={"Sorry, no mechanics found in this area."}
                  className={classes.noData}
                />
              )}
            </Row>
            {maxApiDataLength * (getMechanicPageNumber - 1) ==
              machanicData.length && (
              <Col>
                <Button
                  label={getMechanicLoading ? "loading..." : "Load More"}
                  disabled={getMechanicLoading}
                  onClick={() => {
                    getMechanicLoading == false &&
                      getMechanicsNearMe(getMechanicPageNumber, machanicData);
                  }}
                  className={`${classes.loadMoreBtn}`}
                />
              </Col>
            )}
          </Container>
        </section>
      )}
      {/* For Customer Render Available in your Area Section End*/}
      {isLogin === false && (
        <>
          {/* mobileMachanics_AferSection */}

          <section className={`${classes.mobileMachanics_AferSection}`}>
            <Container className={`${classes.custom_Container}`}>
              <Row className={`${classes.mobileMachanics_AferSection_row}`}>
                {dataList?.goals?.map((item, index) => {
                  return (
                    <Col lg={3} md={6} sm={12}>
                      <OurServiceCard item={item} />
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </section>

          {/* mobileMachanics_AferSection */}

          {/* About us section */}

          <section className={`${classes.aboutUs_Section}`}>
            <Container className={`${classes.custom_Container}`}>
              <Row>
                <Col md={12}>
                  <div
                    data-aos="fade-up"
                    className={`${classes.aboutUs_Section_div}`}
                  >
                    <h5 className={`${classes.upper_text_heading}`}>
                      The Story About Us
                    </h5>
                    <h1 className={`${classes.lower_text_heading}`}>
                      ABOUT US <span>ABOUT US</span>
                    </h1>
                  </div>
                </Col>
                <Col md={12}>
                  <Row className={`${classes.aboutUs_row}`}>
                    <Col md={6} sm={12}>
                      <div
                        data-aos="fade-left"
                        className={`${classes.aboutImg_sec}`}
                      >
                        <img
                          src={imageUrl(dataList?.pages?.sec1Image)}
                          className="img-fluid"
                          alt=""
                        ></img>
                      </div>
                    </Col>
                    <Col md={6} sm={12}>
                      <div
                        data-aos="fade-right"
                        className={`${classes.aboutSection_para}`}
                      >
                        <h5>{dataList?.pages?.sec1Heading}</h5>
                        <p>{dataList?.pages?.sec1Description}</p>
                        <Button
                          label={"Learn More"}
                          className={classes.learn_more_btn}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>

          {/* About us section */}

          {/* Background Image with description section */}

          <section className={`${classes.background_with_image_Section}`}>
            <Container className={`${classes.custom_Container}`}>
              <Row>
                <Col lg={12}>
                  <BackgroundImageWithInfo
                    item={{
                      title: dataList?.pages?.sec2Heading,
                      description: dataList?.pages?.sec2Description,
                      image: imageUrl(dataList?.pages?.sec2Image),
                    }}
                    bgimgClass={classes.bgimgtyer}
                    containerClass={`${classes.background_img_container}`}
                  />
                </Col>
              </Row>
            </Container>
          </section>

          {/* Background Image with description section */}

          {/* WE OFFER THESE SERVICES section */}

          <section className={`${classes.OfferServices_Section}`}>
            <Container className={`${classes.custom_Container}`}>
              <Row>
                <Col md={12}>
                  <div data-aos="fade-up">
                    <h1 className={`${classes.lower_text_heading}`}>
                      WE OFFER THESE SERVICES <span>SERVICES</span>
                    </h1>
                  </div>
                </Col>
                <Col md={12}>
                  <Row className={`${classes.OfferServices_Section_row}`}>
                    {dataList?.services?.map((item, index) => {
                      return (
                        <Col lg={3} md={6} sm={12}>
                          <OurServiceCard item={item} />
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>

          {/* WE OFFER THESE SERVICES section */}

          {/* FREQUIENTLY ASKED QUESTION section */}

          <section className={`${classes.FrequientlyAsked_Section}`}>
            <Container className={`${classes.custom_Container_faq}`}>
              <Row>
                <Col md={12}>
                  <div data-aos="fade-up" className={`${classes.FAQ_head}`}>
                    <h5 className={`${classes.upper_text_heading}`}>
                      Answer of Common Question
                    </h5>
                    <h1 className={`${classes.lower_text_heading}`}>
                      FREQUIENTLY ASKED QUESTION <span>FAQ</span>
                    </h1>
                  </div>
                </Col>
                <Col md={12}>
                  <div className={`${classes.FAQ_container}`}>
                    <Accordion data={dataList?.faq} />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/* FREQUIENTLY ASKED QUESTION section */}

          {/* FREQUIENTLY ASKED QUESTION section */}

          <section className={`${classes.WantToJoin_Section}`}>
            <Container className={`${classes.custom_Container}`}>
              <Row>
                <Col lg={12}>
                  <BackgroundImageWithInfo
                    item={{
                      title: dataList?.pages?.sec3Heading,
                      description: dataList?.pages?.sec3Description,
                      image: imageUrl(dataList?.pages?.sec3Image),
                    }}
                    btn={true}
                    bgimgClass={classes.bgimgnetwork}
                    btnClass={classes.join_btn}
                    containerClass={`${classes.background_img_container}`}
                  />
                </Col>
              </Row>
            </Container>
          </section>

          {/* FREQUIENTLY ASKED QUESTION section */}
        </>
      )}

      {/* Footer section */}

      <section className={`${classes.Footer_Section}`}>
        <Footer containerClass={classes.custom_Container} />
      </section>

      {/* Footer section */}
    </div>
  );
}

export default HomePage;
