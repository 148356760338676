import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CustomAccordion from "../../stories/Accordion";
import Footer from "../../stories/Footer";
import Header from "../../stories/Header";
import HeroSection from "../../stories/HeroSection";
import classes from "./PrivacyPolicy.module.css";
import Aos from "aos";
import { BaseURL, imageUrl } from "../../Config/apiUrl";
import { Get } from "../../Axios/AxiosFunctions";
import parse from "html-react-parser";

function PrivacyPolicy(props) {
  const [blurLoader, setBlurLoader] = useState(false);
  const [dataList, setDataList] = useState([]);

  const [selectedPolicy, setSelectedPolicy] = useState([]);

  const getPrivacyPolicyData = async () => {
    const cmsDataApiUrl = BaseURL(
      "pages?pages=privacy_policy&privacyPolicy=true"
    );
    setBlurLoader(true);
    const responseData = await Get(cmsDataApiUrl);
    if (responseData !== undefined) {
      setDataList(responseData.data.data);
      setSelectedPolicy(responseData?.data?.data?.privacyPolicy[0]);
    } else {
      setDataList([]);
    }
    setBlurLoader(false);
  };

  useEffect(() => {
    getPrivacyPolicyData();
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={[classes.page, blurLoader && "pageBlur"].join(" ")}>
      <div className={classes.header}>
        <Header
          bgTransparent={true}
          containerClass={`${[classes.container].join(" ")}`}
        />
      </div>
      <HeroSection
        BgImg={imageUrl(dataList?.pages?.cover_image)}
        title={"PRIVACY POLICY"}
        sectionClass={classes.heroSection}
        description={dataList?.pages?.detail_description}
      />

      <section className={classes.faqSection}>
        <Container className={classes.container}>
          <Row >
            <Col xxl={2} md={3} sm={12} >
              {dataList?.privacyPolicy?.map((item, i) => {
                return (
                  <div
                    className={classes.card}
                    key={i}
                    onClick={() => {
                      setSelectedPolicy(item);
                    }}
                  >
                    <p
                      className={[
                        selectedPolicy?.title === item?.title &&
                        classes.selectedPolicyContent,
                      ]}
                    >
                      {item?.title}
                    </p>
                  </div>
                );
              })}
            </Col>
            <Col xxl={10} md={9} sm={12}>
              <div className={classes.description}>
                {parse(`${selectedPolicy?.content}`)}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer containerClass={classes.container} />
    </div>
  );
}

export default PrivacyPolicy;

PrivacyPolicy.defaultProps = {
  data: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla",
};
