import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classes from "./PackagesCard.module.css";
import Button from "../Button";
import { setRegistrationType } from "../../store/Actions/authAction";
import { useHistory } from "react-router-dom";

function PackagesCard({ item, onBuyNow, onSignupNow }) {
  const isLogin = useSelector((state) => state.authReducer.isLogin);
  const user_type = useSelector((state) => state.authReducer.user_type);
  const userData = useSelector((state) => state.authReducer.user);
  const hasAlreadyPurchased = userData?.plan !== null;

  console.log(userData, "userData*****");

  return (
    <div data-aos="zoom-in-up" className={classes.card}>
      <div className={classes.header}>
        <h4>{item?.product?.name}</h4>
        <h5>
          <span>{`$${item?.amount / 100}`}</span>
          {`/${item?.interval_count > 1 ? item?.interval_count : ""} ${
            item?.interval
          }`}
        </h5>
      </div>
      <div className={classes.content}>
        <ul className={classes.list}>
          {item?.product?.description?.split(".")?.map((data, i) => {
            return data !== "" && <li key={i}>{`${data} .`} </li>;
          })}
        </ul>
        {isLogin && user_type === "mechanic" ? (
          <div>
            {!hasAlreadyPurchased &&
            item?.product?.name === "FREE MEMBERSHIP" ? (
              <div className={classes.justifyCenter}>
                <p className="activated-pack">Current Activated Package</p>
              </div>
            ) : userData?.plan === item?.id ? (
              <div className={classes.justifyCenter}>
                <p className="activated-pack">Current Activated Package</p>
              </div>
            ) : (
              <div className="button-container">
                <Button className={classes.btn} onClick={() => onBuyNow(item)}>
                  Buy Now
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div className={classes.justifyCenter}>
            <Button className={classes.btn} onClick={() => onSignupNow(item)}>
              Sign-up Now
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default PackagesCard;
