import {
  SET_REGISTRATION_TYPE,
  SET_CERTIFICATES_SERVICES_ARRAY,
  DEFAULT_LAT_LNG,
} from "../Actions/actionType";

const INITIAL_STATE = {
  registrationType: null,
  packageDetail: null,
  certificatesArray: [],
  servicesArray: [],
  defaultCoords: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DEFAULT_LAT_LNG:
      return {
        ...state,
        defaultCoords: action.coords,
      };
    case SET_REGISTRATION_TYPE:
      return {
        ...state,
        registrationType: action.payload?.type,
        packageDetail: action.payload?.item,
      };
    case SET_CERTIFICATES_SERVICES_ARRAY:
      return {
        ...state,
        certificatesArray: action.payload?.certificate,
        servicesArray: action.payload?.service,
      };
    default:
      return state;
  }
};
