import React, { useState } from "react";
import classes from "./InstagramPostCard.module.css";
import { Container, Row, Col } from "react-bootstrap";
import Button from "../Button";
import moment from "moment";

function InstagramPostCard({ item, index }) {
  return (
    <Container
      className={`g-0 ${classes.cardContainer}`}
      key={`instaPost-${index}`}
    >
      <Row className={`g-0 ${classes.cardRow}`}>
        <Col md={12} className={`g-0 ${classes.cardMainDiv}`}>
          <div className={`g-0 ${classes.imageContainer}`}>
            <img src={item?.media_url} />
          </div>
          <div className={classes?.contentContainer}>
            <div className={classes?.captionContainer}>
              <p>
                <span className={classes?.userName}>{item?.username}</span>
                {item?.caption && item?.caption}
              </p>
            </div>
            <div className={classes?.btnContainer}>
              <p className={classes?.createdAt}>
                {moment(item?.timestamp).fromNow()}
              </p>
              <Button
                onClick={() => window.open(item?.permalink)}
                className={classes.yellowBtn}
              >
                View Post
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default InstagramPostCard;
