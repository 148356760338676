import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Get } from "../../Axios/AxiosFunctions";
import { imageUrl, URL } from "../../Config/apiUrl";
import classes from "./footer.module.css";
import { ImFacebook } from "react-icons/im";
import { ImGooglePlus } from "react-icons/im";
import { BsTwitter } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { useHistory, Link } from "react-router-dom";

import {
  logofooterimg,
  download01,
  download02,
} from "../../constant/imagePath";
import { useSelector } from "react-redux";

const Footer = ({ containerClass, dataList }) => {
  const history = useHistory();
  //

  const isLogin = useSelector((state) => state.authReducer.isLogin);
  const user_type = useSelector((state) => state?.authReducer?.user_type);

  return (
    <section className={classes.footerSection}>
      <Container
        className={[
          classes.footerContainer,
          containerClass && containerClass,
        ].join(" ")}
      >
        <Row className={classes.footerRow}>
          <Col lg={3} md={3} sm={12} className={classes.colume_one}>
            <div>
              <img
                src={logofooterimg}
                className={` ${classes.logo}`}
                alt=""
                onClick={() => history.push("/")}
              />
              <p className={classes.tagLine}>Local, Honest, and Reputable</p>
              <div>
                {dataList?.map((ele) => {
                  return (
                    <a
                      href={ele?.link}
                      target="_blank"
                      style={{ marginRight: "20px" }}
                    >
                      <img
                        style={{ width: "25px", height: "auto" }}
                        src={imageUrl(ele?.icon)}
                      />
                    </a>
                  );
                })}
              </div>
            </div>
          </Col>
          <Col lg={3} md={3} sm={12} className={classes.colume_two}>
            <div>
              <h4 className={classes.footerHeading}>IMPORTANT</h4>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/consult">Consult</Link>
                </li>

                {user_type === "customer" && (
                  <li>
                    <Link to="/available-mechanics">Available Mechanics</Link>
                  </li>
                )}
              </ul>
            </div>
          </Col>
          <Col lg={3} md={3} sm={12} className={classes.colume_three}>
            <div>
              <h4 className={classes.footerHeading}>GET HELP</h4>
              <ul>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={3} md={3} sm={12} className={classes.colume_four}>
            <div>
              <h4 className={classes.footerHeading}>QUICK LINKS</h4>
              <ul>
                {/* {user_type !== "mechanic" && ( */}
                {!isLogin && (
                  <li>
                    <Link to="/join-the-network">Join The Network</Link>
                  </li>
                )}
                <a
                  href="https://play.google.com/store/apps/details?id=com.mobilemechanichub&hl=en&gl=US"
                  target="_blank"
                >
                  <img
                    src={download02}
                    className="img-fluid mt-3 mw-100"
                    alt="img_not_found"
                  />
                </a>
                <a
                  href="https://apps.apple.com/us/app/mobile-mechanic-hub/id1599293218"
                  target="_blank"
                >
                  <img
                    src={download01}
                    className="img-fluid mt-3 mw-50"
                    alt="img_not_found"
                  />
                </a>
              </ul>
            </div>
          </Col>
          {/*  */}
          <div className={`row ${classes.copyright}`}>
            <Col md={12}>
              <p className={classes.copyright_text}>
                © 2021 Mobile Mechanic Hub. All Rights Reserved
              </p>
              <p className={classes.terms_condition}>
                <Link to="/terms-and-conditions">Terms & Condition</Link>
              </p>
            </Col>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default Footer;
