import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import classes from "./currentBooking.module.css";
import PLANT_COUNTRY from "../../assets/images/plantCountry.png";
import MANUFACT from "../../assets/images/productivity.png";
import MODEL_NAME from "../../assets/images/specification (2).png";
import V_TYPE from "../../assets/images/steering-wheel.png";
import MODEL_YEAR from "../../assets/images/factory.png";
import ENGINE_SIZE_CC from "../../assets/images/engineSizeCC.png";
import ENGINE_SIZE_L from "../../assets/images/engineSizeL.png";
import ENGINE_SIZE_CL from "../../assets/images/engineSizeCl.png";
import VIN_NO from "../../assets/images/car-no.png";
import profileImg from "../../assets/images/Ellipse 4.png";
import { BaseURL, imageUrl, apiHeader } from "./../../Config/apiUrl";
import { Patch } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import MessageModal from "./../../Components/Modals/MessageModal";
import { v4 as uuidv4 } from "uuid";
import { Post } from "../../Axios/AxiosFunctions";
import { toast } from "react-toastify";
import CompleteTaskModal from "../../Components/Modals/CompleteTaskModal";
import ConfirmationModal from "../../Components/Modals/Confirmation";
import Button from "../Button";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
function CurrentBooking({
  item,
  index,
  setCurrentBookingData,
  currentBookingData,
  isUpdateDataInState = false,
}) {
  const history = useHistory()
  const [isAcceptingModal, setIsAcceptingModal] = useState(false);
  const [isRejectingModal, setIsRejectingModal] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false)
  const accessToken = useSelector((state) => state.authReducer.accessToken);

  const userData = useSelector((state) => state.authReducer?.user);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showCompleteTaskModal, setShowCompleteTaskModal] = useState(false);
  const user_type = useSelector((state) => state.authReducer.user_type);

  // For Api call
  const [isAccepting, setIsAccepting] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);

  console.log('aasaa', { item })




  const completeTask = async () => {
    const changeStatusApiUrl = BaseURL(`booking/update/${item?._id}`);
    setIsCompleted(true);
    const responseData = await Patch(
      changeStatusApiUrl,
      { BookingStatus: "completed" },
      apiHeader(accessToken)
    );
    if (responseData !== undefined) {
      let arr = currentBookingData?.slice();
      if (isUpdateDataInState) {
        arr?.splice(index, 1, responseData?.data?.data);
      } else {
        arr?.splice(index, 1);
      }
      setCurrentBookingData(arr);
      setIsCompleted(false);
      setShowCompleteTaskModal(false)
      toast.success(`Task Completed Successfuly`);
    } else {
      setIsCompleted(false);
      setShowCompleteTaskModal(false)


    }
  };

  const acceptRejectBooking = async (ele, status, index) => {
    const acceptRejectURL = BaseURL(`booking/update/${ele?._id}`);
    // For Loading
    if (status == 'accepted') {
      setIsAccepting(true)
    } else {
      setIsRejecting(true)
    }

    const responseData = await Patch(
      acceptRejectURL,
      { BookingStatus: status },
      apiHeader(accessToken)
    );
    if (responseData !== undefined) {
      let arr = currentBookingData?.slice();

      if (isUpdateDataInState) {
        arr?.splice(index, 1, responseData?.data?.data);
      } else {
        arr?.splice(index, 1);

      }
      console.log({ arr })

      setCurrentBookingData(arr);
      // For Loading
      if (status == 'accepted') {
        setIsAccepting(false)
        setIsAcceptingModal(false)
      } else {
        setIsRejecting(false)
        setIsRejectingModal(false)
      }
      toast.success(`Booking ${status} Successfuly`);
      // For Modal Close

    } else {
      // For Loading
      if (status == 'accepted') {
        setIsAccepting(false)
        setIsAcceptingModal(false)

      } else {
        setIsRejectingModal(false)
        setIsRejecting(false)
      }
    }
  };

  const sendMessage = async (message) => {
    let newMessage = {
      _id: uuidv4(),
      text: message,
      createdAt: Date.now(),
      user: {
        _id: userData?.UserId?._id,
        name: userData?.userName,
        avatar: `${imageUrl(userData?.photo)}`,
      },
    };

    let params = {
      msg: newMessage,
      msgTo: item?.customerDetailId?._id,
      currentUser: user_type,
      fcmToken: "",
      roomId: item?.roomId,
    };
    const messageURL = BaseURL(`chat/message/create`);
    setIsSendingMessage(true);
    const responseData = await Post(messageURL, params, apiHeader(accessToken));
    if (responseData !== undefined) {
      setShowMessageModal(false);
      toast.success(`Message Sent To ${item?.name}`);
    } else {
      console.log("error");
      setIsSendingMessage(false);
    }
  };

  return (
    <>
      <div className={`${classes.UserDetailCard}`}>
        <div className={`${classes.profileSubContainer}`}>
          <div className={`${classes.profile}`}>
            <img src={`${imageUrl(item?.customerDetailId?.photo)}`} />
          </div>
          <div className={`${classes.bookingStatus_conatiner}`}>
            <h5>{item?.name}</h5>
            <h6>{`Booking Status - ${item?.BookingStatus}`}</h6>
          </div>
        </div>
        <Row className={`${classes.booking_card_inner_div}`}>
          <Col lg={4} md={6} sm={12} className={`${classes.infoSubContainer}`}>
            <i class="fas fa-envelope"></i>
            <p>{item?.email}</p>
          </Col>
          <Col lg={6} md={6} sm={12} className={`${classes.infoSubContainer}`}>
            <i class="fas fa-map-marker-alt"></i>
            <p>{item?.serviceAddress}</p>
          </Col>
          <Col lg={4} md={6} sm={12} className={`${classes.infoSubContainer}`}>
            <img src={PLANT_COUNTRY} />
            <p>{item?.PlantCountry}</p>
          </Col>
          <Col lg={4} md={6} sm={12} className={`${classes.infoSubContainer}`}>
            <img src={MANUFACT} />
            <p>{item?.manufactureName}</p>
          </Col>
          <Col lg={4} md={6} sm={12} className={`${classes.infoSubContainer}`}>
            <i class="fas fa-car"></i>
            <p>{`${item?.vehicleName}`}</p>
          </Col>

          {item?.ModelName !== undefined &&
            item?.ModelName !== "" &&
            item?.ModelName !== null && (
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${classes.infoSubContainer}`}
              >
                <img src={MODEL_NAME} style={{ filter: "unset" }} />
                <p>{item?.ModelName}</p>
              </Col>
            )}
          <Col lg={4} md={6} sm={12} className={`${classes.infoSubContainer}`}>
            <img src={V_TYPE} />
            <p>{item?.VehicleType}</p>
          </Col>

          {item?.ModelYear !== undefined &&
            item?.ModelYear !== "" &&
            item?.ModelYear !== null && (
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${classes.infoSubContainer}`}
              >
                <img src={MODEL_YEAR} />
                <p>{`Make Year -${item?.ModelYear}`}</p>
              </Col>
            )}

          {item?.engineSizeCC !== undefined &&
            item?.engineSizeCC !== "" &&
            item?.engineSizeCC !== null && (
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${classes.infoSubContainer}`}
              >
                <img src={ENGINE_SIZE_CC} className={`${classes.plate_img}`} />
                <p>{item?.engineSizeCC}</p>
              </Col>
            )}

          {item?.engineSizeCC !== undefined &&
            item?.engineSizeCI !== "" &&
            item?.engineSizeCI !== null && (
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${classes.infoSubContainer}`}
              >
                <img src={ENGINE_SIZE_CL} className={`${classes.plate_img}`} />
                <p>{item?.engineSizeCI}</p>
              </Col>
            )}

          {item?.engineSizeCC !== undefined &&
            item?.engineSizeL !== "" &&
            item?.engineSizeL !== null && (
              <Col
                lg={4}
                md={6}
                sm={12}
                className={`${classes.infoSubContainer}`}
              >
                <img src={ENGINE_SIZE_L} className={`${classes.plate_img}`} />
                <p>{item?.engineSizeL}</p>
              </Col>
            )}
          <Col lg={4} md={6} sm={12} className={`${classes.infoSubContainer}`}>
            <img src={VIN_NO} />
            <p>{item?.VIN}</p>
          </Col>
          <Col lg={12} sm={12} className={`${classes.infoSubContainer}`}>
            <i class="fas fa-clipboard-list"></i>
            <p>{item?.workDetail}</p>
          </Col>
        </Row>

        {user_type == 'mechanic' && <div className={`${classes.button_container}`}>
          {(item?.BookingStatus === "accepted" ||
            item?.BookingStatus === "pending") && (
              <Button
                className={`${classes.secondaryBtn}`}
                onClick={() => {
                  setShowMessageModal(true);
                }}
              >
                Send Message
              </Button>
            )}

          {item?.BookingStatus === "accepted" && (
            <Button
              className={`${classes.completedBtn}`}
              onClick={() => setShowCompleteTaskModal(true)}
              disabled={isCompleted}
            >
              {`Complete Task`}

            </Button>
          )}
          {item?.BookingStatus === "pending" && (
            <>
              <Button
                className={`${classes.completedBtn}`}
                onClick={() => setIsAcceptingModal(true)}
                disabled={isAcceptingModal}
              >

                {` Accept`}

              </Button>
              <Button
                className={`${classes.rejectBtn}`}
                onClick={() => setIsRejectingModal(true)}
                disabled={isRejectingModal}
              >
                {`Reject`}

              </Button>
            </>
          )}
          {item?.BookingStatus == 'completed' && (
            <Button className={classes.reviewBtn} onClick={() => history.push(`/user-booking-detail/${item?._id}`)}>Review & Rating</Button>
          )}
        </div>}
        {showMessageModal && (
          <MessageModal
            show={showMessageModal}
            setShow={setShowMessageModal}
            title={`Please Write Your Message Below...`}
            user={item}
            onClick={sendMessage}
            buttonText={"SEND"}
            isSendingMessage={isSendingMessage}
          />
        )}
        {showCompleteTaskModal && (
          <ConfirmationModal
            show={showCompleteTaskModal}
            setShow={setShowCompleteTaskModal}
            subTitle={`Do you want to complete this task?`}
            onClick={() => completeTask()}
            isApiCall={isCompleted}
          />
        )}
        {isAcceptingModal && (
          <ConfirmationModal
            show={isAcceptingModal}
            setShow={setIsAccepting}
            subTitle={`Do you want to accept this task?`}
            onClick={() => acceptRejectBooking(item, "accepted", index)}
            isApiCall={isAccepting}
          // isApiCall={}

          />
        )}
        {isRejectingModal && (
          <ConfirmationModal
            show={isRejectingModal}
            setShow={setIsRejecting}
            subTitle={`Do you want to reject this task?`}
            onClick={() => acceptRejectBooking(item, "rejected", index)}
            isApiCall={isRejecting}
          />
        )}
      </div>
    </>
  );
}

export default CurrentBooking;
