import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { imageUrl } from "../../Config/apiUrl";
import { RatingBG, VERIFIED } from "../../constant/imagePath";
import moment from "moment";
import { IoLocationSharp, IoCalendarSharp } from "react-icons/io5";
import classes from "./BookingCard.module.css";
import StarRatings from "react-star-ratings/build/star-ratings";

function BookingCard({ item, index, status }) {
  const history = useHistory();
  const user_type = useSelector((state) => state.authReducer.user_type);
  return (
    <div
      className={classes.card}
      onClick={() => {
        history.push(`/view-booking/${item?._id}`);
        // if (
        //     user_type === "customer" &&
        //     (status === "completed" || status === "past")
        // ) {
        //     history.push(`/user-booking-detail/${item?._id}`);
        // } else if (
        //     user_type === "mechanic" &&
        //     status === "past"
        // ) {
        //     history.push(`/user-booking-detail/${item?._id}`);
        // } else {
        //     history.push(`/view-booking/${item?._id}`);
        // }
      }}
    >
      <div className={classes.cardHeader}>
        {user_type === "customer" && (
          <div className={classes.yellowBg}>
            <div className={classes.rating}>
              <StarRatings
                starDimension={15}
                numberOfStars={1}
                rating={
                  item?.review?.length > 0
                    ? parseFloat(item?.review?.[0]?.rating)
                    : 0
                }
                starEmptyColor={"var(--black-color"}
                starRatedColor={"var(--main-color-red)"}
              />
              <span className={classes.rateNum}>
                {item?.review?.length > 0
                  ? parseFloat(item?.review[0]?.rating).toFixed(1)
                  : 0}
              </span>
            </div>

            <img src={RatingBG} />
          </div>
        )}
        <div className={`${classes.cardImg}`}>
          <img
            src={`${imageUrl(
              user_type === "customer"
                ? item?.mechanicDetailId?.photo
                : item?.customerDetailId?.photo
            )}`}

            // style={{
            //     marginTop: `${user_type === "customer" && "-63px"
            //         }`,
            // }}
          />
        </div>
      </div>
      <div className={classes.cardBody}>
        <div>
          <h2>
            {user_type === "customer"
              ? item?.mechanicDetailId?.userName
              : item?.name}
          </h2>
          {item?.isMMHVerified && (
            <img
              className={`img-fluid ${classes.verifiedMech}`}
              src={VERIFIED}
              style={{ marginTop: "-36px", zIndex: 1 }}
            />
          )}
        </div>
        <div className={`${[classes.iconWithText].join(" ")}`}>
          <IoCalendarSharp className={classes.icon} size={20} />
          <p>{moment(item?.createdAt).format("MMM/DD/yyyy")}</p>
        </div>
        <div className={`${[classes.iconWithText].join(" ")}`}>
          <IoLocationSharp className={classes.icon} />
          <p className={classes.location}>{item?.serviceAddress}</p>
        </div>
      </div>
    </div>
  );
}

export default BookingCard;
