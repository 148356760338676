import React, { useState } from "react";
import Style from "./DesktopHeader.module.css";
import { Navbar, Nav, Container, OverlayTrigger } from "react-bootstrap";
import Button from "../Button";
import { LogoBlack, LogoWhite } from "../../constant/imagePath";
import { NavLink, useHistory, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  BsChatDots,
  BsFillBellFill,
  BsFillEnvelopeOpenFill,
} from "react-icons/bs";
import { BaseURL, imageUrl } from "../../Config/apiUrl";
// User Menu Icon
import { IoLogOut, IoChatbubbles } from "react-icons/io5";
import { IoIosHelpCircle } from "react-icons/io";
import {
  FaHome,
  FaTags,
  FaUnlockAlt,
  FaCertificate,
  FaBook,
} from "react-icons/fa";
import { AiFillSetting } from "react-icons/ai";
import { ClickAwayListener } from "@mui/material";

function NotificationsPopup({ data, setShow }) {
  const history = useHistory();

  return (
    <ClickAwayListener onClickAway={() => setShow(false)}>
      <div className={Style.notifications}>
        <h6>Notifications</h6>
        {/* <div class="options">
                <div class="option"></div>
              </div> */}
        <ul>
          {data?.map((item) => {
            return (
              <li
                className={`${Style.notificationCard} ${
                  item?.data?.isRead && Style.notificationsRead
                }`}
                style={{
                  padding: "10px 5px",
                }}
                onClick={() => history.push(item?.data?.detailPage)}
              >
                <div className={Style.image}>
                  <img src={item?.data?.image} alt="" />
                </div>
                <div className={Style.msg}>
                  <p>{item?.data?.message}</p>
                  <span>{item?.data?.receivedTime}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </ClickAwayListener>
  );
}

function ListItem({ item }) {
  return (
    <li className={Style.optionListItem} onClick={item?.onClick}>
      {item?.icon}
      <span>{item?.title}</span>
    </li>
  );
}

function UserDetailsPopup({ logout, setShow }) {
  const history = useHistory();
  const user_type = useSelector((state) => state.authReducer.user_type);
  const userData = useSelector((state) => state.authReducer?.user);

  const options = [
    {
      title: "Home",
      onClick: () => history.push("/"),
      icon: <FaHome size={20} color={"var(--main-color-red)"} />,
    },
    {
      title: "Bookings",
      onClick: () => history.push("/bookings"),
      icon: <FaBook size={20} color={"var(--main-color-red)"} />,
    },

    {
      title: "Change Password",
      onClick: () => history.push("/change-password"),
      icon: <FaUnlockAlt size={20} color={"var(--main-color-red)"} />,
    },
    {
      title: "Edit Profile",
      onClick: () => history.push("/edit-profile"),
      icon: <AiFillSetting size={20} color={"var(--main-color-red)"} />,
    },
    {
      title: "Support",
      onClick: () => history.push("/support"),
      icon: <IoIosHelpCircle size={20} color={"var(--main-color-red)"} />,
    },
  ];
  return (
    <ClickAwayListener onClickAway={() => setShow(false)}>
      <div className={`${Style.userDetailsBox}`}>
        <div
          className={Style.userDetails}
          onClick={() => history.push("/view-profile")}
        >
          <div className={Style.profile}>
            <img src={`${imageUrl(userData?.photo)}`} />
          </div>
          <div className={`${Style.editProfile_div}`}>
            <span>Hello,</span>
            <h4>{userData?.userName}</h4>
            <Link to="/view-profile" className={Style.viewProfile}>
              View And Edit Profile
            </Link>
          </div>
        </div>

        <div className={Style.dropdownDivider} />
        <ul>
          {options?.map((item) => {
            return <ListItem item={item} />;
          })}

          {user_type === "mechanic" && (
            <>
              <ListItem
                item={{
                  title: "Certificates & Services",
                  onClick: () =>
                    history.push("/update-services-and-certificates"),
                  icon: (
                    <FaCertificate size={20} color={"var(--main-color-red)"} />
                  ),
                }}
              />
              <ListItem
                item={{
                  title: "Buy Packages",
                  onClick: () => history.push("/buy-packages"),
                  icon: <FaTags size={20} color={"var(--main-color-red)"} />,
                }}
              />
            </>
          )}
          {user_type === "mechanic" && userData?.premium && (
            <ListItem
              item={{
                title: "Vouchers",
                onClick: () => history.push("/mechanic-coupons"),
                icon: (
                  <BsFillEnvelopeOpenFill
                    size={20}
                    color={"var(--main-color-red)"}
                  />
                ),
              }}
            />
          )}

          <ListItem
            item={{
              title: "Logout",
              onClick: () => logout(),
              icon: <IoLogOut size={20} color={"var(--main-color-red)"} />,
            }}
          />
        </ul>
      </div>
    </ClickAwayListener>
  );
}

const DesktopHeader = ({
  logo = LogoWhite,
  backgroundColor,
  containerClass,
  className,
  bgTransparent = false,
  scrollY,
  setModalOpen,
  markAllAsread,
  notificationsUnRead,
  chatUnreadCount,
  notificationsData,
  isLogoBlack,
  logout,
}) => {
  const history = useHistory();
  const path = useLocation()?.pathname;

  // Redux
  const userData = useSelector((state) => state.authReducer?.user);
  const user_type = useSelector((state) => state.authReducer?.user_type);
  const isLogin = useSelector((state) => state.authReducer.isLogin);

  const [showNotificationOverlay, setShowNotificationOverlay] = useState(null);
  const [showProfileOverlay, setShowProfileOverlay] = useState(null);

  // Notification Popup

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className={`${[
        Style.header,
        className && className,
        scrollY && Style.bgWhite,
        !bgTransparent && Style.boxShadow && Style.bgWhite,
      ].join(" ")}`}
      style={{ backgroundColor: backgroundColor }}
      id={"navDesktopHeader"}
    >
      <Container
        className={`${[
          Style.navbarContainer,
          containerClass && containerClass,
        ].join(" ")}`}
      >
        <Navbar.Brand href="/">
          <div className={Style.imageContainer}>
            <img
              src={isLogoBlack ? LogoBlack : scrollY ? LogoBlack : LogoWhite}
              alt="Logo"
            />
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={` ml-4 ${[Style.gapCustm].join(" ")}`} gap={3}>
            <NavLink
              to="/"
              exact
              className={`${[
                Style.nabarLinks,
                bgTransparent && Style.textWhite,
              ].join(" ")}`}
              activeClassName={Style.navActive}
            >
              Home
            </NavLink>
            <NavLink
              exact
              to="/about-us"
              className={`${[
                Style.nabarLinks,
                bgTransparent && Style.textWhite,
              ].join(" ")}`}
              activeClassName={Style.navActive}
            >
              About Us
            </NavLink>
            <NavLink
              exact
              to="/consult"
              className={`${[
                Style.nabarLinks,
                bgTransparent && Style.textWhite,
              ].join(" ")}`}
              activeClassName={Style.navActive}
            >
              Consult
            </NavLink>
            {user_type === "customer" && (
              <NavLink
                exact
                to="/available-mechanics"
                className={`${[
                  Style.nabarLinks,
                  bgTransparent && Style.textWhite,
                ].join(" ")}`}
                activeClassName={Style.navActive}
              >
                Available Mechanics
              </NavLink>
            )}
            <NavLink
              exact
              to="/contact-us"
              className={`${[
                Style.nabarLinks,
                bgTransparent && Style.textWhite,
              ].join(" ")}`}
              activeClassName={Style.navActive}
            >
              Contact Us
            </NavLink>
            <NavLink
              exact
              to="/faq"
              className={`${[
                Style.nabarLinks,
                bgTransparent && Style.textWhite,
              ].join(" ")}`}
              activeClassName={Style.navActive}
            >
              FAQ
            </NavLink>
            <NavLink
              exact
              to="/gallery"
              className={`${[
                Style.nabarLinks,
                bgTransparent && Style.textWhite,
              ].join(" ")}`}
              activeClassName={Style.navActive}
            >
              Gallery
            </NavLink>
          </Nav>
          <Nav className={"ms-auto"}>
            <div className={[Style.rightContent].join(" ")}>
              <ul>
                {isLogin && (
                  <>
                    <li>
                      <div
                        className={`${[
                          Style.iconWithCount,
                          bgTransparent && Style.textWhite,
                        ].join(" ")}`}
                        onClick={() => history.push("/chat")}
                      >
                        <IoChatbubbles
                          size={22}
                          className={`${[
                            bgTransparent ? Style.iconWhite : Style.iconBlack,
                            scrollY && Style.iconBlack,
                          ].join(" ")}`}
                        />
                        {chatUnreadCount > 0 && <p>{chatUnreadCount}</p>}
                      </div>
                    </li>
                    {/* Notifications */}
                    {user_type == "mechanic" && (
                      <li>
                        <OverlayTrigger
                          placement={"bottom"}
                          show={showNotificationOverlay}
                          trigger={["click"]}
                          overlay={
                            <div className={[Style.notifyoverlayDiv]}>
                              <NotificationsPopup
                                data={notificationsData}
                                setShow={setShowNotificationOverlay}
                              />
                            </div>
                          }
                          onToggle={() => {
                            setShowProfileOverlay(false);
                            setShowNotificationOverlay(
                              !showNotificationOverlay
                            );
                            markAllAsread();
                          }}
                        >
                          <div
                            className={`${[
                              Style.iconWithCount,
                              bgTransparent && Style.textWhite,
                            ].join(" ")}`}
                          >
                            <BsFillBellFill
                              size={22}
                              className={`${[
                                bgTransparent
                                  ? Style.iconWhite
                                  : Style.iconBlack,
                                scrollY && Style.iconBlack,
                              ].join(" ")}`}
                            />
                            {notificationsUnRead > 0 && (
                              <p>{notificationsUnRead}</p>
                            )}
                          </div>
                        </OverlayTrigger>
                      </li>
                    )}
                    {/* DropDown */}
                    <li>
                      <OverlayTrigger
                        placement={"bottom"}
                        show={showProfileOverlay}
                        trigger={["click"]}
                        overlay={
                          <div className={[Style.profileOverlayDiv]}>
                            <UserDetailsPopup
                              logout={logout}
                              setShow={setShowProfileOverlay}
                            />
                          </div>
                        }
                        onToggle={() => {
                          setShowNotificationOverlay(false);
                          setShowProfileOverlay(!showProfileOverlay);
                        }}
                      >
                        <div
                          // className={`${Style.Header_Img_Container}`}
                          style={{ display: "flex" }}
                        >
                          <div className={`${Style.headerProfile}`}>
                            <img src={`${imageUrl(userData?.photo)}`} />
                          </div>
                        </div>
                      </OverlayTrigger>
                    </li>
                    {/* DropDown */}
                    {user_type === "mechanic" ? (
                      <li>
                        <Button
                          className={Style.yellowBtn}
                          onClick={() => history.push("/buy-packages")}
                        >
                          Upgrade Plan
                        </Button>
                      </li>
                    ) : (
                      path === "/" && (
                        <li>
                          <a
                            href="#2"
                            className={Style.yellowBtn}
                            onClick={() => console.log(" book now clicked")}
                          >
                            Book Now
                          </a>
                        </li>
                      )
                    )}
                  </>
                )}
                {!isLogin && (
                  <>
                    <li>
                      <Button
                        className={Style.loginBtn}
                        onClick={() => setModalOpen(true)}
                      >
                        Login
                      </Button>
                    </li>
                    <li className="nav-item secondaryBtn">
                      <Button
                        onClick={() => history.push("/join-the-network")}
                        className={Style.yellowBtn}
                      >
                        Join The Network
                      </Button>
                    </li>
                  </>
                )}

                {/* End Extra */}
              </ul>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default DesktopHeader;
