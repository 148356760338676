import React, { useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./CompleteTaskModal.module.css";
import { Row, Col } from 'react-bootstrap';
import REPAIR_DONE from "../../../assets/images/repair-done.jpg";
import { useHistory } from 'react-router-dom';

function CompleteTaskModal({ show, setShow ,onClick , title , text, buttonText,isCompleted}) {
 
  const history = useHistory();
  

  return (
    <ModalSkeleton show={show} setShow={setShow} borderRadius={"40px"}>

      <Row className={`${classes.modal_container}`}>
        <Col md={12} >
         <div className={`${classes.img_div}`}>
         <img src={REPAIR_DONE} alt="img"/>
         </div>
         <div className={`${classes.text_div}`}>
            <h4>{text}</h4>
            <p>{title}</p>
         </div>
       
        </Col>
        {/* <ModalFooter> */}
      <div className={`${classes.done_btn_div}`}>
        <button
          className={`${classes.send_btn} ${classes.c_p}`}
          onClick={()=>{
            onClick();
            setShow(false)
            
          }}
         
        >
          {isCompleted ? "Wait..." : buttonText}
        </button>
      </div>
      </Row>


      
    </ModalSkeleton>
  );
}

export default CompleteTaskModal;
