import React, { useState, useEffect } from "react";
import classes from "./AvailableMechanics.module.css";
import Header from "../../stories/Header";
import HeroSection from "../../stories/HeroSection";
import bgImage from "../../assets/images/Home_banner.png";
import Map from "../../stories/Map/index";
import { Container, Row, Col } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import DropDown from "../../stories/DropDown";
import Button from "../../stories/Button/index";
import Footer from "../../stories/Footer";
import LocationInput from "../../stories/LocationInput/index";
import MechanicCard from "../../stories/MechanicCard/index";
import Aos from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";
import { BaseURL, maxApiDataLength } from "../../Config/apiUrl";
import { Get } from "../../Axios/AxiosFunctions";
import NoData from "../../stories/NoData/NoData";

function AvailableMechanics() {
  const { accessToken } = useSelector((state) => state.authReducer);
  const { certificatesArray, servicesArray, defaultCoords } = useSelector(
    (state) => state.commonReducer
  );
  const [place, setPlace] = useState(null);
  const [myCoords, setMyCoords] = useState(defaultCoords);

  const [machanicData, setMachanicData] = useState([]);
  const [selectedCertificates, setSelectedCertificates] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [getMechanicPageNumber, setGetMechanicPageNumber] = useState(1);
  const [getMechanicLoading, setGetMechanicLoading] = useState(false);

  const title = "SEARCH, FIND & GET WORK DONE";
  const description =
    "You can use the search bar below to find a list of Local, Honest, Reputable Mobile Mechanics in your area. Please type in your City, State and Zip Code.";

  const RenderSkeletons = () => {
    return [1, 2, 3, 4, 5, 6].map((item, i) => {
      return (
        <Col lg={4} md={6} sm={12}>
          <div className="SkeletonContainer"></div>
        </Col>
      );
    });
  };

  const getMechanicsNearMe = async (pageNo, oldArray) => {
    const filterArray = [];
    selectedCertificates?.map((item) => {
      filterArray.push(item?._id);
    });
    selectedServices?.map((item) => {
      filterArray.push(item?._id);
    });

    let nearestMechanicApiUrl = BaseURL(
      `customer/mechanics-near-me?latlng=${myCoords?.lat},${
        myCoords?.lng
      }&limit=${maxApiDataLength}&page=${pageNo}&types=${JSON.stringify(
        filterArray
      )}`
    );
    setGetMechanicLoading(true);
    let responseData = await Get(nearestMechanicApiUrl, accessToken);
    if (responseData !== undefined) {
      setGetMechanicPageNumber((pre) => pre + 1);
      let mechanicArray = oldArray.slice();
      mechanicArray = mechanicArray.concat(responseData?.data?.data);
      setMachanicData(mechanicArray);
    }
    setGetMechanicLoading(false);
  };

  const resetGetMechanicData = () => {
    setSelectedCertificates([]);
    setSelectedServices([]);
    setGetMechanicPageNumber(1);
    setMachanicData([]);
    getMechanicsNearMe(1, []);
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
    getMechanicsNearMe(1, []);
  }, []);

  return (
    <div className={classes.page}>
      {/* <Header /> */}
      <div className={classes.header}>
        <Header
          bgTransparent={true}
          containerClass={`${[classes.container].join(" ")}`}
        />
      </div>

      <HeroSection
        BgImg={bgImage}
        alt="img"
        title={title}
        description={description}
        sectionClass={classes.heroSection}
      >
        {/* Location Input */}
        <Row className={`${classes.searchBar_div}`}>
          <Col
            md={8}
            xl={9}
            sm={9}
            xs={12}
            className={`${classes.loc_input_home}`}
          >
            <LocationInput
              setPlace={setPlace}
              placeholder={"Location*"}
              setCordinate={setMyCoords}
            />
          </Col>
          <Col md={4} xl={3} sm={3} xs={12}>
            <Button
              label={"Search"}
              className={`${classes.searchBtn}`}
              onClick={() => {
                if (place !== null) {
                  setMachanicData([]);
                  setGetMechanicPageNumber(1);
                  getMechanicsNearMe(1, []);
                }
              }}
            />
          </Col>
        </Row>

        {/* Location Input */}
      </HeroSection>

      {/* <Map /> */}
      <div className={`${classes.Map_section}`}>
        <Container className={`${classes.container}`}>
          <Row>
            <Col lg={12}>
              <Map
                locations={machanicData}
                myCoords={myCoords}
                mapContainerClass={classes.map_container}
              />
            </Col>
          </Row>
        </Container>
      </div>

      {/* <Map /> */}

      {/* Available in your Area Section */}

      <section>
        <Container className={`${classes.container}`}>
          <Row>
            <Col md={12}>
              <div className={`${classes.available_mechanics}`}>
                <p>Available in your Area</p>
                <h1>MOBILE MECHANICS</h1>
              </div>
            </Col>
            <Col md={12}>
              <div className={`${classes.fliter_div}`}>
                <h2>Filter</h2>
                <div>
                  <FaFilter className={`${classes.filterIcon}`} />
                </div>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className={`${classes.mech_filter}`}>
                <h5>Mechanic Certifications </h5>
                <DropDown
                  placeholder={"Certificates"}
                  isMulti
                  options={certificatesArray}
                  optionLabel={"CertificateType"}
                  optionValue={"CertificateType"}
                  setter={setSelectedCertificates}
                  value={selectedCertificates}
                />
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className={`${classes.mech_filter}`}>
                <h5>Mechanic Services </h5>
                <DropDown
                  placeholder={"services"}
                  isMulti
                  options={servicesArray}
                  optionLabel={"serviceType"}
                  optionValue={"serviceType"}
                  setter={setSelectedServices}
                  value={selectedServices}
                />
              </div>
            </Col>
            <Col md={12}>
              <div className={`${classes.filterBtn_div}`}>
                <Button
                  label={`Apply Filters`}
                  className={`${classes.red_btn} ${classes.filter_btn}`}
                  onClick={() => {
                    setMachanicData([]);
                    setGetMechanicPageNumber(1);
                    getMechanicsNearMe(1, []);
                  }}
                />
                <Button
                  label={`Clear Filters`}
                  className={`${classes.white_btn} ${classes.filter_btn}`}
                  onClick={() => {
                    resetGetMechanicData();
                  }}
                />
              </div>
            </Col>
          </Row>

          <Row className={`${classes.machanicCard_row}`}>
            {getMechanicLoading ? (
              <RenderSkeletons />
            ) : (
              machanicData?.map((item, index) => {
                return (
                  <Col lg={4} md={6} sm={12}>
                    <MechanicCard item={item} />
                  </Col>
                );
              })
            )}

            {machanicData.length == 0 && getMechanicLoading == false && (
              <NoData
                className={classes.NoDataContainer}
                text={"Sorry, no mechanics found in this area."}
              />
            )}
          </Row>
          {maxApiDataLength * (getMechanicPageNumber - 1) ==
            machanicData.length && (
            <Col>
              <Button
                label={getMechanicLoading ? "loading..." : "Load More"}
                disabled={getMechanicLoading}
                onClick={() => {
                  getMechanicLoading == false &&
                    getMechanicsNearMe(getMechanicPageNumber, machanicData);
                }}
                className={`${classes.loadMoreBtn}`}
              />
            </Col>
          )}

          {/* <Row className={`${classes.machanicCard_row}`}>
            {machanicData?.map((item, index) => {
              return (
                <Col lg={4} md={6} sm={12}>
                  <MechanicCard item={item} />
                </Col>
              );
            })}
          </Row> */}
        </Container>
      </section>

      <section className={`${classes.Footer_Section}`}>
        <Footer containerClass={classes.container} />
      </section>

      {/* Footer section */}
    </div>
  );
}

export default AvailableMechanics;
