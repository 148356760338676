import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import classes from "./Accordion.module.css";

function CustomAccordion({ data }) {
  const [isOpen, setIsOpen] = useState();
  return (
    <div>
      <style>{`
                .accordion-button:not(.collapsed),.accordion-button:focus {
                    box-shadow:unset !important;
                    
                }
                `}</style>
      {data?.map((item, i) => (
        <Accordion
          className={classes.accordion}
          activeKey={isOpen}
          onClick={() => setIsOpen(i)}
          key={i}
        >
          <Accordion.Item eventKey={i}>
            <Accordion.Header>
              <p className={classes.heading}>{item?.question}</p>
            </Accordion.Header>
            <Accordion.Body className={classes.body}>
              {item?.answer}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ))}
    </div>
  );
}

export default CustomAccordion;
