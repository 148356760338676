import React from "react";
import classes from "./StripeCard.module.css";
import Button from "../../../stories/Button";
import { ImCross } from "react-icons/im";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { MdOutlineCancel } from "react-icons/md";

const StripeCard = ({ handleAttach, setIsCard, loading }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else {
      console.log("[PaymentMethod]", paymentMethod);
      await handleAttach(paymentMethod?.id);
      setIsCard(false);
    }
  };
  return (
    <div>
      <div className={[classes.AddCardDiv]}>
        <h3>Add New Card </h3>
        <MdOutlineCancel
          size={25}
          color={"#000"}
          onClick={() => setIsCard(false)}
        />
      </div>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div
          style={{
            borderBottom: "2px solid  rgba(0, 0, 0, 0.1)",
            padding: "10px",
          }}
        >
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
              hidePostalCode: true,
            }}
          />
        </div>
        <div className={[classes.confirmBtnDiv]}>
          <Button
            label={loading ? "Adding..." : "CONFIRM"}
            type="submit"
            className={[classes.confirmBtn]}
            disabled={loading}
          />
        </div>
      </form>
    </div>
  );
};

export default StripeCard;
