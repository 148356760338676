import React, { useEffect, useState } from "react";
import classes from "./mobileFooter.module.css";
import { Col, Container, Row } from "react-bootstrap";
import {
  download01,
  download02,
  logofooterimg,
} from "../../constant/imagePath";
import { Link, useHistory } from "react-router-dom";
import { imageUrl } from "../../Config/apiUrl";
import { useSelector } from "react-redux";

const MobileFooter = ({ dataList, containerClass }) => {
  const history = useHistory();
  const pagesArray = [
    { title: "Home", link: "/" },
    { title: "About Us", link: "/about-us" },
    { title: "Consult", link: "/consult" },
    { title: "FAQ", link: "/faq" },
    { title: "Privacy Policy", link: "/privacy-policy" },
    { title: "Contact Us", link: "/contact-us" },
  ];
  const isLogin = useSelector((state) => state.authReducer.isLogin);
  const user_type = useSelector((state) => state.authReducer.user_type);

  return (
    <div className={classes.footer}>
      <Container fluid className={containerClass && containerClass}>
        <Row className={[classes.innerBox, "gx-0"].join(" ")}>
          <Col md={12}>
            <div className={classes.logo} onClick={() => history.push("/")}>
              <img src={logofooterimg} />
            </div>
          </Col>
          <Col
            sm={12}
            className={classes.borderBottom}
            style={{
              marginTop: "30px",
              marginBottom: "22px",
            }}
          />
          <Col md={12}>
            <ul className={classes.pagesArray}>
              {pagesArray?.map((item) => {
                return (
                  <li>
                    <Link to={item?.link} className={classes.pageLink}>
                      {item?.title}
                    </Link>
                  </li>
                );
              })}
              {user_type === "customer" && (
                <li>
                  {" "}
                  <Link className={classes.pageLink} to="/available-mechanics">
                    {"Available Mechanics"}
                  </Link>{" "}
                </li>
              )}

              {!isLogin && (
                <li>
                  <Link className={classes.pageLink} to="/join-the-network">
                    {"Join The Network"}
                  </Link>
                </li>
              )}
            </ul>
          </Col>
          <Col md={12} className={classes.platformIcons}>
            <a
              href="https://play.google.com/store/apps/details?id=com.mobilemechanichub&hl=en&gl=US"
              target="_blank"
              className={classes.platformIcon}
            >
              <img
                src={download02}
                className="img-fluid  mw-100"
                alt="img_not_found"
              />
            </a>
            <a
              href="https://apps.apple.com/us/app/mobile-mechanic-hub/id1599293218"
              target="_blank"
              className={classes.platformIcon}
            >
              <img
                src={download01}
                className="img-fluid  mw-50"
                alt="img_not_found"
              />
            </a>
          </Col>
          <Col sm={12} className={classes.socialMedia}>
            <span>Social media:</span>
            <ul className={classes.socialIcons}>
              {dataList?.map((ele) => {
                return (
                  <li>
                    <a href={ele?.link} target="_blank">
                      <img
                        style={{ width: "25px", height: "auto" }}
                        src={imageUrl(ele?.icon)}
                      />
                    </a>
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col sm={12} className={classes.borderBottom} />
        </Row>
        <div className={classes.lastLine}>
          <p>&copy; 2021 Mobile Mechanic Hub</p>
          <div>
            <span onClick={() => history.push("/privacy-policy")}>Privacy</span>
            <span className={classes.break} />
            <span onClick={() => history.push("/terms-and-conditions")}>
              Terms
            </span>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MobileFooter;
