import "./App.css";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Demo from "./Pages/Demo";
import { Fragment, useEffect } from "react";
import { Provider, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

import "./assets/style.css";
import "react-modern-drawer/dist/index.css";

import "react-modern-drawer/dist/index.css";
import HomePage from "./Pages/HomePage";
import AboutPage from "./Pages/AboutPage";
import ContactUs from "./Pages/ContactUsPage";
import Consult from "./Pages/Consult";
import FAQ from "./Pages/Faq";
import JoinTheNetwork from "./Pages/JoinTheNetwork";
import ChangePassword from "./Pages/ChangePassword";
import UserSetting from "./Pages/UserSetting";
import RecentBooking from "./Pages/RecentBooking";

import MechanicPackages from "./Pages/MechanicPackages";
import TermsAndConditions from "./Pages/TermsAndConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import RegistrationForm from "./Pages/RegistrationForm";
import UserSupportPage from "./Pages/UserSupportPage";
import AvailableMechanics from "./Pages/AvailableMechanics";
import ProfilePage from "./Pages/ProfilePage";
import UserBookingDetail from "./Pages/UserBookingDetail";
import NotificationPage from "./Pages/Notification/Notification";
import ViewBooking from "./Pages/ViewBooking";
import {
  defaultCoords,
  setCertificatesAndServices,
  setFcmToken,
} from "./store/Actions/authAction";
import { BaseURL, firebaseVapidObject } from "./Config/apiUrl";
import { Get } from "./Axios/AxiosFunctions";
import ViewMechanicProfile from "./Pages/ViewMechanicProfile";

import MechanicCoupons from "./Pages/MechanicCoupons";
import UpdateServiceAndCertificate from "./Pages/UpdateServicesAndCertificate";
import Chat from "./Pages/Chat";
import firebase, { messaging } from "./firebase";
import ProtectedRoute from "./Helper/ProtectedRoute";
import Gallery from "./Pages/Gallery";
import UnClaimedBusiness from "./Pages/UnClaimedBusiness";
import UnclaimedBusinessListing from "./Pages/UnclaimedBusinessListing";
import CreateProfile from "./Pages/CreateProfile";

function App() {
  const dispatch = useDispatch();

  const getCertificatesAndServicesArray = async () => {
    const filterDataURL = BaseURL(`filter-data`);
    const resData = await Get(filterDataURL);

    if (resData !== undefined) {
      dispatch(setCertificatesAndServices(resData?.data?.data));
    }
  };

  const getToken = async () => {
    let token = "";
    if (
      Notification.permission == "granted" &&
      firebase.messaging.isSupported()
    ) {
      token = await messaging.getToken(firebaseVapidObject);
      dispatch(setFcmToken(token));
    }
  };
  // get live location and Save Data To Redux
  useEffect(() => {
    //If denied then you have to show instructions to enable location Defualt USA COORDS:
    var lat = 40.73061;
    var lng = -73.935242;
    if (navigator?.permissions !== undefined && navigator?.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(async function (result) {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(async (success) => {
              lat = success?.coords?.latitude;
              lng = success?.coords?.longitude;
              dispatch(defaultCoords({ lat, lng }));
              return;
            });
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition(async (success) => {
              lat = success?.coords?.latitude;
              lng = success?.coords?.longitude;
            });
            dispatch(defaultCoords({ lat, lng }));
            return;
          }
          dispatch(defaultCoords({ lat, lng }));
        });
    }
    getCertificatesAndServicesArray();
    getToken();
  }, []);

  return (
    <>
      <ToastContainer />
      <Router>
        <Fragment>
          {/* All Time Access Screens Start */}
          <Route path="/" exact component={HomePage} />

          {/* All Time Access Screens End */}

          {/* without Login Screens Start */}
          <Route path="/about-us" exact component={AboutPage} />
          <Route path="/consult" exact component={Consult} />
          <Route path="/faq" exact component={FAQ} />
          <Route path="/contact-us" exact component={ContactUs} />
          <Route
            path="/terms-and-conditions"
            exact
            component={TermsAndConditions}
          />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/join-the-network" exact component={JoinTheNetwork} />
          <Route path="/registration-form" exact component={RegistrationForm} />

          {/* without Login Screens End */}

          {/* After Login Screens Start */}
          <Route
            path="/available-mechanics"
            exact
            component={AvailableMechanics}
          />
          <Route path="/support" exact component={UserSupportPage} />
          {/* <ProtectedRoute path="/change-password" component={ChangePassword} /> */}
          <Route path="/change-password" exact component={ChangePassword} />
          <Route path="/edit-profile" exact component={UserSetting} />
          <Route path="/bookings" exact component={RecentBooking} />
          <Route path="/notifications" exact component={NotificationPage} />
          <Route
            path="/user-booking-detail/:id"
            exact
            component={UserBookingDetail}
          />
          <Route path="/buy-packages" exact component={MechanicPackages} />
          <Route path="/view-profile" exact component={ProfilePage} />
          {/* For Customer */}
          <Route
            path="/view-mechanic-profile/:id"
            exact
            component={ViewMechanicProfile}
          />

          <Route path="/mechanic-coupons" exact component={MechanicCoupons} />
          {/* View Booking */}
          <Route path="/view-booking/:id" exact component={ViewBooking} />

          <Route
            path="/update-services-and-certificates"
            exact
            component={UpdateServiceAndCertificate}
          />

          <Route path="/chat" exact component={Chat} />
          {/* New Pages */}
          <Route path="/gallery" exact component={Gallery} />
          <Route
            path="/unclaimed-business/:id"
            exact
            component={UnClaimedBusiness}
          />
          {/* <Route
            path="/unclaimed-business"
            exact
            component={UnclaimedBusinessListing}
          /> */}
          <Route path="/create-profile/:id" exact component={CreateProfile} />

          {/* After Login Screens End */}

          {/* Customer Bookings */}

          {/* <Route path="/AboutUs" component={AboutUs}></Route>
            <Route path="/" exact component={HomePage} />
            <Route path="/ContactUs" component={ContactUs} />
            <Route path="/AvailableMechanics" component={AvailableMechanics} />
            <Route path="/JoinTheNetwork" component={JoinTheNetwork} />
            <Route path="/RegistrationForm" component={RegistrationForm} />
            <Route path="/MessageScreen" component={MessageScreen} />
            <Route
              path="/UpdateServicesAndCertif"
              component={UpdateServicesAndCertif}
            />
            <Route
              path="/ViewMechanicProfile"
              component={ViewMechanicProfile}
            />

            <Route
              path="/MechanicProfilePage"
              component={MechanicProfilePage}
            />
            <Route path="/MechanicPackages" component={MechanicPackages} />
            <Route path="/UserBookingDetail" component={UserBookingDetail} />
            <Route path="/UserSupportPage" component={UserSupportPage} />
            <Route path="/UserSetting" component={UserSetting} />
            <Route path="/changePassword" component={ChangePassword} />
            <Route path="/MechanicCoupons" component={MechanicCoupons} />
            <Route
              path="/UserRecentBooking"
              component={UserRecentBooking}
              headerBg={"white"}
            />
            <Route
              path="/ViewCurrentBooking"
              component={ViewCurrentBooking}
              headerBg={"white"}
            />
            <Route path="/FAQ" component={FAQ} headerBg={"white"} />
            <Route
              path="/TermsAndConditions"
              component={TermsAndConditions}
              headerBg={"white"}
            />
            <Route
              path="/PrivacyPolicy"
              component={PrivacyPolicy}
              headerBg={"white"}
            />
            <Route path="/Demo" component={Demo} />
            <Route path="/ramiz" component={Ramiz} /> */}
        </Fragment>
      </Router>
    </>
  );
}

export default App;
