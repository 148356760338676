import React, { useState } from "react";
import classes from "./MechanicCard.module.css";
import { MdLocationPin } from "react-icons/md";
import Button from "../Button";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RatingBG, VERIFIED } from "../../constant/imagePath";
import { imageUrl } from "../../Config/apiUrl";
import StarRatings from "react-star-ratings";
import LoginOrSignUpModal from "../../Components/Modals/LoginOrSignUp";

function MechanicCard({ item }) {
  const isLogin = useSelector((state) => state.authReducer.isLogin);
  const history = useHistory();

  // For Login Modal
  const [showLoginModal, setShowLoginModal] = useState(false)


  return (
    <div className={classes.card}>
      <div className={classes.header}>
        {/* Verified Icon */}
        {item?.isMMHVerified && (
          <img className={classes.verifiedIcon} src={VERIFIED} />
        )}
        <div className={classes.ratingAbsolute}>
          <div className={classes.imgDiv}>
            <img src={RatingBG} />
            <div className={classes.rating}>
              <StarRatings
                starDimension={15}
                numberOfStars={1}
                rating={4}
                starEmptyColor={"var(--black-color"}
                starRatedColor={"var(--main-color-red)"}
              />
              <span>{item?.ratingsAverage}</span>
            </div>
          </div>
        </div>
        <div className={classes.image}>
          <img src={imageUrl(item?.photo)} />
        </div>
      </div>
      <div className={classes.desc}>
        <h4>{item?.userName}</h4>

        {/* Location */}
        <div className={classes.location}>
          <MdLocationPin className={classes.icon} />
          <span>{item?.address}</span>
        </div>
        {/* Btn */}
        <div className={classes.btnDiv}>
          {isLogin ? (
            <Button
              className={classes.btn}
              label={"View Details"}
              onClick={() =>
                // history.push(`/ViewMechanicProfile?id=${item?._id}`)
                history.push(`/view-mechanic-profile/${item?._id}`)
              }
            />
          ) : (
            <Button
              className={classes.btn}
              label={"Contact Mechanic"}
              onClick={() => setShowLoginModal(true)}
            />
          )}
        </div>
      </div>
      {showLoginModal && <LoginOrSignUpModal show={showLoginModal} setShow={setShowLoginModal} />}
    </div>
  );
}

export default MechanicCard;
