import React from "react";
import classes from "./InputWithButton.module.css";
import InputClasses from "../Input/input.module.css";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

const InputWithButton = ({
  mainContainerClass,
  leftIcon,
  inputClass,
  value,
  setValue,
  placeholder = "Message...",
  disabled = false,
  btnClass,
  btnChildern,
  onClick,
}) => {
  return (
    <div
      className={[
        classes.MainContainer,
        mainContainerClass && mainContainerClass,
      ].join(" ")}
    >
      <style jsx>{``}</style>
      {leftIcon && <div className={classes.search_icon}>{leftIcon}</div>}
      <input
        value={value}
        placeholder={placeholder}
        type={"text"}
        onChange={(e) => setValue(e.target.value)}
        className={[classes.inputtxt, inputClass && inputClass].join(" ")}
        disabled={disabled}
        onKeyPress={(e) => {
          if (e.target.value !== '') {
            ["Enter", "NumpadEnter"].includes(e.code) && onClick()
          }
        }

        }
      />
      {btnChildern && (
        <Button
          className={[classes.btnContainer, btnClass && btnClass].join(" ")}
          disabled={disabled}
          onClick={onClick}
        >
          {btnChildern}
        </Button>
      )}
    </div>
  );
};
InputWithButton.propTypes = {
  mainContainerClass: PropTypes.string,
  leftIcon: PropTypes.element,
  inputClass: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  btnClass: PropTypes.string,
  btnChildern: PropTypes.element,
  onClick: PropTypes.func,
};

export default InputWithButton;
