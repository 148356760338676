import React, { useState, useEffect } from "react";
import classes from "./ContactUs.module.css";
import HeroSection from "./../../stories/HeroSection";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "./../../stories/Footer";
import { apiHeader, BaseURL, imageUrl } from "../../Config/apiUrl";
import Aos from "aos";
import "aos/dist/aos.css";
import { FiMail } from "react-icons/fi";
import Input from "./../../stories/Input/index";
import TextArea from "./../../stories/TextArea/index";
import Button from "./../../stories/Button/index";
import Header from "./../../stories/Header/index";
import { Get, Post } from "../../Axios/AxiosFunctions";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function ContactUsPage() {
  const userData = useSelector((state) => state.authReducer?.user);
  const userName = userData?.userName?.split(" ");

  const [firstName, setFirstName] = useState(
    userData?.userName !== undefined ? userName[0] : ""
  );
  const [lastName, setLastName] = useState(
    userData?.userName !== undefined && userName.length > 1 ? userName[1] : ""
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [blurLoader, setBlurLoader] = useState(false);
  const [dataList, setDataList] = useState([]);

  const [loading, setLoading] = useState(false);

  const cmsDataApiUrl = BaseURL(
    "pages?pages=contact_us&goals=false&service=false&aboutusItem=false&faq=false&all=false"
  );

  const submitContactForm = async () => {
    const contactUrl = BaseURL("contact-us");
    let params = {
      firstName,
      lastName,
      email,
      phoneNo: phoneNumber,
      message,
      formType: "contactus",
    };
    for (let key in params) {
      if (params[key] == "") {
        return toast.info(`Please enter required fields.`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    setLoading(true);
    const responseData = await Post(contactUrl, params, apiHeader());
    if (responseData !== undefined) {
      toast.success(`Form Successfully Submitted.`, {
        position: toast.POSITION.TOP_CENTER,
      });
      setEmail("");
      setLastName("");
      setFirstName("");
      setPhoneNumber("");
      setMessage("");
    }
    setLoading(false);
  };

  const getContactData = async () => {
    setBlurLoader(true);
    const responseData = await Get(cmsDataApiUrl);
    if (responseData !== undefined) {
      setDataList(responseData.data.data);
    } else {
      setDataList([]);
    }
    setBlurLoader(false);
  };

  useEffect(() => {
    getContactData();
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={[classes.page, blurLoader && "pageBlur"].join(" ")}>
      {/* <Header /> */}
      <div className={classes.header}>
        <Header
          bgTransparent={true}
          containerClass={`${[classes.container].join(" ")}`}
        />
        {/* <Header
          bgTransparent={true}
          containerClass={`${[classes.custom_Container].join(" ")}`}
        /> */}
      </div>
      <HeroSection
        BgImg={imageUrl(dataList?.pages?.cover_image)}
        alt="img"
        title={dataList?.pages?.heading}
        description={dataList?.pages?.detail_description}
        sectionClass={classes.contactSection}
      ></HeroSection>

      {/* About us section */}

      <section className={`${classes.aboutUs_Section}`}>
        <Container className={`${classes.custom_Container}`}>
          <Row>
            <Col md={12}>
              <div className={`${classes.aboutUs_Section_div}`}>
                <h1 className={`${classes.lower_text_heading}`}>
                  GET IN TOUCH <span>CONTACT US</span>
                </h1>
              </div>
            </Col>
            <Col md={12}>
              <div className={`${classes.email_card_container}`}>
                <div data-aos="fade-up" className={`${classes.email_card_div}`}>
                  <div className={`${classes.email_card_img_div}`}>
                    <FiMail className={`${classes.email_card_icon}`} />
                  </div>
                  <div className={`${classes.email_card_text}`}>
                    <h5>Email</h5>
                    <p>MobileMechanicHub@gmail.com</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={`${classes.contactus_Section}`}>
        <Container className={`${classes.custom_Container_form}`}>
          <Row>
            <Col md={12}>
              <div
                data-aos="fade-down"
                className={`${classes.contactus_Section_div}`}
              >
                <p>Contact Us</p>
                <h1 className={`${classes.lower_text_heading}`}>
                  PLEASE FILL OUT THE FORM
                </h1>
              </div>
            </Col>
            <Col md={12}>
              <Row data-aos="fade-up" className={`${classes.form_div_row}`}>
                <Col md={6} sm={12}>
                  <Input
                    placeholder={"First Name"}
                    type={"text"}
                    setter={setFirstName}
                    value={firstName}
                  />
                </Col>
                <Col md={6} sm={12}>
                  <Input
                    placeholder={"Last Name"}
                    type={"text"}
                    setter={setLastName}
                    value={lastName}
                  />
                </Col>
                <Col md={6} sm={12}>
                  <Input
                    placeholder={"Phone Number"}
                    type={"number"}
                    setter={setPhoneNumber}
                    value={phoneNumber}
                  />
                </Col>
                <Col md={6} sm={12}>
                  <Input
                    placeholder={"Email Address"}
                    type={"email"}
                    setter={setEmail}
                    value={email}
                  />
                </Col>
                <Col md={12}>
                  <TextArea
                    placeholder={
                      "Please Let Us Know How We Can Be Of Assistance.If you're looking for a repair,please serach for a mechanic in your local area prior to submitting a form.This will get you some assistance faster!."
                    }
                    type={"text"}
                    setter={setMessage}
                    value={message}
                    rows={5}
                  />
                </Col>

                <Col md={12}>
                  <div className={`${classes.btn_div}`}>
                    <Button
                      label={loading ? "Submitting..." : "Submit"}
                      disabled={loading}
                      className={classes.submit_btn}
                      onClick={submitContactForm}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      {/* About us section */}

      {/* Footer section */}

      <section className={`${classes.Footer_Section}`}>
        <Footer containerClass={classes.custom_Container} />
      </section>

      {/* Footer section */}
    </div>
  );
}

export default ContactUsPage;
