import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CustomAccordion from "../../stories/Accordion";
import Footer from "../../stories/Footer";
import Header from "../../stories/Header";
import HeroSection from "../../stories/HeroSection";
import classes from "./Faq.module.css";
import Aos from "aos";
import { BaseURL, imageUrl } from "../../Config/apiUrl";
import { Get } from "../../Axios/AxiosFunctions";

function FAQ() {
  const [blurLoader, setBlurLoader] = useState(false);
  const [dataList, setDataList] = useState([]);

  const cmsDataApiUrl = BaseURL(
    "pages?pages=faq&goals=true&service=true&aboutusItem=true&faq=true"
  );

  const getFaqData = async () => {
    setBlurLoader(true);
    const responseData = await Get(cmsDataApiUrl);
    if (responseData !== undefined) {
      setDataList(responseData.data.data);
    } else {
      setDataList([]);
    }
    setBlurLoader(false);
  };

  useEffect(() => {
    getFaqData();
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={[classes.page, blurLoader && "pageBlur"].join(" ")}>
      <div className={classes.header}>
        <Header
          bgTransparent={true}
          containerClass={`${[classes.container].join(" ")}`}
        />
      </div>
      <HeroSection
        BgImg={imageUrl(dataList?.pages?.cover_image)}
        title={dataList?.pages?.heading}
        description={dataList?.pages?.detail_description}
        sectionClass={classes.faqSection}
      />

      <section className={classes.faqSection}>
        <Container className={classes.container}>
          <Row>
            <Col md={12} data-aos="fade-up">
              <h5 className={classes.heading1}>Answer of Common Question</h5>
              <h1 className={classes.heading2}>
                FREQUENTLY ASKED QUESTION <span>FAQ</span>
              </h1>
            </Col>
            <Col md={12}>
              <CustomAccordion data={dataList?.faq} />
            </Col>
          </Row>
        </Container>
      </section>

      <Footer containerClass={classes.container} />
    </div>
  );
}

export default FAQ;
