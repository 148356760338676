import React, { useState } from "react";
import classes from "./voucherCard.module.css";
import { Row, Col } from "react-bootstrap";
import moment from "moment";

// {
//   showMore
//     ? ele?.description
//     : ele?.description.substring(0, 45)
// }
// </p >
// {!showMore
//   ? ele?.description?.length > 45 && (
//     <p
//       className="show-more"
//       onClick={() => setShowMore(true)}
//     >
//       {" "}
//       show more
//     </p>
//   )
//   : ele?.description?.length > 45 && (
//     <p
//       className="show-more"
//       onClick={() => setShowMore(false)}
//     >
//       {" "}
//       show less
//       </p>
//     )}

const VoucherCard = ({ data }) => {
  const [showMore, setShowMore] = useState(false)
  return (
    <div className={classes.card}>
      <Row className={classes.row} >
        <Col md={4} xs={12}>
          <img
            className={classes.voucherImg}
            src={
              "http://t0.gstatic.com/licensed-image?q=tbn:ANd9GcRODAepevJESvKmoYWQ8YAJJQO0j_a_ZgVaTFAobP3lXgETKX-kTOowNQH9_zxyUGC-aLS53LuzzNt0qys8gO4"
            }
          />
        </Col>
        {/* For Data */}
        <Col md={8} className={classes.pTB20}>
          <Row className="g-0">
            <Col
              md={6}
              xs={12}
              className={classes.pLR20}
            >
              <Row className="g-0">
                <Col md={12}>
                  <div className={classes.dFlex}>
                    <p className={classes.title}>
                      Voucher:
                    </p>
                    <p className={classes.infoDetail}>{data?.title}</p>
                  </div>
                </Col>
                <Col md={12}>
                  <div className={classes.dFlex}>
                    <p className={classes.title}>
                      Web Link:
                    </p>
                    <p className={classes.infoDetail}>{data?.web_link}</p>
                  </div>
                </Col>
                <Col md={12}>
                  <div className={classes.dFlex}>
                    <p className={classes.title}>
                      Code:
                    </p>
                    <p className={classes.infoDetail}>{data?.code}</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              md={6}
              xs={12}
              className={classes.pLR20}
            >
              <Row className="g-0">
                <Col md={12}>
                  <div className={classes.dFlex}>
                    <p className={classes.title}>
                      Discount:
                    </p>
                    <p className={classes.infoDetail}>{data?.discount}</p>
                  </div>
                </Col>
                <Col md={12}>
                  <div className={classes.dFlex}>
                    <p className={classes.title}>
                      Voucher Start:
                    </p>
                    <p className={classes.infoDetail}>
                      {moment(data?.voucher_start).format("MMM/DD/YYYY")}
                    </p>
                  </div>
                </Col>
                <Col md={12}>
                  <div className={classes.dFlex}>
                    <p className={classes.title}>
                      Voucher Expiry:
                    </p>
                    <p className={classes.infoDetail}>
                      {moment(data?.voucher_end).format("MMM/DD/YYYY")}
                    </p>
                  </div>

                </Col>
              </Row>
            </Col>
            {/* Description */}
            <Col md={12}
              className={classes.pLR20}

            >
              <div className={classes.dFlex}>
                <p className={classes.title}>
                  Description:
                </p>
                <p className={classes.infoDetail}>{
                  showMore
                    ? data?.description
                    : data?.description.substring(0, 45)}
                  <>
                    {!showMore
                      ? data?.description?.length > 45 && (
                        <p
                          className={classes.textBlack}
                          onClick={() => setShowMore(true)}
                        >
                          {" "}
                          show more
                        </p>
                      )
                      : data?.description?.length > 45 && (
                        <p
                          className={classes.textBlack}
                          onClick={() => setShowMore(false)}
                        >
                          {" "}
                          show less
                        </p>
                      )
                    }

                  </>
                </p>
              </div>
            </Col>
          </Row>
        </Col>

      </Row>
    </div>
  );
};

export default VoucherCard;
