import Aos from "aos";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Footer from "../../stories/Footer";
import Header from "../../stories/Header";
import { ProfileWithEditButton } from "../../stories/ProfileWithEditButton";
import { FaSnapchat } from "react-icons/fa";
import { MdLocationPin, MdDescription, MdEmail } from "react-icons/md";
import { BsFacebook, BsLinkedin } from "react-icons/bs";
import { GrInstagram } from "react-icons/gr";
import { ImGooglePlus3 } from "react-icons/im";
import { useHistory, useParams } from "react-router-dom";
import classes from "./UnClaimedBusiness.module.css";
import Button from "../../stories/Button";
import { apiHeader, BaseURL } from "../../Config/apiUrl";
import { Get, Patch } from "../../Axios/AxiosFunctions";
import ClaimModal from "../../Components/Modals/ClaimedModal";
import Loader from "../../stories/Loader";

function UnClaimedBusiness() {
  const history = useHistory();
  const id = useParams()?.id;

  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(true);
  const [verifyLoading, setVerifyLoading] = useState(false);

  const fetchData = async () => {
    const url = BaseURL(`customer/unclaimed-business-user/${id}`);
    setLoading(true);
    const response = await Get(url);
    if (response !== undefined) {
      setUser(response?.data?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const verifyCode = async (data) => {
    const params = {
      ...data,
      email: user?.UserId?.email,
    };
    const url = BaseURL("verify/unclaim");
    setVerifyLoading(true);
    const response = await Patch(url, params, apiHeader());
    if (response !== undefined) {
      setShowModal(false);
    }
    setVerifyLoading(false);
  };

  return (
    <div>
      <div className={classes.header}>
        <Header
          bgTransparent={false}
          isLogoBlack={true}
          containerClass={`${[classes.container].join(" ")}`}
        />
      </div>
      {loading ? (
        <div style={{ height: "calc(100vh - 459px)" }}>
          <Loader />
        </div>
      ) : (
        <div className={`${classes.bodyContainer} `}>
          <div
            className={`${classes.userImagesContainer}`}
            style={{
              height: 400,
            }}
          >
            <ProfileWithEditButton
              updateImage={user?.cover_image}
              className={classes.coverImage}
              onErrorImage={true}
              classImg={classes.coverImageTagClass}
              edit={false}
            />

            <div className={`${classes.profileImageContainer}`}>
              <ProfileWithEditButton updateImage={user?.photo} edit={false} />
            </div>
          </div>
          <div
            className={`${classes.userDetailContainer}`}
            style={{
              marginTop: 50,
            }}
          >
            <div className={classes.centerItems}>
              <p className={`${classes.userNameText}`}>{user?.userName}</p>
              <>
                <Row className={`g-0`}>
                  <Col md={12} className={classes.SocialLinkContainer}>
                    {user?.snapchatLink && (
                      <FaSnapchat
                        size={24}
                        color={"var(--main-color-red)"}
                        onClick={() => {
                          window.open(
                            `https://${user?.snapchatLink}`,
                            "_blank"
                          );
                        }}
                        className={classes.socialIcon}
                      />
                    )}
                    {user?.facebookLink && (
                      <BsFacebook
                        size={24}
                        color={"var(--main-color-red)"}
                        onClick={() => {
                          window.open(
                            `https://${user?.facebookLink}`,
                            "_blank"
                          );
                        }}
                        className={classes.socialIcon}
                      />
                    )}
                    {user?.instagramLink && (
                      <GrInstagram
                        size={24}
                        color={"var(--main-color-red)"}
                        onClick={() => {
                          window.open(
                            `https://${user?.instagramLink}`,
                            "_blank"
                          );
                        }}
                        className={classes.socialIcon}
                      />
                    )}
                    {user?.googleLink && (
                      <ImGooglePlus3
                        size={24}
                        color={"var(--main-color-red)"}
                        onClick={() => {
                          window.open(`https://${user?.googleLink}`, "_blank");
                        }}
                        className={classes.socialIcon}
                      />
                    )}
                    {user?.linkedinLink && (
                      <BsLinkedin
                        size={24}
                        color={"var(--main-color-red)"}
                        onClick={() => {
                          window.open(
                            `https://${user?.linkedinLink}`,
                            "_blank"
                          );
                        }}
                        className={classes.socialIcon}
                      />
                    )}
                  </Col>
                </Row>
              </>
            </div>

            <Row className={`g-0 ${classes.personalDetailRow}`}>
              <Col md={4} sm={12}>
                <MdEmail size={24} color={"#E60000"} />
                <span>{user?.UserId?.email}</span>
              </Col>
              <Col md={8} sm={12}>
                <MdLocationPin size={24} color={"#E60000"} />
                <span>
                  {user?.address
                    ? user?.address
                    : "This information is not edit yet..."}
                </span>
              </Col>
              <Col md={12} sm={12} className={classes?.descriptionContainer}>
                <MdDescription size={40} color={"#E60000"} />
                <span>
                  {user?.description
                    ? user?.description
                    : "This information is not edit yet..."}
                </span>
              </Col>
            </Row>
            <Row className={`g-0 ${classes.certificateAndServicesRow}`}>
              <Col md={12} sm={12}>
                <h4>CERTIFICATIONS</h4>
              </Col>
              {user?.certificates?.length > 0 ? (
                user?.certificates?.map((items, index) => {
                  return (
                    <Col md={6} sm={12}>
                      <h5>{items?.certificateType}</h5>
                      <ul>
                        {items?.certificateItem?.map((list, index) => {
                          return (
                            <li className={`${classes.greyText}`}>
                              {list?.CertificateName}
                            </li>
                          );
                        })}
                      </ul>
                    </Col>
                  );
                })
              ) : (
                <h6>No Certificates</h6>
              )}
            </Row>
            <Row className={`g-0 ${classes.certificateAndServicesRow}`}>
              <Col md={12} sm={12}>
                <h4>SERVICES</h4>
              </Col>
              {user?.services?.length > 0 ? (
                user?.services?.map((items, index) => {
                  return (
                    <Col md={6} sm={12}>
                      <h5>{items?.serviceType}</h5>
                      <ul>
                        {items?.serviceItems?.map((list, index) => {
                          return (
                            <li className={`${classes.greyText}`}>
                              {list?.serviceName}
                            </li>
                          );
                        })}
                      </ul>
                    </Col>
                  );
                })
              ) : (
                <h6>No Services</h6>
              )}
            </Row>
            <div className={classes.btnBox}>
              <Button
                label={"Claim Business"}
                className={classes.btn}
                onClick={() => {
                  history.push(`/create-profile/${id}`);
                }}
                disabled={showModal}
                style={{
                  filter: showModal && "brightness(0.7)",
                }}
              />
            </div>
          </div>
        </div>
      )}

      {/* Body User Detail Section End */}

      <section className={classes.footerSection}>
        <Footer containerClass={classes.container} />
      </section>

      <ClaimModal
        show={showModal}
        verifyClick={verifyCode}
        loading={verifyLoading}
      />
    </div>
  );
}

export default UnClaimedBusiness;
