import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import Footer from "../../stories/Footer";
import classes from "./Notification.module.css";
import moment from "moment";
import { apiUrl, BaseURL, imageUrl } from "../../Config/apiUrl";
import { Get } from "../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import Header from "../../stories/Header";
import { error, success, warning } from "../../constant/imagePath";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import { useRef } from "react";
import NoData from "../../stories/NoData/NoData";

const Notifications = ({ img, message, jobTitle, time }) => {
  return (
    <div className={[classes.HeaderNotificationInnerDiv]}>
      <div className={[classes.ImgDiv]}>
        <img src={imageUrl(img)} alt="..." />
      </div>
      <div className={[classes.contentDiv]}>
        <h6>{jobTitle}</h6>
        <p>{message}</p>
      </div>
      <div className={[classes.timeDiv]}>{moment(time).format("hh:mm a")}</div>
    </div>
  );
};

const NotificationPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const accessToken = useSelector((state) => state?.authReducer?.access_token);
  const userData = useSelector((state) => state?.authReducer?.user);
  const user_type = userData?.user_type;
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationUnReadCount, setNotificationUnReadCount] = useState([]);

  const socket = useRef(null);

  // get all notifications
  const getAllNotifications = async () => {
    const url = BaseURL("notification/all");
    const response = await Get(url, accessToken, false);
    if (response !== undefined) {
      setNotificationUnReadCount(response?.data?.newNotifications);
      let newShape = [];
      response?.data?.data?.length > 0 &&
        response?.data?.data?.forEach((e) =>
          newShape.push({
            id: e?._id,
            message: e?.message,
            receivedTime: moment(e?.createdAt).fromNow(),
            isRead: e?.isSeen,
            image:
              e?.status == "success"
                ? success
                : e?.status == "error"
                  ? error
                  : warning,
          })
        );
      setNotifications(newShape);
    }
  };

  useEffect(() => {
    let count = 0;
    socket.current = io(apiUrl);
    accessToken && user_type === "mechanic" && getAllNotifications();
    socket.current.on("msg", (msg, socketRoomId, roomList) => {
      roomList?.length > 0 &&
        roomList?.forEach(
          (e) =>
          (count =
            userData?.user_type == "customer"
              ? count + e?.customerUnreadCount * 1
              : count + e?.mechanicUnreadCount * 1)
        );
      setNotificationUnReadCount(count);
    });
  }, []);

  // mark all as read
  const markAllAsread = async (type) => {
    // setNotifications([]);
    const url = BaseURL(`seen-notifications`);
    const response = await Get(url, accessToken, false);
    if (response !== undefined) {
      type !== undefined &&
        toast.success("Successfully marked all notifications as read.");
      await getAllNotifications();
    }
  };

  return (
    <div className={[classes.NotificationScreen]}>
      <div className={classes.header}>
        <Header
          backgroundColor={"var(--white-color)"}
          containerClass={classes.mainContainer}
          className={classes.header}
          isLogoBlack={true}
        />
      </div>
      {!loading && notifications?.length == 0 && <NoData className={classes.noData} />}
      {loading ? (
        <div className={[classes.LoaderDiv]}>{/* <Loader /> */}</div>
      ) : (
        <section className={[classes.middleSection]}>
          <Container className={[classes.mainContainer]}>
            <Row>
              {notifications.length > 0 ? (
                notifications?.map((item, i) => {
                  return (
                    <Col xs={12} className={[classes.notificationCol]} key={i}>
                      <Notifications
                        time={item?.createdAt}
                        img={item?.sender?.photo}
                        message={item?.message}
                        jobTitle={item?.job?.jobTitle}
                      />
                    </Col>
                  );
                })
              ) : (
                <></>
              )}
            </Row>
          </Container>
        </section>
      )}

      <Footer containerClass={classes.mainContainer} />
    </div>
  );
};
export default NotificationPage;
