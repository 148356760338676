import React from "react";
import Header from "../../stories/Header";
import { imageUrl } from "../../Config/apiUrl";
import classes from "./HeroSection.module.css";
import { Container, Row, Col } from 'react-bootstrap';

const HeroSection = ({
  BgImg,
  title,
  description,
  sectionClass,
  ...props
}) => {
  return (
    <div>
      <section
        className={`${classes.header_sect} ${sectionClass && sectionClass}`}
        style={{
          backgroundImage: `url(${BgImg})`,
        }}
      >
        <Container className={`${classes.container} `}>
          <Row>
            <Col className={`${classes.sect1}`}>
              <div data-aos="fade-up" >
                <h1 className={`${classes.sect1_title}`}>{title}</h1>
                <p className={`${classes.sect1_para}`}>{description}</p>
                {props.children}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default HeroSection;
