import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import classes from "./ChatDesktop.module.css";
import { IoIosSend } from "react-icons/io";
import { BiMessageDetail } from "react-icons/bi";
import { useSelector } from "react-redux";
import InputWithButton from "../../stories/InputWithButton/InputWithButton";
import moment from "moment";
import { imageUrl } from "../../Config/apiUrl";
import Button from "../../stories/Button";
import NoData from "../../stories/NoData/NoData";
import Loader from "../../stories/Loader";
const ChatDesktop = ({
  roomsData,
  selectedRoom,
  setSelectedRoom,
  messages,
  sendMessage,
  getPrevMessages,
  loadMoreMessages,
  isLoadingChats,
  isLoadingRooms
}) => {
  const userData = useSelector((state) => state?.authReducer?.user);
  const userType = useSelector((state) => state?.authReducer?.user_type);

  console.log(selectedRoom, "selectedRoom");

  const [message, setMessage] = useState("");

  // For Scroll To Bottom
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  useEffect(scrollToBottom, []);

  return (
    <>
      <section className={classes.sectionImg}>
        <Container
          fluid
          className={[classes.custom_Container].join(" ")}
        >
          <Row>
            <Col lg={4}>
              <div className={[classes.ChatRoom_Container]}>
                {isLoadingRooms ?
                  <Loader />
                  :
                  roomsData?.length == 0 ?
                    <NoData className={classes.noData} text={'No Rooms Found'} />
                    :
                    roomsData?.map((item, i) => {
                      return (
                        <div
                          className={[classes.ChatRoom_innerDiv]}
                          key={i}
                          onClick={() => setSelectedRoom(item)}
                          style={{
                            backgroundColor:
                              item?.id === selectedRoom?.id && "#e6000094",
                          }}
                        >
                          <div className={[classes.ChatRoom_img_div]}>
                            <img
                              src={imageUrl(
                                userType === "customer"
                                  ? item?.mechanic?.photo
                                  : item?.customer?.photo
                              )}
                            />
                          </div>
                          <div className={[classes.ChatRoom_text_div]}>
                            <div className={[classes.ChatRoom_innertext_div]}>
                              <div className={[classes.roomsDataUserName]}>
                                <h5>
                                  {userType === "customer"
                                    ? item?.mechanic?.userName
                                    : item?.customer?.userName}
                                </h5>
                              </div>
                              <div>
                                <p>{moment(item?.createdAt).format("hh:mm a")}</p>
                              </div>
                            </div>
                            <p className={[classes.ChatRoom_innertext_div_para]} style={{
                              color: item?.id === selectedRoom?.id && "#000",
                            }}>
                              {item?.lastMessage?.text}
                            </p>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </Col>
            <Col lg={8}>
              <div className={[classes.inbox]}>
                {selectedRoom !== null ? (
                  <>
                    <div className={classes.chatHeader}>
                      <div className={classes.logoImg}>
                        <img
                          src={imageUrl(
                            userType === "customer"
                              ? selectedRoom?.mechanic?.photo
                              : selectedRoom?.customer?.photo
                          )}
                          alt="chatLogo"
                        />
                      </div>
                      <div className={classes.chatBoxUserName}>
                        <p>
                          {userType === "customer"
                            ? selectedRoom?.mechanic?.userName
                            : selectedRoom?.customer?.userName}
                        </p>
                      </div>
                    </div>
                    <div className={[classes.chattingDiv]}>
                      <div ref={messagesEndRef} />
                      {
                        isLoadingChats ?
                          <Loader />
                          :
                          messages?.length == 0 ?
                            <NoData className={classes.noData} text={'No Chats Found'} />
                            :
                            messages.map((item, i) => {
                              console.log("id", userData?.UserId?._id, item?.user?._id)
                              return (
                                <>
                                  <div key={i}>
                                    {/* for left align messages  */}
                                    {userData?.UserId?._id !== item?.user?._id ? (
                                      <>
                                        <div className={classes.incomingMessage}>
                                          <p>{item?.text}</p>
                                        </div>
                                        <span className={classes.time}>
                                          {moment(item?.createdAt).format("hh:mm a")}
                                        </span>
                                      </>
                                    ) : (
                                      //  for right align messages
                                      <>
                                        <div className={classes.outcomingMessage}>
                                          <p>{item?.text}</p>
                                        </div>
                                        <span className={classes.Incomingtime}>
                                          {moment(item?.createdAt).format("hh:mm a")}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                </>
                              );
                            })}
                      <div className={[classes.loadMoreButtonDiv]}>
                        {messages?.length == 30 && <Button
                          label={loadMoreMessages ? "loading..." : "load more"}
                          onClick={() =>
                            getPrevMessages(messages, messages?.length + 1, 30)
                          }
                          disabled={loadMoreMessages}
                        />
                        }
                      </div>
                    </div>
                    <div className={[classes.sendBtnDiv]}>
                      <InputWithButton
                        mainContainerClass={classes.SendInputMainContainer}
                        inputClass={classes.sendInput}
                        btnClass={classes.SendInputBtnContainer}
                        placeholder={"Type message here..."}
                        btnChildern={
                          <IoIosSend
                            color={"var(--main-color-red)"}
                            size={24}
                          />
                        }
                        value={message}
                        setValue={setMessage}
                        onClick={() => {
                          sendMessage(message);
                          setMessage("");
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <>
                      <div className={[classes.noRoomSelected].join(" ")}>
                        <div
                          className={[classes.noRoomInnerSelected].join(" ")}
                        >
                          <div>
                            <BiMessageDetail
                              size={100}
                              color={"var(--red-color)"}
                            />
                          </div>
                          <h3>You have no messages right now</h3>
                        </div>
                      </div>
                    </>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ChatDesktop;
