import React from 'react'
import BackgroundImageWithInfo from '../../stories/BackgroundImageWithInfo'
import OurServiceCard from '../../stories/OurServiceCard'
import Header from '../../stories/Header'
import MechanicCard from '../../stories/MechanicCard'
import CustomAccordion from '../../stories/Accordion'
import MechanicTypeCard from '../../stories/MechanicTypeCard'
import Input from '../../stories/Input'
import PackagesCard from '../../stories/PackageCard'
import DesktopHeader from '../../stories/Header/DesktopHeader'
import "./dome.css"
import { useState } from 'react';
import CurrentBooking from '../../stories/CurrentBookingCard'
function Demo() {
  const [currentBookings, setCurrentBookings]=useState([])
  return (
     <>
      {/*-------------------- RENDERS LIST OF CURRENT BOOKING IN THIS SECTION ---------------- */}
      {/* {isLogin && user_type === "mechanic" && currentBookings?.length > 0 && (
        
      )} */}

     <CurrentBooking item={currentBookings} />

     </>
  )
}

export default Demo