import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Patch, Post } from "../../Axios/AxiosFunctions";
import { apiHeader, BaseURL } from "../../Config/apiUrl";
import { updateUserData } from "../../store/Actions/authAction";
import Button from "../../stories/Button";
import { Checkbox } from "../../stories/Checkbox";
import Footer from "../../stories/Footer";
import Header from "../../stories/Header";
import classes from "./UpdateServicesAndCertificate.module.css";

const UpdateServiceAndCertificate = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.authReducer?.user);
  const { certificatesArray, servicesArray } = useSelector(
    (state) => state?.commonReducer
  );
  const accessToken = useSelector((state) => state?.authReducer?.accessToken);
  const [updateLoading, setUpdateLoading] = useState(false);

  const [selectedServices, setSelectedServices] = useState(userData?.services);
  const [selectedCertificates, setSelectedCertificates] = useState(
    userData?.certificates
  );

  const [selectedCertificatesObjects, setSelectedCertificatesObjects] =
    useState([]);
  const [selectedServicesObjects, setSelectedServicesObjects] = useState([]);

  const headers = apiHeader(accessToken);

  const handleSelectedCertificates = (type, item, itemType) => {
    let index = -1;
    let newItem = {};
    let data =
      itemType == "certificate"
        ? selectedCertificatesObjects.slice()
        : selectedServicesObjects.slice();
    if (itemType == "certificate") {
      index = selectedCertificatesObjects.findIndex(
        (x) => x.certificateItemId == item?._id
      );
      if (index == -1) {
        newItem = {
          mechanicUserId: userData?.UserId?._id,
          mechanicDetailId: userData?._id,
          certificateTypeId: type?._id,
          certificateItemId: item?._id,
        };
      }
    } else {
      index = selectedCertificatesObjects.findIndex(
        (x) => x.certificateItemId == item?._id
      );
      if (index == -1) {
        newItem = {
          mechanicDetailId: userData?._id,
          mechanicUserId: userData?.UserId?._id,
          serviceTypeId: type?._id,
          serviceItemId: item?._id,
        };
      }
    }
    if (index == -1) {
      data.push(newItem);
    } else {
      data?.splice(index, 1);
    }
    if (itemType == "certificate") {
      setSelectedCertificatesObjects(data);
    } else {
      setSelectedServicesObjects(data);
    }
  };

  const Update = async () => {
    const url = BaseURL("mechanic/certificates-and-services/update");
    const params = {
      selectedCertificates: selectedCertificatesObjects,
      selectedServices: selectedServicesObjects,
    };
    for (let key in params) {
      if (params[key].length === 0 || params[key] === "") {
        return toast.error(
          `Please Select Atleast One Certificate OR One Service.`
        );
      }
    }
    setUpdateLoading(true);
    const response = await Patch(url, params, headers);
    if (response !== undefined) {
      dispatch(updateUserData(response?.data?.data));
      toast.success(`🙌 Certifications & Services are updated now.`);
    }
    setUpdateLoading(false);
  };

  useEffect(() => {
    createCeritifateAndServiceDataStructure(
      userData?.certificates,
      "certificateItem",
      "Certificate",
      setSelectedCertificatesObjects,
      setSelectedCertificates
    );
    createCeritifateAndServiceDataStructure(
      userData?.services,
      "serviceItems",
      "service",
      setSelectedServicesObjects,
      setSelectedServices
    );
  }, []);
  const createCeritifateAndServiceDataStructure = (
    mainArray,
    mainKey,
    innerTypeKey,
    setObject,
    setString
  ) => {
    let selectedItemStringsArray = [];
    let selectedItemObjectArray = [];
    mainArray.map((item, index) => {
      item[mainKey].map((innerItem, innerIndex) => {
        selectedItemStringsArray.push(innerItem[`${innerTypeKey}Name`]);
        selectedItemObjectArray.push({
          mechanicUserId: userData?.UserId?._id,
          mechanicDetailId: userData?._id,
          ...(innerTypeKey == "Certificate" && {
            certificateTypeId: innerItem[`${innerTypeKey}TypeId`],
            certificateItemId: innerItem?._id,
          }),
          ...(innerTypeKey == "service" && {
            serviceTypeId: innerItem[`${innerTypeKey}TypeId`],
            serviceItemId: innerItem?._id,
          }),
        });
      });
    });
    setString(selectedItemStringsArray);
    setObject(selectedItemObjectArray);
  };

  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <Header
          containerClass={`${[classes.container].join(" ")}`}
          bgTransparent={false}
          isLogoBlack={true}
        />
      </div>
      <section className={classes.middleSection}>
        <Container className={[classes.container].join(" ")}>
          <h1 className={[classes.headerContent]}>
            Certificates {"&"} Services
          </h1>
          <Row className={classes.mainRow}>
            <Col md={12}>
              <h3 className={[classes.subHeader]}>CERTIFICATIONS</h3>
              <p>Check boxes for below option</p>
            </Col>
            {certificatesArray?.map((item, i) => {
              return (
                <Col md={6} className={[classes.certificateCol]} key={i}>
                  <h3>{item?.CertificateType}</h3>
                  {item?.certificateItem?.map((certificate, i) => {
                    return (
                      <Checkbox
                        value={selectedCertificates}
                        label={certificate?.CertificateName}
                        disabled={false}
                        setValue={(e) => {
                          console.log(e, "e");
                          setSelectedCertificates(e);
                          handleSelectedCertificates(
                            item,
                            certificate,
                            "certificate"
                          );
                        }}
                      />
                    );
                  })}
                </Col>
              );
            })}
          </Row>

          <Row className={classes.mainRow}>
            <Col md={12}>
              <h3 className={[classes.subHeader]}>SERVICES</h3>
              <p>Check boxes for below option</p>
            </Col>
            {servicesArray?.map((item, i) => {
              return (
                <Col md={6} className={[classes.certificateCol]} key={i}>
                  <h3>{item?.serviceType}</h3>
                  {item?.serviceItem?.map((service, i) => {
                    return (
                      <Checkbox
                        value={selectedServices}
                        label={service?.serviceName}
                        disabled={false}
                        setValue={(e) => {
                          setSelectedServices(e);
                          handleSelectedCertificates(item, service, "service");
                        }}
                      />
                    );
                  })}
                </Col>
              );
            })}
          </Row>
          <div className={[classes.updateBtnDiv]}>
            <Button
              label={updateLoading ? "Updating..." : "Update"}
              disabled={updateLoading}
              onClick={Update}
            />
          </div>
        </Container>
      </section>

      <section>
        <Footer containerClass={classes.container} />
      </section>
    </div>
  );
};

export default UpdateServiceAndCertificate;
