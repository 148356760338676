import React from "react";
import { DotLoader } from "react-spinners";
import classes from "./Loader.module.css";

function Loader({ size = 70, color = "var(--main-color-red)", text }) {
  return (
    <div className={classes.container}>
      <DotLoader size={size} className={classes.loader} color={color} />
      {text && <span>{text}</span>}
    </div>
  );
}

export default Loader;
