import React, { useEffect, useState } from "react";
import HeroSection from "../../stories/HeroSection";
import classes from "./Consult.module.css";
import Aos from "aos";
import { Col, Container, Row } from "react-bootstrap";
import Input from "../../stories/Input";
import TextArea from "../../stories/TextArea";
import Button from "../../stories/Button";
import Footer from "../../stories/Footer";
import BackgroundImageWithInfo from "../../stories/BackgroundImageWithInfo";
import { apiHeader, BaseURL, imageUrl } from "../../Config/apiUrl";
import Header from "../../stories/Header";
import { Get, Post } from "../../Axios/AxiosFunctions";
import { toast } from "react-toastify";

function Consult() {
  // Form States
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [dataList, setDataList] = useState([]);
  const [blurLoader, setBlurLoader] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);

  const getData = async () => {
    const ApiURL = BaseURL(
      `pages?pages=consult&goals=${false}&service=${false}&aboutusItem=${false}&faq=${false}&all=${false}`
    );
    setBlurLoader(true);
    const responseData = await Get(ApiURL);
    if (responseData !== undefined) {
      setDataList(responseData?.data?.data);
    } else {
      setDataList([]);
      console.log("error");
    }
    setBlurLoader(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmitApi = async () => {
    const contactUrl = BaseURL("contact-us");
    let params = {
      firstName,
      lastName,
      email,
      phoneNo,
      message,
      formType: "consult",
    };
    let isRequired = false;
    for (let key in params) {
      if (params[key] == "") {
        return toast.error("Please fill out all fields");
      }
    }

    if (isRequired == true) {
      toast.info(`Please enter required fields.`, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      setLoading(true);
      const responseData = await Post(contactUrl, params, apiHeader());
      if (responseData !== undefined) {
        toast.success(`Form Successfully Submitted.`, {
          position: toast.POSITION.TOP_CENTER,
        });
        setEmail("");
        setLastName("");
        setFirstName("");
        setPhoneNo("");
        setMessage("");
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <div className={blurLoader ? "pageBlur" : "Consult"}>
      <div className={classes.page}>
        <div className={classes.header}>
          <Header
            bgTransparent={true}
            containerClass={`${[classes.container].join(" ")}`}
          />
        </div>
        <HeroSection
          BgImg={imageUrl(dataList?.pages?.cover_image)}
          title={dataList?.pages?.heading}
          description={dataList?.pages?.sec1Description}
          sectionClass={classes.consultSection}
        />

        <section className={classes.consultSection}>
          <Container className={classes.container}>
            <Row>
              <Col md={12} data-aos="fade-up">
                <h5 className={classes.heading1}>Mobile Mechanic Hub</h5>
                <h1 className={classes.heading2}>
                  CONSULTATION <span>Consult</span>
                </h1>
              </Col>
              <Col md={12} className={classes.formAndContent}>
                <Row>
                  <Col md={6}>
                    <div className={classes.left}>
                      <h4>{dataList?.pages?.sec1Heading}</h4>
                      <p>{dataList?.pages?.sec2Description} </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className={classes.form}>
                      <div className={classes.mb10}>
                        <Input
                          placeholder={"First Name*"}
                          value={firstName}
                          setter={setFirstName}
                        />
                      </div>
                      <div className={classes.mb10}>
                        <Input
                          placeholder={"Last Name*"}
                          value={lastName}
                          setter={setLastName}
                        />
                      </div>
                      <div className={classes.mb10}>
                        <Input
                          placeholder={"Phone Number*"}
                          type={"number"}
                          value={phoneNo}
                          setter={setPhoneNo}
                        />
                      </div>
                      <div className={classes.mb10}>
                        <Input
                          placeholder={"Email Address*"}
                          type={"email"}
                          value={email}
                          setter={setEmail}
                        />
                      </div>
                      <div className={classes.mb10}>
                        <TextArea
                          placeholder={"Message*"}
                          value={message}
                          setter={setMessage}
                        />
                      </div>
                      <Button
                        label={loading ? "Submitting..." : "Submit"}
                        onClick={() => handleSubmitApi()}
                        className={classes.submitBtn}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Bg Image Section */}
        <BackgroundImageWithInfo
          item={{
            title: dataList?.pages?.sec2Heading,
            description: dataList?.pages?.sec2Description,
            image: imageUrl(dataList?.pages?.sec2Image),
          }}
          bgimgClass={classes.bgImageClass}
          containerClass={classes.container}
          sectionClass={classes.bgImgSection}
          btnClass={classes.join_btn}
        />

        <Footer containerClass={classes.container} />
      </div>
    </div>
  );
}

export default Consult;

const Data3 = {
  image: imageUrl("8dba00de-5956-4f99-9a5e-fcbcb86e0b86-image"),
  title: "Why Choose Us?",
  description:
    "We have been in the industry 8+ years, and have test many different systems. We have made all the mistakes for you! As the old saying goes, a bought lesson is a learned lesson. We know what works, and what does not. We have a list of systems that we use to make not only our life easier, but our customers as well. We have a list great of shop softwares, and booking softwares for a single owner operator or a company with multiple mechanics. Our clients truly enjoy their experience. We make it painless, and simple. Contact us today for a 1 on 1 call.",
};
