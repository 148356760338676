import React, { useEffect, useState } from "react";
import classes from "./PaymentModal.module.css";
import ModalSkeleton from "../ModalSkeleton";
import { IoIosCard } from "react-icons/io";
import { BsPlusLg } from "react-icons/bs";
import StripeCard from "../StripeCard";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { apiHeader, BaseURL, stripe_public_key } from "../../../Config/apiUrl";
import { Get, Post } from "../../../Axios/AxiosFunctions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { NoPayment } from "../../../constant/imagePath";
import CreditCard from "../../../stories/CreditCard";
import Button from "../../../stories/Button";
import { Col, Row } from "react-bootstrap";

const PaymentModal = ({
  show,
  setShow,
  type,
  apiToken,
  buyPackage,
  buyPackageLoading,
}) => {
  const accessToken = useSelector((state) => state?.authReducer?.accessToken);
  const [addCard, setIsCard] = useState(false);
  const stripePromise = loadStripe(stripe_public_key);
  const [attachCardLoading, setAttachCardLoading] = useState(false);
  const [getCardLoading, setGetCardLoading] = useState(false);
  const [removeCardLoading, setRemoveCardLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);

  const headers = apiHeader(type === "registration" ? apiToken : accessToken);

  const getCard = async () => {
    const url = BaseURL("payment-method-list");
    setGetCardLoading(true);
    const response = await Get(
      url,
      type === "registration" ? apiToken : accessToken
    );
    if (response !== undefined) {
      setResponseData(response?.data?.data);
      console.log(response?.data?.data, "getcard");
    }
    setGetCardLoading(false);
  };

  useEffect(() => {
    if (show) {
      getCard();
    }
  }, [show]);

  const attachCard = async (id) => {
    console.log(id, "attach card");
    const url = BaseURL("attach-payment-method");
    const params = {
      pmId: id,
    };
    setAttachCardLoading(true);
    const response = await Post(url, params, headers);
    if (response !== undefined) {
      console.log(response?.data?.data, "attach");
      setResponseData(response?.data?.data);
      toast.success(
        "You have successfully added a new card, now kindly select a card and pay.",
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        }
      );
    }
    setAttachCardLoading(false);
  };

  const detachCard = async (id) => {
    const url = BaseURL("detach-payment-method");
    const params = {
      pmId: id,
    };
    setRemoveCardLoading(true);
    const response = await Post(url, params, headers);
    if (response !== undefined) {
      console.log(response?.data?.data, "detach");
      setResponseData(response?.data?.data);
      toast.success("Payment card successfully removed.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
    setRemoveCardLoading(false);
  };
  return (
    <>
      <ModalSkeleton
        show={show}
        setShow={setShow}
        borderRadius={"25px"}
        header={
          !addCard && (
            <div>
              <h3>Payment Methods</h3>
            </div>
          )
        }
        headerClass={[classes.headerClass]}
        modalClass={[classes.modalBodyDiv]}
        width={"650px"}
      >
        {addCard ? (
          <Elements stripe={stripePromise}>
            <StripeCard
              setIsCard={setIsCard}
              handleAttach={attachCard}
              loading={attachCardLoading}
            />
          </Elements>
        ) : (
          <div>
            <div className={[classes.addNewCardDiv]}>
              <div>
                <IoIosCard size={45} color={"var(--main-color-red)"} />
                <p className={[classes.debitCreditLabel]}>Debit/Credit Card</p>
              </div>
              <div
                onClick={() => {
                  setIsCard(true);
                }}
              >
                <BsPlusLg size={15} color={"var(--main-color-red)"} />
                <p className={[classes.addNewCardlabel]}>ADD A NEW CARD</p>
              </div>
            </div>
            {getCardLoading ? (
              <>
                <Row className="my-2">
                  {[1].map((item, i) => {
                    return (
                      <Col lg={12}>
                        <div
                          className="SkeletonContainer"
                          style={{ height: "100px" }}
                        ></div>
                      </Col>
                    );
                  })}
                </Row>
              </>
            ) : responseData?.length > 0 ? (
              <>
                {responseData?.map((item, i) => {
                  return (
                    <div key={i}>
                      <CreditCard
                        cardData={item}
                        detachCard={detachCard}
                        selectedCard={selectedCard}
                        setSelectedCard={setSelectedCard}
                        removeCardLoading={removeCardLoading}
                      />
                    </div>
                  );
                })}
                <div className={[classes.buyPackageBtnDiv]}>
                  <Button
                    label={buyPackageLoading ? "Buying..." : "Buy Package"}
                    onClick={() => buyPackage(selectedCard)}
                    disabled={selectedCard === null || buyPackageLoading}
                    className={[classes.buyPackageBtn]}
                    customStyle={{
                      filter: selectedCard === null && "brightness(0.8)",
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={[classes.noCardsDiv]}>
                <img src={NoPayment} alt="..." />
                <h6>No payment cards added yet.</h6>
              </div>
            )}
          </div>
        )}
      </ModalSkeleton>
    </>
  );
};

export default PaymentModal;
