import React, { useState } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import ConfirmationModal from "../../Components/Modals/Confirmation";
import { pdfUrl } from "../../Config/apiUrl";
import { pdfBg } from "../../constant/imagePath";
import classes from "./PDFImageView.module.css";


function PDFImageView({ item, HandleDeletePdf, index, local = false, loading, pdfType, setChosenPDFType, setSelectedDoc, isCloseable = true }) {
    const [areYouSure, setAreYouSure] = useState(false);
    return (
        <>
            <div className={classes.uploaded_div} key={index} onClick={() => window.open(local ? URL.createObjectURL(item) : `${pdfUrl}/${item}`, '_blank')}>
                <div className={classes.uploaded_pdf}>
                    <div className={classes.certificateCard}>
                        <img src={pdfBg} alt="cover" className="image-fluid" />
                        <span>View PDF</span>
                    </div>
                </div>
                {isCloseable && <div
                    className={classes.deleteIcon}
                    onClick={(e) => {
                        e.stopPropagation();
                        setSelectedDoc(item);
                        setAreYouSure(true);
                        if (pdfType === "public") {
                            setChosenPDFType(pdfType);
                        } else {
                            setChosenPDFType(pdfType);
                        }
                    }}
                >
                    <RiCloseCircleFill color="var(--main-color-red)" size={20} />
                </div>}
            </div>
            <ConfirmationModal
                show={areYouSure}
                setShow={setAreYouSure}
                onClick={async () => {
                    await HandleDeletePdf(index, item?._id, local);
                    setAreYouSure(false);
                }}
                isApiCall={loading}
                subTitle={"Do you want to remove this document?"}

            />
        </>
    );
}

export default PDFImageView;
