import React, { useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./loginOrSignUp.module.css";
import Input from "./../../../stories/Input/index";
import Form from "react-bootstrap/Form";
import Button from "./../../../stories/Button/index";
import { FiMail } from "react-icons/fi";
import { FaLock } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
import { apiHeader, BaseURL } from "../../../Config/apiUrl";
import { toast } from "react-toastify";
import { Post } from "../../../Axios/AxiosFunctions";
import { useDispatch, useSelector } from "react-redux";
import { saveLoginUserData } from "../../../store/Actions/authAction";

function LoginOrSignUpModal({ show, setShow, onForgetPassword }) {
  const fcmToken = useSelector((state) => state?.authReducer?.fcmToken);
  const dispatch = useDispatch();
  const [login, setLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isAgree, setIsAgree] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log(fcmToken, "fcmToken");

  const handleSignUp = async () => {
    const url = BaseURL("web-signup");
    const params = {
      userName,
      email,
      password,
      passwordConfirm: confirmPassword,
      isTermsAgree: isAgree,
      role: "customer",
    };
    for (let key in params) {
      if (params[key] === "" || params[key] === false) {
        return toast.error("Please fill all the fields");
      }
    }
    params.fcmToken = fcmToken
    setLoading(true);
    const response = await Post(url, params, apiHeader());
    if (response !== undefined) {
      toast.success(
        `🙌 We have successfully created your account. Please check your email for verification.`,
        {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        }
      );
      setEmail("");
      setConfirmPassword("");
      setPassword("");
      setUserName("");
      setIsAgree(false);
      setShow(false);
    }
    setLoading(false);
  };

  const handleLogin = async () => {
    const url = BaseURL("web-login");
    const params = {
      email,
      password,
    };
    for (let key in params) {
      if (params[key] === "") {
        return toast.error("Please fill all the fields");
      }
    }
    params.fcmToken = fcmToken

    setLoading(true);
    const response = await Post(url, params, apiHeader());
    if (response !== undefined) {
      dispatch(saveLoginUserData(response?.data?.data));
      setEmail("");
      setPassword("");
      setShow(false);
    }
    setLoading(false);
  };

  return (
    <>
      <ModalSkeleton show={show} setShow={setShow} borderRadius={"40px"}>
        {login ? (
          <div className={`${classes.login_div}`}>
            <h3>LOGIN</h3>
            <p className={`${classes.login_para} ${classes.textGray}`}>
              Login to your account
            </p>
            <div className={`${classes.login_div_inp}`}>
              <Input
                type={`email`}
                placeholder={"Enter Email"}
                setter={setEmail}
                value={email}
                leftIcon={<FiMail size={24} color={"#E60000"} />}
              />
            </div>
            <div className={`${classes.login_div_inp}`}>
              <Input
                type={`password`}
                placeholder={"Enter Your Password"}
                setter={setPassword}
                value={password}
                leftIcon={<FaLock size={24} color={"#E60000"} />}
              />
            </div>
            <Button
              label={loading ? "Logging..." : "Login"}
              disabled={loading}
              onClick={handleLogin}
              className={`${classes.login_btn}`}
            />
            <p
              className={`${classes.forget_password} ${classes.textGray} ${classes.c_p}`}
              onClick={(e) => {
                onForgetPassword();
              }}
            >
              Forgot Password
            </p>
            <p>
              <span className={` ${classes.Sign_Up_text} ${classes.textGray}`}>
                Don't have an account?
              </span>
              <span
                onClick={() => setLogin(false)}
                className={`${classes.Sign_Up} ${classes.textred} ${classes.c_p}`}
              >
                Sign-Up Now
              </span>
            </p>
          </div>
        ) : (
          <div className={`${classes.login_div}`}>
            <h3>CREATE ACCOUNT</h3>
            <p className={`${classes.login_para} ${classes.textGray}`}>
              Signup to your account
            </p>
            <div className={`${classes.login_div_inp}`}>
              <Input
                type={`text`}
                placeholder={"Enter UserName"}
                setter={setUserName}
                value={userName}
                leftIcon={<FaUserAlt size={24} color={"#E60000"} />}
              />
            </div>
            <div className={`${classes.login_div_inp}`}>
              <Input
                type={`email`}
                placeholder={"Enter Your email"}
                setter={setEmail}
                value={email}
                leftIcon={<FiMail size={24} color={"#E60000"} />}
              />
            </div>
            <div className={`${classes.login_div_inp}`}>
              <Input
                type={`password`}
                placeholder={"Enter Your Password"}
                setter={setPassword}
                value={password}
                leftIcon={<FaLock size={24} color={"#E60000"} />}
              />
            </div>
            <div className={`${classes.login_div_inp}`}>
              <Input
                type={`password`}
                placeholder={"Enter Confirm Password"}
                setter={setConfirmPassword}
                value={confirmPassword}
                leftIcon={<FaLock size={24} color={"#E60000"} />}
              />
            </div>

            <p className={`${classes.login_div_checkBox}`}>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  value={isAgree}
                  label="I Agree Terms & Conditions"
                  onClick={() => setIsAgree((p) => !p)}
                />
              </Form.Group>
            </p>
            <Button
              label={loading ? "Signing..." : "Signup"}
              disabled={loading}
              onClick={handleSignUp}
              className={`${classes.login_btn}`}
            />
            <p
              className={`${classes.forget_password} ${classes.textGray} ${classes.c_p}`}
              onClick={(e) => {
                onForgetPassword();
              }}
            >
              Forgot Password
            </p>
            <p>
              <span className={` ${classes.Sign_Up_text} ${classes.textGray}`}>
                Already have an account?
              </span>
              <span
                onClick={() => setLogin(true)}
                className={`${classes.Sign_Up} ${classes.textred} ${classes.c_p}`}
              >
                Login Here
              </span>
            </p>
          </div>
        )}
      </ModalSkeleton>
    </>
  );
}

export default LoginOrSignUpModal;
