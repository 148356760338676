import React, { useState, useEffect, useRef } from "react";
import classes from "./userSetting.module.css";
import Header from "./../../stories/Header/index";
import Footer from "../../stories/Footer";
import Button from "./../../stories/Button/index";
import Input from "./../../stories/Input/index";
import { FiMail } from "react-icons/fi";
import {
  FaUserAlt,
  FaSnapchat,
  FaFacebook,
  FaInstagram,
  FaGooglePlus,
  FaLinkedinIn,
  FaCar,
  FaRegFilePdf,
} from "react-icons/fa";
import { MdOutlineDescription, MdCancel } from "react-icons/md";
import { AiFillCloseCircle } from "react-icons/ai";
import { ProfileWithEditButton } from "./../../stories/ProfileWithEditButton/index";
import {
  ProfileIcon,
  coverImage1,
  pdfBg,
  UploadPdfIcon,
} from "../../constant/imagePath";
import { useDispatch, useSelector } from "react-redux";
import { apiHeader, BaseURL, imageUrl, pdfUrl } from "../../Config/apiUrl";
import { toast } from "react-toastify";
import { Patch, Post } from "../../Axios/AxiosFunctions";
import { updateUserData } from "../../store/Actions/authAction";
import ConfirmationModal from "../../Components/Modals/Confirmation";
import { Col, Container, Row } from "react-bootstrap";
import PDFImageView from "../../stories/PDFImageView";

function UserSetting() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.authReducer?.user);
  const user_type = useSelector((state) => state.authReducer?.user_type);
  const accessToken = useSelector((state) => state.authReducer?.accessToken);
  const documentRef = useRef(null);
  const privateDocRef = useRef(null);

  const [userName, setUserName] = useState(
    userData?.userName ? userData?.userName : ""
  );
  const [email, setEmail] = useState(
    userData?.UserId?.email ? userData?.UserId?.email : ""
  );
  const [description, setDescription] = useState(
    userData?.description ? userData?.description : ""
  );
  const [snapchat, setSnapchat] = useState(
    userData?.snapchatLink ? userData?.snapchatLink : ""
  );
  const [facebook, setFacebook] = useState(
    userData?.facebookLink ? userData?.facebookLink : ""
  );
  const [instagram, setInstagram] = useState(
    userData?.instagramLink ? userData?.instagramLink : ""
  );
  const [google, setGoogle] = useState(
    userData?.googleLink ? userData?.googleLink : ""
  );
  const [linkedIn, setLinkedIn] = useState(
    userData?.linkedinLink ? userData?.linkedinLink : ""
  );
  const [vehicleName, setVehicleName] = useState(
    userData?.vehicle ? userData?.vehicle : ""
  );
  const [loading, setLoading] = useState(false);

  const [profileImage, setProfileImage] = useState(
    userData?.photo ? userData?.photo : ""
  );
  const [coverImage, setCoverImage] = useState(
    userData?.cover_image ? userData?.cover_image : ""
  );
  // Confirm Modal

  // ------ public docs states --------
  const [documentsFile, setDocumentsFile] = useState([]);

  // ------ private docs states -------
  const [documentsFilePrivate, setDocumentsFilePrivate] = useState([]);

  const [isDeletingDoc, setIsDeletingDoc] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState("");
  const [chosenPDFType, setChosenPDFType] = useState("");

  console.log({ userData });

  // Api

  const submit = async () => {
    let params = {
      email,
      description,
      userName,
      photo: profileImage,
      UserId: userData?.UserId?._id,
    };
    params = {
      ...params,
      ...(user_type === "mechanic" && {
        cover_image: coverImage,
      }),
      ...(user_type === "customer" && {
        vehicle: vehicleName,
      }),
    };
    for (let key in params) {
      if (params[key] == "" || params[key] == null) {
        return toast.info(`Please Enter All Details.`);
      }
    }

    if (userData?.documents?.length + documentsFile?.length == 0) {
      return toast.error("Document is required");
    }
    if (
      userData?.premium &&
      userData?.privateDocuments?.length + documentsFilePrivate?.length == 0
    ) {
      return toast.error("Private Document is required");
    }

    const pdfApiURL = BaseURL("upload/pdf");
    params = {
      ...params,
      ...(user_type === "mechanic" && {
        facebookLink: facebook === null || facebook === "" ? "" : facebook,
        instagramLink: instagram === null || instagram === "" ? "" : instagram,
        snapchatLink: snapchat === null || snapchat === "" ? "" : snapchat,
        googleLink: google === null || google === "" ? "" : google,
        linkedinLink: linkedIn === null || linkedIn === "" ? "" : linkedIn,
        documents: [...documentsFile, ...userData?.documents],
        privateDocuments: [
          ...documentsFilePrivate,
          ...userData?.privateDocuments,
        ],
      }),
    };
    let formData = user_type == "mechanic" && (await createPdfFormData(params));
    user_type == "mechanic" && setLoading(true);
    if (user_type == "mechanic") {
      if (documentsFile.length !== 0 || documentsFilePrivate.length !== 0) {
        let responseData = await Post(
          pdfApiURL,
          formData,
          apiHeader(accessToken, true)
        );
        if (responseData !== undefined) {
          params = {
            ...params,
            documents: responseData?.data?.data?.user?.documents,
            privateDocuments: responseData?.data?.data?.user?.privateDocuments,
          };
          console.log("Response Upload Pdf: ", params);
          HandleSubmitApi(params);
        }
      } else {
        HandleSubmitApi(params);
      }
    }

    user_type == "mechanic" && setLoading(false);

    user_type !== "mechanic" && HandleSubmitApi(params);
  };
  // Handle Submit
  const HandleSubmitApi = async (params) => {
    let updateProfileURL = BaseURL("updateMe");
    let editProfileData = await createFormData(params);
    setLoading(true);
    const responseData = await Post(
      updateProfileURL,
      editProfileData,
      apiHeader(accessToken)
    );
    if (responseData !== undefined) {
      dispatch(updateUserData(responseData?.data?.data?.user));
      setDocumentsFile([]);
      setDocumentsFilePrivate([]);
      setLoading(false);
      return toast.success(`Profile updated successfully.`);
    } else {
      setLoading(false);
      return toast.error(`${responseData?.message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const createFormData = (file) => {
    let data = new FormData();
    data.append("userName", file?.userName);
    user_type !== "mechanic" && data.append("vehicle", file?.vehicle);
    data.append("photo", file?.photo);
    data.append("description", file?.description);
    data.append("UserId", file?.UserId);
    data.append("email", file?.email);
    user_type === "mechanic" &&
      data.append("instagramLink", file?.instagramLink);
    user_type === "mechanic" && data.append("cover_image", file?.cover_image);
    user_type === "mechanic" && data.append("facebookLink", file?.facebookLink);
    user_type === "mechanic" && data.append("snapchatLink", file?.snapchatLink);
    user_type === "mechanic" && data.append("googleLink", file?.googleLink);
    user_type === "mechanic" && data.append("linkedinLink", file?.linkedinLink);
    if (user_type === "mechanic") {
      file?.documents?.length == 0
        ? data.append("documents[]", file?.documents)
        : file?.documents?.forEach((item) => {
            data.append("documents", item);
          });
      userData?.premium && file?.privateDocuments?.length == 0
        ? data.append("privateDocuments[]", file?.privateDocuments)
        : file?.privateDocuments?.forEach((item) => {
            data.append("privateDocuments", item);
          });
    }
    return data;
  };

  const createPdfFormData = (file) => {
    let data = new FormData();

    if (user_type == "mechanic") {
      file?.documents?.length == 0
        ? data.append("documents[]", file?.documents)
        : file?.documents.forEach((item) => {
            data.append("documents", item);
          });
      userData?.premium && file?.privateDocuments?.length == 0
        ? data.append("privateDocuments[]", file?.privateDocuments)
        : file?.privateDocuments.forEach((item) => {
            data.append("privateDocuments", item);
          });
    }

    return data;
  };

  // Api

  // Upload PDF Document Funcation Start

  const uploadDocument = (e, type = "public") => {
    let files = e;
    let myReduxFiles =
      type == "public" ? userData?.documents : userData?.privateDocuments;
    let stateFiles =
      type == "public" ? documentsFile?.length : documentsFilePrivate?.length;
    let maxLength = type == "public" ? 4 : 2;
    let uploadFilesLength = files.length;
    if (uploadFilesLength + myReduxFiles?.length + stateFiles > maxLength) {
      return toast.info(
        `😬Sorry, Maximum ${maxLength} ${type} pdf files allowed.`
      );
    }

    let isNotPdf = false;
    let maxSize = 2097152;
    let isLargeinSize = false;
    Object.keys(files).forEach(function (key) {
      if (files[key].name.split(".").pop().toLowerCase() === "pdf") {
        isNotPdf = true;
      }
      if (files[key].size > maxSize) {
        isLargeinSize = true;
      }
    });

    if (isNotPdf === false) {
      toast.info("Sorry, One of your files is not a pdf.");
      return;
    }

    if (isLargeinSize === true) {
      toast.info("😬Alert, One of your files is more than 2MB.");
      return;
    }

    let allDocumentsFile =
      type == "public" ? [...documentsFile] : [...documentsFilePrivate];
    Object.keys(files).forEach(function (key) {
      allDocumentsFile.push(files[key]);
    });
    if (type == "public") {
      setDocumentsFile(allDocumentsFile);
    } else {
      setDocumentsFilePrivate(allDocumentsFile);
    }
  };
  // Upload PDF Document Funcation End

  // Handle Remove Certificate
  const handleRemoveCertificate = async () => {
    const deletePdfURL = BaseURL(`delete-PDF`);
    let params =
      chosenPDFType === "public"
        ? { pdf: selectedDoc }
        : { pdf: selectedDoc, privatePDF: true };

    setIsDeletingDoc(true);
    const deletePdfResponse = await Patch(
      deletePdfURL,
      params,
      apiHeader(accessToken)
    );
    if (deletePdfResponse !== undefined) {
      if (chosenPDFType === "public") {
        toast.success(`Public Document has been deleted.`);
        let index = userData?.documents?.findIndex((el) => el === selectedDoc);
        let newArray = userData?.documents.slice();
        newArray.splice(index, 1);
        console.log(".......", { ...userData, documents: [...newArray] });
        dispatch(updateUserData({ ...userData, documents: [...newArray] }));
      } else {
        toast.success(`Private Document has been deleted.`);
        let index = userData?.privateDocuments?.findIndex(
          (el) => el === selectedDoc
        );
        let newArray = userData?.privateDocuments.slice();
        newArray.splice(index, 1);
        dispatch(
          updateUserData({ ...userData, privateDocuments: [...newArray] })
        );
      }
    } else {
      console.log("error");
    }
    setIsDeletingDoc(false);
  };

  let decideDocLength = documentsFile?.length + userData?.documents?.length;
  let decidePrivateDocLength =
    documentsFilePrivate?.length + userData?.privateDocuments?.length;

  return (
    <div>
      {/* <Header /> */}
      <div className={classes.header}>
        <Header
          containerClass={`${[classes.container].join(" ")}`}
          bgTransparent={false}
          isLogoBlack={true}
        />
      </div>
      <section className={`${classes.InputSection_div}`}>
        <Container className={classes.container}>
          {/* Input Section */}
          <div
            className={`${[
              user_type == "mechanic"
                ? classes.userImagesContainer
                : classes.imagesDiv,
            ].join(" ")}`}
          >
            {user_type == "mechanic" && (
              <ProfileWithEditButton
                updateImage={coverImage}
                setUpdateImage={setCoverImage}
                onErrorImage={true}
                classImg={classes.coverImageTagClass}
                className={classes.coverImage}
                classBtn={classes.coverEditBtn}
              />
            )}
            <div className={`${classes.profile_div}`}>
              <ProfileWithEditButton
                updateImage={profileImage}
                setUpdateImage={setProfileImage}
              />
            </div>
          </div>
          <p className={`${classes.greyText} ${classes.profile_text}`}>
            Change Profile Picture
          </p>

          <div className={`${classes.inp_div}`}>
            <Input
              type={"text"}
              placeholder={"User Name"}
              setter={setUserName}
              value={userName}
              leftIcon={<FaUserAlt size={20} color={"#E60000"} />}
            />
          </div>
          <div className={`${classes.inp_div}`}>
            <Input
              type={"email"}
              placeholder={"Email"}
              setter={setEmail}
              value={email}
              leftIcon={<FiMail size={20} color={"#E60000"} />}
            />
          </div>
          <div className={`${classes.inp_div}`}>
            <Input
              type={"text"}
              placeholder={"Description"}
              setter={setDescription}
              value={description}
              leftIcon={<MdOutlineDescription size={20} color={"#E60000"} />}
            />
          </div>

          {user_type === "mechanic" ? (
            <>
              {/* Social Icon */}
              <div className={`${classes.inp_div}`}>
                <Input
                  type={"text"}
                  placeholder={"Snapchat"}
                  setter={setSnapchat}
                  value={snapchat}
                  leftIcon={<FaSnapchat size={20} color={"#E60000"} />}
                />
              </div>
              <div className={`${classes.inp_div}`}>
                <Input
                  type={"text"}
                  placeholder={"FaceBook"}
                  setter={setFacebook}
                  value={facebook}
                  leftIcon={<FaFacebook size={20} color={"#E60000"} />}
                />
              </div>
              <div className={`${classes.inp_div}`}>
                <Input
                  type={"text"}
                  placeholder={"Instagram"}
                  setter={setInstagram}
                  value={instagram}
                  leftIcon={<FaInstagram size={20} color={"#E60000"} />}
                />
              </div>
              <div className={`${classes.inp_div}`}>
                <Input
                  type={"text"}
                  placeholder={"Google"}
                  setter={setGoogle}
                  value={google}
                  leftIcon={<FaGooglePlus size={20} color={"#E60000"} />}
                />
              </div>
              <div className={`${classes.inp_div}`}>
                <Input
                  type={"text"}
                  placeholder={"LinkedIn"}
                  setter={setLinkedIn}
                  value={linkedIn}
                  leftIcon={<FaLinkedinIn size={20} color={"#E60000"} />}
                />
              </div>
              {/* End of social Icon */}

              {/* Public Docs */}
              <div className={`${classes.publicDocuments_div}`}>
                {/*  */}
                <Row className={classes.certificatesRow}>
                  <Col md={12}>
                    <h2
                      className={`${classes.greyText} ${classes.public_text}`}
                    >
                      Public Documents
                    </h2>
                    <div className={`${classes.doc_para_div}`}>
                      <p className={`${classes.greyText} ${classes.para_text}`}>
                        Note: Mechanic is allowed to upload total (4) publicly
                        shared files.
                      </p>
                      <p className={`${classes.greyText} ${classes.para_text}`}>
                        (total {decideDocLength} files selected)
                      </p>
                    </div>
                  </Col>
                  <Col
                    md={12}
                    className={`${[
                      classes.certificatesBoxCol,
                      userData?.documents?.length > 0 && classes.p70,
                      documentsFile?.length > 0 && classes.p70,
                    ].join(" ")}`}
                  >
                    <div className={classes.certificatesBox}>
                      {/* When No Files */}
                      {userData?.documents?.length > 0 ||
                      documentsFile?.length > 0 ? (
                        <>
                          {userData?.documents?.map((el, index) => {
                            return (
                              <PDFImageView
                                item={el}
                                index={index}
                                local={false}
                                HandleDeletePdf={handleRemoveCertificate}
                                loading={isDeletingDoc}
                                pdfType="public"
                                setChosenPDFType={setChosenPDFType}
                                setSelectedDoc={setSelectedDoc}
                              />
                            );
                          })}
                          {documentsFile?.map((el, index) => {
                            return (
                              <PDFImageView
                                item={el}
                                index={index}
                                local={true}
                                HandleDeletePdf={() => {
                                  const newPdf = [...documentsFile];
                                  newPdf.splice(index, 1);
                                  setDocumentsFile(newPdf);
                                }}
                                loading={isDeletingDoc}
                                pdfType="public"
                                setChosenPDFType={setChosenPDFType}
                                setSelectedDoc={setSelectedDoc}
                              />
                            );
                          })}
                        </>
                      ) : (
                        <div
                          className={`${classes.pdf_btn} ${classes.btn_main}`}
                        >
                          <div className={`${classes.uploadPdfIconContainer} `}>
                            <img src={UploadPdfIcon} />
                          </div>
                          <label onClick={() => documentRef.current.click()}>
                            Browes You File
                          </label>
                        </div>
                      )}
                    </div>

                    <Col md={12}>
                      {/* Hidden Input */}
                      <input
                        multiple
                        type={`file`}
                        className={`d-none`}
                        ref={documentRef}
                        onChange={(e) => {
                          uploadDocument(e.target.files, "public");
                        }}
                      />
                      {(userData?.documents?.length > 0 ||
                        documentsFile?.length > 0) && (
                        <div className={classes.uploadMoreBtnBox}>
                          <Button
                            onClick={() => documentRef.current.click()}
                            className={classes.uploadBtn}
                          >
                            Upload More
                          </Button>
                        </div>
                      )}
                    </Col>
                  </Col>
                </Row>

                {/*  */}
              </div>
              {/* End of Public Docs */}

              {/* Private Docs */}
              {userData?.premium && (
                <div className={`${classes.privateDocuments_div}`}>
                  <Row className={classes.certificatesRow}>
                    <Col md={12}>
                      <h2
                        className={`${classes.greyText} ${classes.public_text}`}
                      >
                        Private Documents
                      </h2>
                      <div className={`${classes.doc_para_div}`}>
                        <p
                          className={`${classes.greyText} ${classes.para_text}`}
                        >
                          Note: Mechanic is allowed to upload total (2)
                          privately shared files.
                        </p>
                        <p
                          className={`${classes.greyText} ${classes.para_text}`}
                        >
                          (total {decidePrivateDocLength} files selected)
                        </p>
                      </div>
                    </Col>
                    <Col
                      md={12}
                      className={`${[
                        classes.certificatesBoxCol,
                        userData?.privateDocuments?.length > 0 && classes.p70,
                        documentsFilePrivate?.length > 0 == classes.p70,
                      ].join(" ")}`}
                    >
                      <div className={classes.certificatesBox}>
                        {/* When No Files */}
                        {userData?.privateDocuments?.length > 0 ||
                        documentsFilePrivate?.length > 0 ? (
                          <>
                            {userData?.privateDocuments?.map((el, index) => {
                              return (
                                <PDFImageView
                                  item={el}
                                  index={index}
                                  local={false}
                                  HandleDeletePdf={handleRemoveCertificate}
                                  loading={isDeletingDoc}
                                  pdfType="private"
                                  setChosenPDFType={setChosenPDFType}
                                  setSelectedDoc={setSelectedDoc}
                                />
                              );
                            })}
                            {documentsFilePrivate?.map((el, index) => {
                              return (
                                <PDFImageView
                                  item={el}
                                  index={index}
                                  local={true}
                                  HandleDeletePdf={() => {
                                    const newPdf = [...documentsFilePrivate];
                                    newPdf.splice(index, 1);
                                    setDocumentsFilePrivate(newPdf);
                                  }}
                                  loading={isDeletingDoc}
                                  pdfType="private"
                                  setChosenPDFType={setChosenPDFType}
                                  setSelectedDoc={setSelectedDoc}
                                />
                              );
                            })}
                          </>
                        ) : (
                          <div
                            className={`${classes.pdf_btn} ${classes.btn_main}`}
                          >
                            <div
                              className={`${classes.uploadPdfIconContainer} `}
                            >
                              <img src={UploadPdfIcon} />
                            </div>
                            <label
                              onClick={() => privateDocRef.current.click()}
                            >
                              Browes You File
                            </label>
                          </div>
                        )}
                      </div>

                      <Col md={12}>
                        {/* Hidden Input */}
                        <input
                          multiple
                          type={`file`}
                          className={`d-none`}
                          ref={privateDocRef}
                          onChange={(e) => {
                            uploadDocument(e.target.files, "private");
                          }}
                        />
                        {(userData?.privateDocuments?.length > 0 ||
                          documentsFilePrivate?.length > 0) && (
                          <div className={classes.uploadMoreBtnBox}>
                            <Button
                              onClick={() => privateDocRef.current.click()}
                              className={classes.uploadBtn}
                            >
                              Upload More
                            </Button>
                          </div>
                        )}
                      </Col>
                    </Col>
                  </Row>
                </div>
              )}
              {/* End Of Private Docs */}
            </>
          ) : (
            <div className={`${classes.inp_div}`}>
              <Input
                type={"text"}
                placeholder={"Vehicle Name"}
                setter={setVehicleName}
                value={vehicleName}
                leftIcon={<FaCar size={20} color={"#E60000"} />}
              />
            </div>
          )}
          <Button
            label={loading ? "Saving..." : "Save"}
            disabled={loading}
            className={classes.savebtn}
            onClick={submit}
          />

          {/* Input Section */}
        </Container>
      </section>
      {/* Footer section */}
      <section className={`${classes.Footer_Section}`}>
        <Footer containerClass={classes.container} />
      </section>

      {/* Footer section */}
    </div>
  );
}

export default UserSetting;
