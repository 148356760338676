import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CustomAccordion from "../../stories/Accordion";
import Footer from "../../stories/Footer";
import Header from "../../stories/Header";
import classes from "./UserBookingDetail.module.css";
import Aos from "aos";
import { FaListAlt, FaMapMarkerAlt } from "react-icons/fa";
import { IoCalendarSharp } from "react-icons/io5";
import { GrMail } from "react-icons/gr";
import { apiHeader, BaseURL, imageUrl } from "../../Config/apiUrl";
import { useParams } from "react-router-dom";
import { VERIFIED } from "../../constant/imagePath";
import { useSelector } from "react-redux";
import StarRatings from "react-star-ratings/build/star-ratings";
import ReviewCard from "../../stories/ReviewCard";
import Input from "../../stories/Input";
import { Get, Post } from "../../Axios/AxiosFunctions";
import Button from "../../stories/Button";
import { toast } from "react-toastify";

function UserBookingDetail() {
  const pageId = useParams()?.id

  const user_type = useSelector(state => state.authReducer.user_type)
  const accessToken = useSelector(state => state.authReducer?.accessToken)


  // For Booking

  const [booking, setBooking] = useState()
  const [isGetting, setIsGetting] = useState(false)

  // For Rating
  const [rating, setRating] = useState(0)
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState('')


  // Getting Booking from api
  async function getBooking() {
    const url = BaseURL(`booking/${pageId}`)
    setIsGetting(true)
    const response = await Get(url, accessToken)
    if (response !== undefined) {
      console.log('res', response?.data?.data)
      setBooking(response?.data?.data)
      setIsGetting(false)
    } else {
      setIsGetting(false)
    }
  }
  useEffect(() => {
    getBooking()
  }, [])




  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);


  // For Review
  const submitReview = async () => {
    const url = BaseURL("review/create");
    let params = {
      reviewMessage: description,
      rating: rating,
      mechanicDetailId: booking?.mechanicDetailId?._id,
      mechanicUserId: booking?.mechanicUserId,
      customer: booking?.customerUserId,
      booking: booking?._id,
    };
    for (let key in params) {
      if (params[key] == '') {
        return toast.error('Please fill out the fields')
      }
    }
    if (rating == 0) {
      return toast.error('Please provide the rating')
    }

    setLoading(true);
    const responseData = await Post(url, params, apiHeader(accessToken))
    if (responseData !== undefined) {
      setBooking(responseData?.data?.data);
      console.log("respone review api: ", responseData?.data?.data);
      setDescription("");
      setRating(0);
      setLoading(false);

    } else {
      toast.error(`${responseData?.message}`);
      setLoading(false);
    }

  };


  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <Header
          bgTransparent={false}
          containerClass={`${[classes.container].join(" ")}`}
          isLogoBlack={true}
        />
      </div>
      <section className={`${classes.middleSection}`}>
        <Container
          className={`${[classes.container].join(" ")}`}
        >
          {isGetting ?

            <div className={`SkeletonContainer ${classes.skeleton}`} />
            : <div className={classes.card}>
              <Row>
                <Col span={4} className={classes.imgAndUserName}>
                  <img
                    className={classes.img}

                    src={`${imageUrl(booking?.mechanicDetailId?.photo)}`}
                  />

                  <div className={classes.nameAndVerify}>
                    <h3>{booking?.mechanicDetailId?.userName}</h3>
                    {booking?.mechanicDetailId?.isMMHVerified && (
                      <img
                        src={VERIFIED}
                      />
                    )}
                  </div>


                </Col>
              </Row>
              <Row className={classes.mTop20}>
                <Col md={6}>
                  <div className={classes.dFlex}>
                    <FaMapMarkerAlt className={classes.icon} />
                    <p>{booking?.mechanicDetailId?.address ? booking?.mechanicDetailId?.address : "This information is empty"}</p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className={classes.dFlex}>
                    <GrMail className={classes.icon} />
                    <p>{booking?.mechanicDetailId?.UserId?.email ? booking?.mechanicDetailId?.UserId?.email : 'This information is empty'}</p>
                  </div>
                </Col>
                <Col md={12} className={classes.mt10}>
                  <div className={classes.dFlex}>
                    <IoCalendarSharp className={classes.icon} />
                    <p>{booking?.mechanicDetailId?.description ? booking?.mechanicDetailId?.description : "This information is empty"}</p>
                  </div>
                </Col>

                {/* Give Rating For Customer */}
                <Col md={12}>
                  <h3 className={`${[classes.mb10, classes.mt20].join(' ')}`}>Rating & Review</h3>
                  {user_type === "customer" && (booking?.review?.length === 0 ? (
                    <>
                      <div className={classes.review}>
                        <div className={classes.mb10}>
                          <StarRatings
                            rating={rating}
                            name="rating"
                            starSpacing="2px"
                            numberOfStars={5}
                            starDimension="20px"
                            starRatedColor="var(--main-color-yellow)"
                            changeRating={(e) => setRating(e)}
                          />
                        </div>
                        <Input placeholder={'Write a review...'} type={'text'} value={description} setter={setDescription} />
                        <Button onClick={submitReview} className={classes.submitBtn} disabled={loading}>{loading ? "Submitting..." : "Submit"}</Button>
                      </div>
                    </>)
                    :
                    <ReviewCard data={booking} />
                  )}
                </Col>

                {/* End Of Give Rating */}
                {/* Render Rating */}
                <Col md={12}>
                  {
                    user_type === "mechanic" && (
                      booking?.review?.length > 0 ? (
                        <ReviewCard data={booking} />
                      ) : (
                        <p className={classes.noReviewsText}>
                          No Reviews & Ratings Given yet.
                        </p>
                      )
                    )
                  }

                </Col>
              </Row>
              <Row>
                {/* <Col className={classes.ratingNreview} md={24}>
                <h1>Rating & Review</h1>
                <p>No Reviews & Ratings Given yet.</p>
              </Col> */}
              </Row>
            </div>}
        </Container>
      </section>
      {/* Footer */}
      <Footer containerClass={classes.container} />
    </div>
  );
}

export default UserBookingDetail;





// 


