export const LOGIN_SUCCESS = "login-success";
export const LOGOUT_SUCCESS = "logout-success";
export const UPDATE_FCM_TOKEN = "set-fcm-token";
export const SAVE_CURRENT_LOCATION = "save-current-location";
export const SET_LOCATION = "set-location";
export const LOGIN_REQUEST = "login-request";
export const LOGOUT_REQUEST = "logout-request";
export const SET_REFETCH = "SET_REFETCH";
export const SET_SELECTEDNAV = "SET_SELECTEDNAV";
export const SET_SELECTED_PARENT_NAV = "SET_SELECTED_PARENT_NAV";
export const UPDATE_USER_DATA = "update-user-data";
export const UPDATE_DATA = "UPDATE_DATA";
export const UPDATE_USER_INTEREST_QUESTION = "update-user-interest-question";
export const UPDATE_USER_EMAIL_VERIFICATION = "update-user-email-verification";
export const CHANGE_PUSH_NOTIFICATION = "change-push-notification";
export const CHANGE_ONBOARDING = "change-onboarding";
export const Save_Cart = "Save_Cart";
export const Save_First_Cart = "Save_First_Cart";
export const Update_Cart = "Update_Cart";
export const Delete_Cart = "Delete_Cart";
export const Delete_Last_Cart_Item = "Delete_Last_Cart_Item";
export const Reset_Cart = "Reset_Cart";
export const DEFAULT_LAT_LNG = "DEFAULT_LAT_LNG";
export const SET_REGISTRATION_TYPE = "SET_REGISTRATION_TYPE";
export const SET_CERTIFICATES_SERVICES_ARRAY =
  "SET_CERTIFICATES_SERVICES_ARRAY";
