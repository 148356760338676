import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L, { LatLngExpression, marker } from "leaflet";
import "leaflet/dist/leaflet.css";
import MY_LOC from "../../assets/images/my-loc.png";
import "leaflet/dist/leaflet.css";
import { imageUrl } from "../../Config/apiUrl";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import classes from './Map.module.css'

function Map({ locations, myCoords, mapContainerClass }) {
  const [map, setMap] = useState(null);
  const userData = useSelector((state) => state?.authReducer?.user);
  const isLogin = useSelector((state) => state.authReducer.isLogin);
  const history = useHistory();
  const [selectedBooking, setSelectedBooking] = useState(null);
  // searched positoin is the particular position where you want to know mechanics
  const searchedPosition = [myCoords?.lat, myCoords?.lng];
  // console.log({ searchedPosition });

  useEffect(() => {
    if (searchedPosition?.length > 0) {
      map?.flyTo(searchedPosition, 11);
    }
  }, [searchedPosition]);
  // console.log("mechanic map");

  const setLocalStorageParams = (ele) => {
    localStorage.setItem("data", JSON.stringify(ele));
    localStorage.setItem("photo", JSON.stringify(ele?.customerDetailId?.photo));
  };

  const center = {
    lat: 24.9081012,
    lng: 67.1393006,
  };

  var pointer = L.icon({
    iconUrl:
      "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png",
    iconSize: [38, 40], // size of the icon
    shadowSize: [35, 46], // size of the shadow
    iconAnchor: [17, 46], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62], // the same for the shadow
    popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
  });

  var pointerMe = L.icon({
    iconUrl: MY_LOC,
    iconSize: [50, 50], // size of the icon
    shadowSize: [100, 100], // size of the shadow
    iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62], // the same for the shadow
    popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
  });

  function DraggableMarker() {
    const [draggable, setDraggable] = useState(false);
    const [position, setPosition] = useState(center);
    const markerRef = useRef(null);

    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current;
          if (marker != null) {
            setPosition(marker.getLatLng());
          }
        },
      }),
      []
    );

    const toggleDraggable = useCallback(() => {
      setDraggable((d) => !d);
    }, []);

    return locations?.map((ele, id) => {
      return (
        <Marker
          // draggable={draggable}
          // eventHandlers={eventHandlers}
          position={{
            lat: ele?.lat,
            lng: ele?.lng,
          }}
          onMouseOver={(e) => {
            console.log("over mouse");
            e.target.openPopup();
          }}
          onMouseOut={(e) => {
            e.target.closePopup();
          }}
          ref={markerRef}
          icon={pointer}
        >
          <Popup minWidth={90}>
            <span
              onClick={() => {
                setSelectedBooking(ele);
                localStorage.setItem("data", JSON.stringify(ele));
                isLogin && history.push(`/ViewCurrentBooking`);
              }}
            >
              {/* {draggable ? ( */}
              <div className="pop-up-div">
                <div className="image-name-div">
                  <img
                    src={`${imageUrl}/${ele?.customerDetailId?.photo}`}
                    className="pop-image rounded-circle"
                  />
                  <p className="pop-user">{ele?.name}</p>
                </div>
                <p className="pop-address text-center">{ele?.serviceAddress}</p>
              </div>
              {/* ) : (
                  "Click here to make marker draggable"
                )} */}
            </span>
          </Popup>
        </Marker>
      );
    });
  }

  return (
    <MapContainer
      center={[40.73061, -73.935242]}
      zoom={14}
      scrollWheelZoom={false}
      style={{ height: 600 }}
      whenCreated={setMap}
      className={`${classes.mapContainer} ${mapContainerClass && mapContainerClass}`}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={searchedPosition} icon={pointerMe}>
        <Popup minWidth={90}>
          <span>
            <div className={classes.popupDiv}>
              <div className={classes.imageNameDiv}>
                <img
                  src={imageUrl(userData?.photo)}
                  className={`${[classes.popImage, 'rounded-circle'].join(' ')}`}
                />
                <p className={classes.popUser}>You</p>
              </div>
              <p className={`${[classes.popAddress].join(' ')}`}>{userData?.address}</p>
            </div>
          </span>
        </Popup>
      </Marker>
      <DraggableMarker />
    </MapContainer>
  );
}

export default Map;
